import React, { Component } from 'react';
import axios from "axios";
import './GamePuzzle.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../../utils/general/Env';
import { uptBodyHeight } from '../../../utils/html/Html.js';
import { withRouter } from '../../../utils/general/Url';
import { GetStateUrlLocale } from '../../../utils/general/General';
import { SetSeo } from '../../../utils/general/Seo.js';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../../body/header/Header';
import Footer from '../../body/footer/Footer';
import GameResult from '../game-result/GameResult';
//----------------------------------- Others -----------------------------------
import load from '../../../assets/images/load-clr.gif';
const initialState = {
    loading: true,
	modal:    false,
	letter: [],
	answer: [],
	selected: "",
	highlight: [],
	unik: [],
	found: [],
	cntFound: 0,
	bar: 0,
	status: 'playing'
};
class GamePuzzle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			modal:    false,
			letter: [],
			answer: [],
			selected: "",
			highlight: [],
			unik: [],
			found: [],
			cntFound: 0,
			bar: 0,
			status: 'playing'
        }
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { act: 2 }
			axios.get(`${ApiUrl()}/game.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({answer: res.data.htm.w});
					this.setState({letter: res.data.msg});
					this.setState({loading: false});
					this.anchor();
				}				
			});
		});
	}
	componentDidMount(){
		SetSeo('games', {tt: 'wordPuzzle.title', dsc: 'wordPuzzle.meta.desc'}, []);
		uptBodyHeight();
		this.browse();
	}
	selectAlpha(letter, key){
		if(this.state.status === 'playing' && !this.state.unik.includes(key)){			
			this.setState(previousState => ({
				unik: [...previousState.unik, key]
			}));
			this.setState(previousState => ({
				highlight: [...previousState.highlight, letter]
			}));
			
			let str = `${this.state.selected}${letter}`;
			this.setState({selected: str});			
		}
	}
	process(){
		if(this.state.status === 'playing'){
			if(this.state.answer.includes(this.state.selected) && 
				!this.state.found.includes(this.state.selected)){
				this.setState(previousState => ({
						found: [...previousState.found, this.state.selected]
					}), () => {					
					if(this.state.found.length === 10){
						this.setState({status: "won"}, () => {
							this.setState({status: "won"}, () => {
								this.toggleModal();
							});
						})
					}
					this.setState({bar: (this.state.found.length * 10)})
					this.reset();
				});
			}else{
				this.reset();
			}
		}
	}
	del(){
		if(this.state.status === 'playing' && this.state.selected.length > 0){
			let arr = this.state.highlight;
			arr.pop();
			this.setState({highlight: arr});
			arr = this.state.unik;
			arr.pop();
			this.setState({unik: arr});
			this.setState({selected: this.state.selected.slice(0, -1)});
		}
	}
	reset(){
		this.setState({selected: ""});
		this.setState({highlight: []});
		this.setState({unik: []});
	}
	restart(){
		this.setState(initialState, () => {
			this.browse();
		});
	}
	toggleModal(){
		this.setState({modal: !this.state.modal});
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
	}
	anchor(){
		let width = document.getElementById('js-fll-bd').offsetWidth;
		if(width < 600){
			document.getElementById('anchor').click();
		}
	}
	render(){
		return (
			<div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="gm-thm-bd" id="js-exp-bd">
                    <div className="gm-mid">
						<div>
							<div className="gm-link">
								<span className="back-link" onClick={() => this.goTo(`/games`)}>
									&#10096; {Trtor('games', 'game.allGame')}
								</span>
								<span className="back-sep">/</span>
								<span className="back-on">
									{Trtor('games', 'wordPuzzle.title')}
								</span>
							</div>
							<div className="gm-hint"></div>
							<div className="clr"></div>
						</div>
						{this.state.loading && 
							<div style={{textAlign:"center", margin:"10px 0 0 0"}}>
								<img src={load} alt="loading..." style={{width:"45px"}}/>
							</div>
						}
						{!this.state.loading && 
						<div>
							<a href="#start" id="anchor" style={{outline:"none"}}>&nbsp;</a>
							<a href="#no" id="start" style={{outline:"none"}}>&nbsp;</a>
							<div className="gm-pzz-nb">{this.state.found.length} / 10</div>
							<div className="gm-pzz-bar">
								<div className="gm-pzz-ibar" style={{width: `${this.state.bar}%`}}></div>	
							</div>
							<div className="gm-pzz-box">
								<div className="gm-pzz-alpha">
									{this.state.selected.length === 0 &&
										<div className="gm-pzz-alpha-hd">
											{Trtor('games', 'wordPuzzle.click')}
										</div>
									}
									{this.state.selected.length > 0 &&
										<div className="gm-pzz-alpha-hd gm-pzz-alpha-hdtxt">
											{this.state.selected}
										</div>
									}
									<div className="gm-pzz-letter-bx">
									{this.state.letter.map((elm, key) => {
										let brd  = key === 0 ? ` bd-l0` : ``;
										let clss = this.state.highlight.includes(elm) 
											? ` gm-pzz-letter-picked` : ``;
										if(key < 4){
											return (
												<div 
													className={`gm-pzz-letter-bx-elm${brd}${clss}`}
													onClick={() => this.selectAlpha(elm, key)}
												>{elm}</div>
											);
										}
										return null;
									})}
									</div>	
									<div className="gm-pzz-letter-bx">
									{this.state.letter.map((elm, key) => {
										let brd  = key === 4 ? ` bd-l0` : ``;
										let clss = this.state.highlight.includes(elm) 
											? ` gm-pzz-letter-picked` : ``;
										if(key > 3){
											return (
												<div 
													className={`gm-pzz-letter-bx-elm${brd}${clss}`}
													onClick={() => this.selectAlpha(elm, key)}
												>{elm}</div>
											);
										}
										return null;
									})}
									</div>	
									<div className="gm-pzz-btn">
										<div className="gm-pzz-go" onClick={() => this.process()}>
											{Trtor('games', 'wordPuzzle.submit')}
										</div>
										<div className="gm-pzz-del" onClick={() => this.del()}>
											&larr;
										</div>
										<div className="clr"></div>
									</div>	
								</div>	
								<div className="gm-pzz-answ">
									<div className="gm-pzz-answ-tit">
										{Trtor('games', 'wordPuzzle.found')}
									</div>
									<div className="gm-pzz-answ-lst">
										<div className="gm-pzz-answ-a">
										{this.state.found.map((elm, key) => {
											let clss = key === 0 ? ` bd-0` : ``;
											if(key < 5){
												return (
													<div 
														className={`gm-pzz-answ-elm${clss}`}
													>{elm}</div>
												);
											}
											return null;
										})}
										</div>
										<div className="gm-pzz-answ-b">
										{this.state.found.map((elm, key) => {
											let clss = key === 5 ? ` bd-0` : ``;
											if(key > 4){
												return (
													<div 
														className={`gm-pzz-answ-elm${clss}`}
													>{elm}</div>
												);
											}
											return null;
										})}
										</div>
									</div>
								</div>	
							</div>
						</div>
						}						
						{this.state.modal &&
						<GameResult 
							show={this.state.modal} 
							toggleModal={() => this.toggleModal()}
							title={this.state.status === "won" ? 
								Trtor('games', 'game.win') : Trtor('games', 'game.lost')}
							msg={this.state.status === "won" ? 
								Trtor('games', 'game.winSub') : Trtor('games', 'game.lostSub')}
							status={this.state.status}
							play={() => this.restart()}
							back={() => this.goTo(`/games`)}
						/>
						}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
export default withRouter(GamePuzzle);