export const init = {
    ht: {
		meta:{
			desc: `Jwe yon varyete de jwèt mo ak devinèt sou entènèt chak semèn sou lang kreyòl Ayisyen an gratis. Egwize lespri ou an chak jou ak aktivite difisil. Eksplore bibliyotèk jwèt sou sit entènèt nou an jodi a!`,
		},
        game: {
            title: 'Jwèt mo',
            sub: 'Pran plezi tout pandan yap amelyore lang ou ak konpetans ekri ou an',
            suba: 'Chak semèn nou mete jwèt yo ajou ak nouvo kontni, degaje w ak pran plezi',
            allGame: 'Tout jwèt',
            again: 'Jwe ankò',
            leave: 'Kite',
            win: 'Felisitasyon!',
            winSub: 'Bon bagay! Ou se mèt vokabilè.',
            lost: '"Ooops..."',
            lostSub: 'Pwochen fwa yap genyen',
            play: 'Jwe',
        },
        hangedMan: {
			meta:{
				desc: `Hangman ou nan Kreyol "Pann moun" se yon jwèt mo klasik kote ou dwe devine mo sekrè a! Defi tèt ou ak zanmi ou yo epi aprann nouvo mo Ayisyen, yon lèt alafwa!`,
			},
            title: 'Pann moun',
            sub: 'Eseye devine',
            hint: 'ede'
        },
        wordPuzzle: {			
			meta:{
				desc: `Jwe jwèt kastèt chak semèn nan Diksyone.net epi devlope vokabilè ou epi amelyore konpetans lang ou. Aprann nouvo mo ak kapasite ou pou rezoud pwoblèm jwèt kastèt chak semèn`,
			},
            title: 'Kole mo',
            sub: 'Jwenn otan ke ou kapab',
            click: 'Klike sou lèt yo',
            submit: 'Soumèt',
            found: 'Mo ou jwen',
        },
        wordle: {
			meta:{
				desc: `Teste konpetans ou an epi rezoud pwoblèm lan. Èske ou ka devine ki mo Ayisyen ki kache? Ranpli kadriyaj yo jiskaske ou rive nan dènye ranje a. Yon nouvo mo pou jwenn ap disponib chak semèn!`,
			},
            title: 'Mo misterye',
            sub: 'Eseye debloke mo a',
        }
    },
    en: {
		meta:{
			desc: `Play a variety of weekly online word games and puzzles about the Haitian creole language for free. Sharpen your mind each day with challenging activities. Explore our online game library today!`,
		},
        game: {
            title: 'Word Games',
            sub: 'Flex your word muscles and improve your language and writing skills with a bit of fun',
            suba: 'Games are updated with new content weekly, have fun',
            allGame: 'All games',
            again: 'Play again',
            leave: 'Leave',
            win: 'Congratulations!',
            winSub: 'Nice! You are the ultimate word master.',
            lost: '"Ooops..."',
            lostSub: 'Better luck next time.',
            play: 'Play',
        },
        hangedMan: {
			meta:{
				desc: `Hangman is a classic word game in which you must guess the secret word! Challenge yourself and your friends and learn new Haitian words, one letter at a time!`,
			},
            title: 'The hanged man',
            sub: 'Try to guess',
            hint: 'Hint'
        },
        wordPuzzle: {
			meta:{
				desc: `Play the weekly puzzle from Diksyone.net and grow your vocabulary and improve your skills of the Haitian creole language. Learn new words and practice problem solving skills when you play the weekly puzzle`,
			},
            title:  'Word puzzle',
            sub: 'Find as many as you can',
            click:  'CLICK LETTER',
            submit: 'Submit',
            found:  'Word found',
        },
        wordle: {
			meta:{
				desc: `Put your skills to the test and solve the Wordle. Can you guess the hidden Haitian word in 6 tries? Fill in the wordle grid of guesses given the last row. A new UnWordle available each week!`,
			},
            title: 'Wordle solver',
            sub: 'Try to unlock the word',
        }
    },
    fr: {
		meta:{
			desc: `Jouez gratuitement à une variété de jeux de mots et de puzzles hebdomadaires en ligne sur la langue créole haïtienne. Aiguisez votre esprit chaque jour avec des activités stimulantes. Explorez notre bibliothèque de jeux en ligne dès aujourd'hui !`,
		},
        game: {
            title: 'Jeux de mots',
            sub: 'Développez et améliorez vos compétences linguistiques et rédactionnelles avec un peu de plaisir',
            suba: 'Les jeux sont mis à jour avec du nouveau contenu chaque semaine, amusez-vous bien',
            allGame: 'Tous les jeux',
            again: 'Jouer encore',
            leave: 'Quitter',
            win: 'Félicitations!',
            winSub: 'Bravo! Vous êtes le maître ultime des mots.',
            lost: '"Ooops..."',
            lostSub: 'Plus de chance la prochaine fois.',
            play: 'Jouer',
        },
		hangedMan: {
			meta:{
				desc: `Le pendu est un jeu de mots classique dans lequel vous devez deviner le mot secret ! Mettez-vous au défi, ainsi que vos amis, et apprenez de nouveaux mots Haïtiens, une lettre à la fois !`,
			},
            title: 'Le pendu',
            sub: 'Essayez de deviner',
            hint: 'Indice'
        },
        wordPuzzle: {
			meta:{
				desc: `Jouez au puzzle hebdomadaire de Diksyone.net et développez votre vocabulaire et améliorez vos compétences du créole Haïtien. Apprenez de nouveaux mots et exercez-vous à résoudre des problèmes lorsque vous jouez au puzzle hebdomadaire`,
			},
            title: 'Puzzle de mot',
            sub: 'Trouvez autant que vous pouvez',
            click: 'cliquez les lettre',
            submit: 'Soumettre',
            found: 'Mot trouvé',
        },
        wordle: {
			meta:{
				desc: `Mettez vos compétences à l'épreuve et résolvez le puzzle. Pouvez-vous deviner le mot Haïtien caché en 6 essais ? Remplissez la grille pour former un mot. Un nouveau mot à découvrir disponible chaque semaine!`,
			},
            title: 'Solveur de mots',
            sub: 'Déverrouiller le mot',
        }
    }
}
