import React, { Component } from 'react';
import './KreyolExample.css';
//-----------------------------------   utils   -----------------------------------

//----------------------------------- Component -----------------------------------
class KreyolExample extends Component {
	constructor(props) {
		super(props);
		this.state = {
			definition: [],
			see: [],
		}
	}
	render(){
		const arrEx = this.props.wrd;
		if(arrEx[this.props.index].hasOwnProperty(this.props.attr) && arrEx[this.props.index][this.props.attr].length > 0){
			let   inc  = 0;
			const arr  = arrEx[this.props.index][this.props.attr];
			const half = Math.ceil(arr.length / 2);
			return (
				<div className="df-wrd-box" style={{marginTop:'40px'}}>
					<div className="df-wrd-tit">
						<div className="df-wrd-oth">
							<h4 style={{margin:'0'}}>
								{this.props.label}
							</h4>
						</div>
						<div className="clr"></div>
					</div>
					<div className="kr-ex-two">
						<div key={`kr-ex-1`}>
						{
							arr.map((elm, key) => {
								if(inc < half){
									inc++;
									return (
										<div className="df-def-ftxt" key={`${this.props.attr}-${inc}`}>
											<div className="df-ex-nb">
												{inc}
											</div>
											<div className="df-ex-txt">
												<span dangerouslySetInnerHTML={{__html: elm.e}} />
											</div>
											<div className="clr"></div>
										</div>
									);
								}
								return null;
							})
						}
						</div>
						<div key={`kr-ex-2`}>
						{
							arr.map((elm, key) => {
								if(key >= half){
									inc++;
									return (
										<div className="df-def-ftxt" key={`${this.props.attr}-${inc}`}>
											<div className="df-ex-nb">
												{inc}
											</div>
											<div className="df-ex-txt">
												<span dangerouslySetInnerHTML={{__html: elm.e}} />
											</div>
											<div className="clr"></div>
										</div>
									);
								}
								return null;
							})
						}
						</div>
					</div>
				</div>
			);
		}
		return null;
	}
}
export default KreyolExample;