import { Route, Routes } from 'react-router-dom';
import { ExecuteFirst } from './utils/ExecuteFirst';
import { GetRouteUrl } from './utils/general/Url';
import Home from './components/home/Home';
import Kreyol from './components/kreyol/Kreyol';
import Submit from './components/submit/Submit';
import SubmitKreyol from './components/submit/submit-kreyol/SubmitKreyol';
import SubmitExpression from './components/submit/submit-expression/SubmitExpression';
import Game from './components/game/Game';
import GameWordle from './components/game/game-wordle/GameWordle';
import GamePuzzle from './components/game/game-puzzle/GamePuzzle';
import GameHangedMan from './components/game/game-hanged-man/GameHangedMan';
import Vocabulary from './components/vocabulary/Vocabulary';
import Expression from './components/expression/Expression';
import ExpressionView from './components/expression/view/ExpressionView';
import Alphabetic from './components/alphabetic/Alphabetic';
import WordOfWeek from './components/word-of-week/WordOfWeek';
import VocabularyView from './components/vocabulary/view/VocabularyView';
import Contact from './components/contact/Contact';
import Page from './components/page/Page';

function App() {
    ExecuteFirst();
  return (
		<main>
			<Routes>
				<Route path={GetRouteUrl('home')} 			element={<Home />} exact />
				<Route path={GetRouteUrl('word-week')} 		element={<WordOfWeek />} exact />
				<Route path={GetRouteUrl('kreyol')} 		element={<Kreyol />} />
				<Route path={GetRouteUrl('vocabularyView')} element={<VocabularyView />} />
				<Route path={GetRouteUrl('vocabulary')} 	element={<Vocabulary />} exact />
				<Route path={GetRouteUrl('vocabulary-a')} 	element={<Vocabulary />} exact />
				<Route path={GetRouteUrl('expression')} 	element={<Expression />} exact />
				<Route path={GetRouteUrl('expression-a')} 	element={<Expression />} exact />
				<Route path={GetRouteUrl('expression-b')} 	element={<ExpressionView />} exact />
				<Route path={GetRouteUrl('alphabetic')} 	element={<Alphabetic />} />
				<Route path={GetRouteUrl('alphabetic-a')} 	element={<Alphabetic />} />
				<Route path={GetRouteUrl('alphabetic-b')} 	element={<Alphabetic />} />
				<Route path={GetRouteUrl('submit')} 		element={<Submit />} />
				<Route path={GetRouteUrl('submit-a')} 		element={<SubmitKreyol />} />
				<Route path={GetRouteUrl('submit-b')} 		element={<SubmitExpression />} />				
				<Route path={GetRouteUrl('game')} 			element={<Game />} />
				<Route path={GetRouteUrl('game-a')} 		element={<GameWordle />} />
				<Route path={GetRouteUrl('game-b')} 		element={<GamePuzzle />} />
				<Route path={GetRouteUrl('game-c')} 		element={<GameHangedMan />} />
				<Route path={GetRouteUrl('contact')} 		element={<Contact />} />
				<Route path={"/"} 							element={<Home />} />
				<Route path={"*"} 							element={<Page />} />
			</Routes>
		</main>
	);
}
export default App;
