import React, { Component } from 'react';
import axios from "axios";
import '../Submit.css';
import './SubmitExpression.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../../utils/general/Env.js';
import { ObjHasKey, SetObjVal, ObjRmvKey } from '../../../utils/general/Json.js';
import { uptBodyHeight } from '../../../utils/html/Html.js';
import { withRouter } from '../../../utils/general/Url';
import { GetStateUrlLocale } from '../../../utils/general/General';
import { SetSeo } from '../../../utils/general/Seo.js';
import { validateEmail } from '../../../utils/general/String';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../../body/header/Header';
import Footer from '../../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import load from '../../../assets/images/load-clr.gif';

class SubmitExpression extends Component {
	constructor(props) {
		super(props);
		this.state = {
			saved: false,
			saving: false,
			form:{
				label: "",
				meaning:[""],
				example:[""],
				email: ""
			},
			error:{
				label: "",
				meaning: "",
				example: "",
				email: ""
			}
        }
	}
	componentDidMount(){
		SetSeo('submit', {tt: 'expression.title', dsc: 'expression.meta.desc'}, []);
		uptBodyHeight();
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
	}
	error(){
		let err   = 0;
		let error = { ...this.state.error };
		if(!validateEmail(this.state.form.email)){
			error.email = ' brd-err';
			err++;
		}else{
			error.email = '';
		}if(this.state.form.label.length < 3){
			error.label = ' brd-err';
			err++;
		}else{
			error.label = '';
		}if(this.state.form.meaning[0].length < 5){
			error.meaning = ' brd-err';
			err++;
		}else{
			error.meaning = '';
		}if(this.state.form.example[0].length < 5){			
			error.example = ' brd-err';
			err++;
		}else{
			error.example = '';
		}
		this.setState({error: error});
		return err;
	}
	submit(){
		if(this.error() === 0){
			this.setState({saving: true}, () => {
				const data = { act: 4, o: this.state.form }
				axios.post(`${ApiUrl()}/expression.php`, JSON.stringify(data)).then(res => {
					if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
						this.setState({saved: true});
					}else{
					}
					this.setState({saving: false});
				});
			});
		}
	}
	handleVal(key, event){
		if(!this.state.saved){
			let form = { ...this.state.form };
			SetObjVal(form, `${key}`, event.target.value);
			this.setState({form: form});
		}
	}
	handleValue(key, index, event){
		if(!this.state.saved){
			let form = { ...this.state.form };
			SetObjVal(form, `${key}.${index}`, event.target.value);
			this.setState({form: form});
		}
	}
	add(key, mx){
		let val = this.state.form[key];
		if(val.length < mx && !this.state.saved){
			val.push("");
			let form = { ...this.state.form };
			SetObjVal(form, `${key}`, val);
			this.setState({form: form});
		}
	}
	rmv(key, index){
		if(!this.state.saved){
			let tab = ObjRmvKey(this.state.form[key], index);
			let obj = { ...this.state.form };
			SetObjVal(obj, `${key}`, tab);
			this.setState({form: obj});
		}
	}
	render(){
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="sub-bd sub-sbd" id="js-exp-bd">
                    <div className="sub-mid">
						<span className="back-link" onClick={() => this.goTo(`/submit`)}>
							&#10096; {Trtor('submit', 'submit.all')}
						</span>
						<div className={"pd-b20"}>
							<h2 className="sub-tit-h2 pd-b10">{Trtor('submit', 'expression.title')}</h2>
							<div style={{fontSize:"0.95rem"}}>
								{Trtor('submit', 'submit.detail')}
							</div>
						</div>
						<div className={"frm-fld"}>
							<div className="frm-lab">{Trtor('submit', 'expression.name')}</div>
							<div className={"mg-t5"}>
								<div className="frm-inp">
									<textarea
										name="label"
										maxLength="150"
										className={`frm-fld-txt${this.state.error.label}`} 
										spellCheck="false"
										data-gramm="false"
										value={this.state.form.label}
										onChange={(e) => this.handleVal("label", e)}
									></textarea>
								</div>
								<div className="frm-icn bd-0">&nbsp;</div>
								<div className="clr"></div>
							</div>
						</div>
						<div className={"frm-fld mg-t30"}>
							<div className="frm-lab">{Trtor('submit', 'expression.meaning')}</div>
							{this.state.form.meaning.map((elm, key) => {
								return (
								<div className={"mg-t5"} key={`frm-${key}`}>
									<div className="frm-inp">
										<textarea
											name="label"
											maxLength="250"
											className={`frm-fld-txt${this.state.error.meaning}`} 
											spellCheck="false"
											data-gramm="false"
											value={this.state.form.meaning[key]}
											onChange={(e) => this.handleValue("meaning", key, e)}
										></textarea>
									</div>
									<div className="frm-icn">
									{key === 0 &&
										<div className="frm-icnp" onClick={() => this.add('meaning', 5)}>
											&#x2795;
										</div>
									}
									{key > 0 &&
										<div className="frm-icnp" onClick={() => this.rmv('meaning', key)}>
											&#8722;
										</div>
									}
									</div>
									<div className="clr"></div>
								</div>
								)})
							}
						</div>
						<div className={"frm-fld mg-t30"}>
							<div className="frm-lab">{Trtor('submit', 'expression.example')}</div>
							{this.state.form.example.map((elm, key) => {
								return (
								<div className={"mg-t5"} key={`frm-${key}`}>
									<div className="frm-inp">
										<textarea
											name="label"
											maxLength="200"
											className={`frm-fld-txt${this.state.error.example}`} 
											spellCheck="false"
											data-gramm="false"
											value={this.state.form.example[key]}
											onChange={(e) => this.handleValue("example", key, e)}
										></textarea>
									</div>
									<div className="frm-icn">
									{key === 0 &&
										<div className="frm-icnp" onClick={() => this.add('example', 10)}>
											&#x2795;
										</div>
									}
									{key > 0 &&
										<div className="frm-icnp" onClick={() => this.rmv('example', key)}>
											&#8722;
										</div>
									}
									</div>
									<div className="clr"></div>
								</div>
								)})
							}
						</div>
						{!this.state.saved && 
							<>
								<div className={"frm-fld mg-t30"}>
									<div className="frm-inp-em" style={{width:"100%"}}>
										<input type="text" 
											className={`frm-fld-inp${this.state.error.email}`} 
											placeholder={Trtor('submit', 'submit.email')} 
											value={this.state.form.email} 
											onChange={(e) => this.handleVal("email", e)}
										/>
									</div>
								</div>
								<div className={"frm-fld mg-t30 mg-b30"}>
									<div className="frm-icn frm-icn-btn" style={{float:"unset", margin:"0 auto"}}>
										{this.state.saving && 
											<div style={{textAlign:"center", padding:"3px 0 0 0"}}>
												<img src={load} alt="loading..." style={{width:"45px"}}/>
											</div>
										}
										{!this.state.saving && 
											<div className="frm-icnp frm-icnp-sub" 
												onClick={() => this.submit()} style={{margin:0}}>
												Publish
											</div>
										}
									</div>
								</div>
							</>
						}
						{this.state.saved && 
							<div className="alert alert-success mg-t30 mg-b30" role="alert">
								{Trtor('submit', 'submit.success')} 
							</div>
						}
					</div>
				</div>
                <Footer />
            </div>
		);
	}
}
export default withRouter(SubmitExpression);