import React, { Component } from 'react';
import axios from "axios";
import './GameWordle.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../../utils/general/Env';
import { uptBodyHeight } from '../../../utils/html/Html.js';
import { withRouter } from '../../../utils/general/Url';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { GetStateUrlLocale } from '../../../utils/general/General';
import { SetSeo } from '../../../utils/general/Seo.js';
import { Trtor } from '../../../utils/general/Translator';
import { KEYBOARD_ONE, KEYBOARD_TWO, KEYBOARD_THREE } from '../../../file/Constant.js';
//----------------------------------- Component -----------------------------------
import Header from '../../body/header/Header';
import Footer from '../../body/footer/Footer';
import GameResult from '../game-result/GameResult';
//----------------------------------- Others -----------------------------------
import load from '../../../assets/images/load-clr.gif';
const five   = [1,2,3,4,5];
const six    = [1,2,3,4,5,6];
const initialState = {
	loading:   true,
	modal:     false,
	currLine:  0,
	trys: 	   [],
	exist: 	   [],
	currWrd:   "",
	answer:   [],
	status:   'playing',
	error:    ''
};			
let selected = [];
let color    = [];

class GameWordle extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:   true,
			modal:     false,
			currLine:  0,
			trys: 	   [],
			exist: 	   [],
			currWrd:   "",
			answer:    [],
			status:    'playing',
			error:     ''
        }
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { act: 3 }
			axios.get(`${ApiUrl()}/game.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({exist:   res.data.msg}, () => {
						initialState.exist = res.data.msg;
					});
					this.setState({loading: false});
					this.setState({answer: res.data.htm}, () => {
						initialState.answer = res.data.htm;
					});					
					this.anchor();
				}				
			});
		});
	}
	componentDidMount(){
		SetSeo('games', {tt: 'wordle.title', dsc: 'wordle.meta.desc'}, []);
		uptBodyHeight();
		this.browse();
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
	}
	nxtArray(){
		let arr = [...this.state.trys];
		if(!arr.hasOwnProperty(this.state.currLine)){
			arr[this.state.currLine] = [];
		}
		return arr;
	}
	store(letter){
		if(this.state.status === 'playing'){
			if(this.state.currWrd.length < 5){
				this.setState({currWrd: `${this.state.currWrd}${letter}`});
				let arr = this.nxtArray();
				arr[this.state.currLine].push(letter);				
				this.setState({trys: arr});
			}
		}
	}
	validate(){
		if(this.state.status === 'playing' && this.state.currWrd.length === 5){
			if(this.state.exist.includes(this.state.currWrd)){	
				let arr = [...this.state.answer];
				let ansText = arr.join('');
				if(ansText === this.state.currWrd){
					this.setState({currLine: this.state.currLine + 1}, () => {
						this.setState({status: "won"}, () => {
							this.toggleModal();
						});
					});
				}else{
					let arr = this.nxtArray();
					this.setState({trys: arr}, () => {
						if(this.state.trys.length === 6){
							this.setState({status: "lost"}, () => {
								this.toggleModal();
							});
						}else{
							this.setState({currLine: this.state.currLine + 1}, () => {						
								this.setState({currWrd: ""});
								this.clrKeyPad();
							});	
						}
					});									
				}				
			}else{
				this.setState({error: " gm-wrd-key-err"});
				setTimeout(() => this.setState({error: ""}), 
					2500
				);
			}
		}
	}
	del(){
		if(this.state.status === 'playing' && this.state.currWrd.length > 0){
			let arr = [...this.state.trys];
			if(arr.hasOwnProperty(this.state.currLine)){
				arr[this.state.currLine].pop();
				this.setState({trys: arr});
			}
			this.setState({currWrd: this.state.currWrd.slice(0, -1)});
			this.setState({error: ""});
		}
	}
	getBgClr(letter, line, key){
		let clr = ' gm-wrd-keygrey';
		if(this.state.answer.includes(letter)){
			clr        = ' gm-wrd-keyyellow';
			let newVar = [];
			let arr	   = [...this.state.answer];
			let inc    = 0;
			while(inc < key){
				arr.shift();
				inc++;
			}						
			let index = arr.findIndex(el => letter === el);
			if(index >= 0){
				this.state.answer.map((elm, keyAnswer) => {
					if(elm === letter){
						newVar.push(keyAnswer);
					}
					return null
				});
					
				if(newVar.includes(key)){
					clr = ' gm-wrd-keygreen';
				}
			}
		}
		return clr;		
	}
	clrKeyPad(){
		let divLetter = Array.prototype.slice.call(document.getElementsByClassName("gm-wrd-letter-elm"));
		divLetter.map((elm, key) => {
			if(elm.textContent.length > 0){
				selected.push(elm.textContent);
				color.push(Array.prototype.slice.call(elm.classList));
			}
			return null;
		});
		let keypad = Array.prototype.slice.call(document.getElementsByClassName("gm-wrd-key"));
		keypad.map((elm, key) => {
			if(elm.textContent.length > 0){
				let index = -1;
				selected.map((el, keySel) => {
					if(el === elm.textContent){
						index = keySel;
					}
					return null
				});
				if(index >= 0){
					if(color[index].includes('gm-wrd-keygrey')){
						elm.classList.add("gm-keypad-grey");
					}else if(color[index].includes('gm-wrd-keyyellow')){
						elm.classList.add("gm-keypad-yellow");
					}else if(color[index].includes('gm-wrd-keygreen')){
						elm.classList.add("gm-keypad-green");
					}
				}
			}
			return null;
		});
	}
	restart(){
		initialState.loading = false;
		this.setState(initialState, () => {
			selected = [];
			color    = [];
			let keypad = Array.prototype.slice.call(document.getElementsByClassName("gm-wrd-key"));
			keypad.map((elm, key) => {
				elm.classList.remove("gm-keypad-grey");
				elm.classList.remove("gm-keypad-yellow");
				elm.classList.remove("gm-keypad-green");
				return null;
			});
		});
	}
	toggleModal(){
		this.setState({modal: !this.state.modal});
	}
	anchor(){
		let width = document.getElementById('js-fll-bd').offsetWidth;
		if(width < 600){
			document.getElementById('anchor').click();
		}
	}
	render(){
		return (
			<div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="gm-wrd-bd" id="js-exp-bd">
                    <div className="gm-mid">
						<div>
							<div className="gm-link">
								<span className="back-link" onClick={() => this.goTo(`/games`)}>
									&#10096; {Trtor('games', 'game.allGame')}
								</span>
								<span className="back-sep">/</span>
								<span className="back-on">{Trtor('games', 'wordle.title')}</span>
							</div>
							<div className="gm-hint"></div>
							<div className="clr"></div>
						</div>
						<a href="#start" id="anchor" style={{outline:"none"}}>&nbsp;</a>
						<a href="#no" id="start" style={{outline:"none"}}>&nbsp;</a>
						{this.state.loading && 
							<div style={{textAlign:"center", margin:"10px 0 0 0"}}>
								<img src={load} alt="loading..." style={{width:"45px"}}/>
							</div>
						}
						{!this.state.loading &&
							<div className="mg-t15">							
							{six.map((elm, key) => {
								let arr = this.state.trys.hasOwnProperty(key) ?
									this.state.trys[key] : ["","","","",""];
								return (
									<div className="gm-wrd-letter" key={`letter-${key}`}>
									{five.map((el, k) => {
										let val = arr.hasOwnProperty(k) ? arr[k] : "";
										let err = key === this.state.currLine ? 
											this.state.error : '';
										let cbg = this.state.currLine > key ? 
											this.getBgClr(val, key, k) : "";
										return (<div 
											className={`gm-wrd-letter-elm${err}${cbg}`}
											key={`let-${k}`}
										>{val}</div>);
									})}
									</div>
								);
							})}
							</div>
						}
						{!this.state.loading &&
							<div className="mg-t20">
								<div className="gm-wrd-keys">
								{KEYBOARD_ONE.map((elm, key) => {
									return (<div className="gm-wrd-key"
											onClick={() => this.store(elm)}
											key={`keypad-${key}`}
										>{elm}</div>);
								})}
								</div>
								<div className="gm-wrd-keys gm-wrd-keys-two">
								{KEYBOARD_TWO.map((elm, key) => {
									return (<div className="gm-wrd-key"
											onClick={() => this.store(elm)}
											key={`keypadt-${key}`}
										>{elm}</div>);
								})}
								</div>
								<div className="gm-wrd-keys gm-wrd-keys-three">
									<div className="gm-wrd-key gm-wrd-key-spe"
										onClick={() => this.validate()}
									>&#10004;</div>
									{KEYBOARD_THREE.map((elm, key) => {
										return (<div className="gm-wrd-key" 
											onClick={() => this.store(elm)}
											key={`keypadth-${key}`}
										>{elm}</div>);
									})}
									<div className="gm-wrd-key gm-wrd-key-spe"
										onClick={() => this.del()}
									>&#8592;</div>
								</div>
							</div>
						}
					</div>
					{this.state.modal &&
						<GameResult 
							show={this.state.modal} 
							toggleModal={() => this.toggleModal()} 
							title={this.state.status === "won" ? 
								Trtor('games', 'game.win') : Trtor('games', 'game.lost')}
							msg={this.state.status === "won" ? 
								Trtor('games', 'game.winSub') : Trtor('games', 'game.lostSub')}
							status={this.state.status}
							play={() => this.restart()}
							back={() => this.goTo(`/games`)}
						/>
					}
				</div>
				<Footer />
			</div>
		);
	}
}
export default withRouter(GameWordle);