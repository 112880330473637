import React, { Component } from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import './Home.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl, ExtUrl } from '../../utils/general/Env';
import { Trtor } from '../../utils/general/Translator';
import { withRouter } from '../../utils/general/Url';
import { ObjHasKey } from '../../utils/general/Json.js';
import { GetStateUrlLocale, GetStateLocale } from '../../utils/general/General';
import { SetSeo } from '../../utils/general/Seo';
import { InitSlider, SlideElment } from '../../utils/slider/Slider';
import { replaceAllWith } from '../../utils/general/String';
//----------------------------------- component -----------------------------------
import Header from '../body/header/Header';
import AutoComplete from '../form/auto-complete/AutoComplete';
import Footer from '../body/footer/Footer';
import WordOfWeekBox from '../word-of-week/word-of-week-box/WordOfWeekBox';
//----------------------------------- other -----------------------------------
import bookJpg from '../../assets/images/home-book.jpg';
import bookFff from '../../assets/images/home-book-fff.jpg';
import hangmanGame from '../../assets/images/hangman-game.png';
import puzzle from '../../assets/images/puzzle.png';
import wordle from '../../assets/images/wordle.png';
import srcb from '../../assets/images/srcb.png';
class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:  false,
			title:    Trtor('home', 'partA.title'),
			subtxt:   Trtor('home', 'partA.sub'),
			subtxta:  Trtor('home', 'partA.sub'),
			subtxtb:  Trtor('home', 'partA.subsht'),
			htmTitle: `<h2>${Trtor('home', 'partA.title')}</h2>`,
			slidePos: [],
			showArr:  false,
			query:    "",
			stat:     [{"i": "","l": "","n": "","u": "","v": "","m": 0,"p": 0,"o": 1},
				{"i": "","l": "","n": "","u": "","v": "","m": 0,"p": 0,"o": 2},
				{"i": "","l": "","n": "","u": "","v": "","m": 0,"p": 0,"o": 3},
				{"i": "","l": "","n": "","u": "","v": "","m": 0,"p": 0,"o": 4},
				{"i": "","l": "","n": "","u": "","v": "","m": 0,"p": 0,"o": 5}],
			wow:      {ww:{l:"",s:"",h:"",d:"",f:""}},
			vocab:    [],
			
        }
	}
	componentDidMount(){		
		SetSeo('home', {tt: 'partA.title',dsc: 'meta.desc'}, []);
        
        this.setState({htmTitle: `<h3>`+this.state.title+`</h3>`})
        window.addEventListener('resize', this.uptSize);   
        this.uptSize();
		this.setState(() => {
			return {
				title:    Trtor('home', 'partA.title'),
				subtxt:   Trtor('home', 'partA.sub'),
				subtxta:  Trtor('home', 'partA.sub'),
				subtxtb:  Trtor('home', 'partA.subsht'),
				htmTitle: `<h2>${Trtor('home', 'partA.title')}</h2>`,
			}
		});
		this.home();
	}
    componentWillUnmount() {
        window.removeEventListener('resize', this.uptSize);
    }
	home(){
		this.setState({loading: true}, () => {
			const data = { 
				act: 1,
				lg: GetStateLocale() 
			}
			axios.get(`${ApiUrl()}/home.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({wow:   res.data.msg});
					this.setState({stat:  res.data.msg.st});
					this.setState({vocab: res.data.msg.vb});
					this.setState({loading: false});
					this.after();
				}				
			});
		});
	}
	after(){
		this.setState({slidePos: InitSlider('vocab', 'hm-ptc-thm-elm', 20)}, () => {
			this.setState({showArr: this.state.slidePos.length > 1 ? true : false});
		});
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
    }
    uptSize = () => {
        let width = window.innerWidth
        if(width < 1080){
            this.setState({subtxt: this.state.subtxtb})
        }else{
            this.setState({subtxt: this.state.subtxta})
        }
        if(width < 800){
            this.setState({htmTitle: `<h3>`+this.state.title+`</h3>`})
        }else{
            this.setState({htmTitle: `<h2>`+this.state.title+`</h2>`})
        }
    }
    htmTitle() {
        return {
           __html: this.state.htmTitle }
    }
    slider(dir) {
        SlideElment(dir, 'vocab', this.state.slidePos);
    }
    setQuery(val){
        this.setState({query: val});
    }
	submit(){
		let url = `/kreyol/${replaceAllWith(this.state.query, " ", "-")}`;
		this.goTo(url);
	}
	render(){
		let alpha = "alphabetic";
		return (
            <div>
                <Header showShadow={true} />
				<div style={{background:"#fff"}}>
					<div className="bd-cent">
						<div className="hm-part-a">
							<div className="hm-pta-lft">
								<div className='hm-pta-img-resp-dv'>
									<img src={bookFff} className="hm-pta-img-resp" alt="dictionary books"/>
								</div>
								<div className="hm-pta-tit" dangerouslySetInnerHTML={this.htmTitle()} />
								<div className="hm-pta-sub">
									{this.state.subtxt}
								</div>
								<div className="hm-pta-src">                            
									<div className="hm-pta-src-in">
										<AutoComplete 
											category="home" 
											placeholder={`${Trtor('home', 'partA.placeholder')}?`} 
											setQuery={(val) => this.setQuery(val)}
											selOpt={1}
										/>
									</div>
									<div className="hm-pta-src-btn" onClick={() => this.submit()}>
										<img src={srcb} className="imgmax" alt="search" style={{maxWidth:"26px"}} />
									</div>
									<div className="clr"></div>
								</div>
							</div>
							<div className="hm-pta-rgt" style={{position:'relative'}}>
								<div className="hm-pta-imgabs">
									<img src={bookJpg} className="hm-pta-img" alt="dictionary books"/>
								</div>
							</div>
							<div className="clr"></div>
						</div>
					</div>				
                </div>				
                <div className="bd-cent">
                    <div className="hm-part-b">
                        <h3>{Trtor('home', 'partB.titleA')} <span className="hm-ptb-titrd">{Trtor('home', 'partB.titleB')}</span> {Trtor('home', 'partB.titleC')}</h3>
                        <div className="hm-ptb-nb">
                            {Trtor('home', 'partB.sub')}.
                        </div>
                    </div>
                    <div className="hm-part-d">						
                        <div className="hm-part-da">
                            <h3>{Trtor('home', 'partC.titleA')}</h3>
							{!this.state.loading &&
							<WordOfWeekBox
								data={{}}
								brd={"cccccc"}
							/>
							}
                        </div>
                        <div className="hm-part-db">
                            <h3>{Trtor('home', 'partC.titleB')}</h3>
                            <div className="hm-ptd-b">
								{this.state.stat.length > 0 &&
									this.state.stat.map((elm, index) => { 
										return (
											<div className="hm-ptd-bar" key={`st-${index}`}>
												<div className="hm-ptd-pct" style={{width:`${elm.p}%`}}>
													{elm.o}
													&nbsp;&nbsp;&nbsp;&nbsp;
													<Link to={`${GetStateUrlLocale()}/kreyol/${elm.l}`} className="lk-blk212">
														{elm.l}
													</Link>
												</div>
											</div>
										);
									})								
								}                               
                            </div>
                        </div>
                        <div className="clr"></div>
                    </div>
				</div>
				<div className="hm-part-gm">
					<div className="hm-part-gm-cent">
						<div className="pd-b20" style={{textAlign:"center"}}>
							<h2>{Trtor('home', 'game.title')}</h2>
							<h5 style={{color:"#444", fontWeight:"400"}}>
								{Trtor('home', 'game.sub')}.
							</h5>
						</div>
						<div className="hm-part-gm-mid">
							<div className="hm-part-gm-elm">
								<div className="hm-part-gm-elm-hd" style={{backgroundColor:"#9E1E1E"}}>
									<img src={hangmanGame} alt="Hangman game" style={{width:"80px"}}/>
								</div>
								<div className="hm-part-gm-elm-bd" onClick={() => this.goTo(`/game/the-hanged-man`)}>
									<div className="hm-part-gm-elm-tit">
										<Link to={`${GetStateUrlLocale()}/game/the-hanged-man`} className="lk-blk212">
											{Trtor('games', 'hangedMan.title')}
										</Link>
									</div>
									<div className="hm-part-gm-elm-sub">{Trtor('games', 'hangedMan.sub')}</div>
									<div className="hm-part-gm-elm-btn">{Trtor('games', 'game.play')}</div>
								</div>
							</div>
							<div className="hm-part-gm-elm">
								<div className="hm-part-gm-elm-hd" style={{backgroundColor:"#183153"}}>
									<img src={puzzle} alt="Word puzzle" style={{width:"80px"}}/>
								</div>
								<div className="hm-part-gm-elm-bd" onClick={() => this.goTo(`/game/word-puzzle`)}>
									<div className="hm-part-gm-elm-tit">
										<Link to={`${GetStateUrlLocale()}/game/word-puzzle`} className="lk-blk212">
											{Trtor('games', 'wordPuzzle.title')}
										</Link>
									</div>
									<div className="hm-part-gm-elm-sub">{Trtor('games', 'wordPuzzle.sub')}</div>
									<div className="hm-part-gm-elm-btn">{Trtor('games', 'game.play')}</div>
								</div>
							</div>
							<div className="hm-part-gm-elm">
								<div className="hm-part-gm-elm-hd" style={{backgroundColor:"#00AE66"}}>
									<img src={wordle} alt="wordle" style={{width:"80px"}}/>
								</div>
								<div className="hm-part-gm-elm-bd" onClick={() => this.goTo(`/game/worldle-solver`)}>
									<div className="hm-part-gm-elm-tit">
										<Link to={`${GetStateUrlLocale()}/game/worldle-solver`} className="lk-blk212">
											{Trtor('games', 'wordle.title')}
										</Link>
									</div>
									<div className="hm-part-gm-elm-sub">{Trtor('games', 'wordle.sub')}</div>
									<div className="hm-part-gm-elm-btn">{Trtor('games', 'game.play')}</div>
								</div>
							</div>
						</div>
					</div>
				</div>				
				<div className="bd-cent" style={{overflow:"hidden"}}>
                    <div className="hm-part-c" style={{overflow:"hidden"}}>
                        <h3>{Trtor('home', 'partD.title')}</h3>
                        <div className='hm-ptc-sub'>{Trtor('home', 'partD.sub')}.</div>
                        <div className='hm-ptc-thm' id="slider-vocab">
							{this.state.vocab.length > 0 &&
								this.state.vocab.map((elm, index) => {									
									let label = GetStateLocale() !== "ht" && ObjHasKey(elm, [`l-${GetStateLocale()}`]) ? elm[`l-${GetStateLocale()}`]: elm.l;
									return (
										<div className="hm-ptc-thm-elm" key={`vcb-${index}`}>
											<div className="hm-ptc-img" 
												style={{
													height:'165px',
													backgroundImage: `url('${ExtUrl()}${elm.m}')`
												}}
												onClick={() => this.goTo(`${elm.u}`)}
												>
											</div>
											<div className="hm-ptc-tit" style={{marginTop:'5px'}}>
												<Link to={`${GetStateUrlLocale()}${elm.u}`} className="lk-blk212">
													{label}
												</Link>
											</div>
											<div className="hm-ptc-nb">{elm.q} {Trtor('home', 'partD.word')}</div>
										</div>
									);
								})
							}
                        </div>
						{this.state.showArr &&
						<div className="hm-ptc-thm-barr">
							<div className="hm-ptc-thm-arr fltl" onClick={() => this.slider(0)}>
								&#8592;
							</div>
							<div className="hm-ptc-thm-arr fltr" onClick={() => this.slider(1)}>
								&#8594;
							</div>
							<div className="clr"></div>
                        </div>
						}
                    </div>
                </div>
                <div className="hm-part-let">                            
                    <h2>{Trtor('home', 'partE.title')}</h2>
                    <div className="hm-grid">
                        <div className="hm-grid-a hm-grid-des-a" onClick={() => this.goTo(`/${alpha}/a`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/a`} className="lk-blk212">A</Link>
						</div>
                        <div className="hm-grid-b hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/b`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/b`} className="lk-blk212">B</Link>
						</div>
                        <div className="hm-grid-c hm-grid-des-c" onClick={() => this.goTo(`/${alpha}/ch`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/ch`} className="lk-blkwht">CH</Link>
						</div>
                        <div className="hm-grid-d hm-grid-des-d hm-grid-big" onClick={() => this.goTo(`/${alpha}/d`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/d`} className="lk-blk212">D</Link>
						</div>
                        <div className="hm-grid-e hm-grid-des-a" onClick={() => this.goTo(`/${alpha}/e`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/e`} className="lk-blk212">E</Link>
						</div>
                        <div className="hm-grid-f hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/f`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/f`} className="lk-blk212">F</Link>
						</div>
                        <div className="hm-grid-g hm-grid-des-c" onClick={() => this.goTo(`/${alpha}/g`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/g`} className="lk-blkwht">G</Link>
						</div>
                        <div className="hm-grid-h hm-grid-des-e" onClick={() => this.goTo(`/${alpha}/h`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/h`} className="lk-blk212">H</Link>
						</div>
                        <div className="hm-grid-i hm-grid-des-a" onClick={() => this.goTo(`/${alpha}/i`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/i`} className="lk-blk212">I</Link>
						</div>
                        <div className="hm-grid-j hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/j`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/j`} className="lk-blk212">J</Link>
						</div>
                        <div className="hm-grid-k hm-grid-des-c" onClick={() => this.goTo(`/${alpha}/k`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/k`} className="lk-blkwht">K</Link>
						</div>
                        <div className="hm-grid-l hm-grid-des-a" onClick={() => this.goTo(`/${alpha}/l`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/l`} className="lk-blk212">L</Link>
						</div>
                        <div className="hm-grid-m hm-grid-des-b hm-grid-big" onClick={() => this.goTo(`/${alpha}/m`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/m`} className="lk-blk212">M</Link>
						</div>
                        <div className="hm-grid-n hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/n`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/n`} className="lk-blk212">N</Link>
						</div>
                        <div className="hm-grid-o hm-grid-des-c hm-grid-big" onClick={() => this.goTo(`/${alpha}/o`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/o`} className="lk-blkwht">O</Link>
						</div>
                        <div className="hm-grid-p hm-grid-des-d" onClick={() => this.goTo(`/${alpha}/p`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/p`} className="lk-blk212">P</Link>
						</div>
                        <div className="hm-grid-r hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/r`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/r`} className="lk-blk212">R</Link>
						</div>
                        <div className="hm-grid-s hm-grid-des-e" onClick={() => this.goTo(`/${alpha}/s`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/s`} className="lk-blk212">S</Link>
						</div>
                        <div className="hm-grid-t hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/t`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/t`} className="lk-blk212">T</Link>
						</div>
                        <div className="hm-grid-u hm-grid-des-d" onClick={() => this.goTo(`/${alpha}/u`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/u`} className="lk-blk212">U</Link>
						</div>
                        <div className="hm-grid-v hm-grid-des-a" onClick={() => this.goTo(`/${alpha}/v`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/v`} className="lk-blk212">V</Link>
						</div>
                        <div className="hm-grid-w hm-grid-des-b" onClick={() => this.goTo(`/${alpha}/w`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/w`} className="lk-blk212">W</Link>
						</div>
                        <div className="hm-grid-x hm-grid-des-d" onClick={() => this.goTo(`/${alpha}/x`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/x`} className="lk-blk212">X</Link>
						</div>
                        <div className="hm-grid-y hm-grid-des-a" onClick={() => this.goTo(`/${alpha}/y`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/y`} className="lk-blk212">Y</Link>
						</div>
                        <div className="hm-grid-z hm-grid-des-c" onClick={() => this.goTo(`/${alpha}/z`)}>
							<Link to={`${GetStateUrlLocale()}/${alpha}/z`} className="lk-blkwht">Z</Link>
						</div>
                    </div>
                </div>
                <div className="bd-cent">
                    <div className="hm-part-d">
                    {Trtor('home', 'partF.txtA')}. <br/>
                    {Trtor('home', 'partF.txtB')}.
                    </div>
                </div>
                <Footer />
            </div>
		);
	}
}
export default withRouter(Home);