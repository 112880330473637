import React, { Component } from 'react';
import './SelectAutoComplete.css';
//-----------------------------------   utils   -----------------------------------
import { GetStateUrlLocale } from '../../../utils/general/General';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { withRouter } from '../../../utils/general/Url';
//----------------------------------- Component -----------------------------------

class SelectAutoComplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: '',
			sel: '',
			showAutoComplete: false,
            original: [],
            category: [],
            arrow: '&#9660;',
        }    
        this.handleChange = this.handleChange.bind(this);
        this.clickSel = this.clickSel.bind(this);
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.arr !== this.props.arr ||
			prevProps.obj !== this.props.obj){
			let list = this.props.arr;
			if(list.length === 0){
				list = this.props.obj.map((elm, key) => {
					return ObjHasKey(elm, ["ts"]) && !!elm.ts ? elm.ts : elm.l;
				});
			}
			this.setState({original: list});
		}
	}
	componentDidMount(){ 
        document.addEventListener('mousedown', this.handleClickOutside);
		let list = this.props.arr;
		if(list.length === 0){
			list = this.props.obj.map((elm, key) => {
				return ObjHasKey(elm, ["ts"]) && !!elm.ts ? elm.ts : elm.l;
			});
		}
		this.setState({original: list});
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleChange(e){
        this.setState({query: e.target.value}, () => {
            this.uptList() 
        })               
    }
    clickSel(){
        if(!this.state.showAutoComplete){ 
            this.setState({category: this.state.original}, () => {
                this.setState({showAutoComplete: true}, () =>{
                    this.uptArrow()
                }) 
            })             
        }        
    }
    uptArrow(){
        if(this.state.showAutoComplete){
            this.setState({arrow: '&#9650;'}) 
        }else{
            this.setState({arrow: '&#9660;'}) 
        }
    }
    uptList(){
        let cpArr = this.state.original;
        let arr = cpArr.filter(cat => cat.toLowerCase().indexOf(this.state.query.toLowerCase()) >= 0);
        this.setState({category: arr}) 
    }
    handleClickOutside = (event) => {     
        if(this.state.showAutoComplete){
            let allowedDiv = ["sel-cnt", "sel-txt", "sel-arr", "sel-mre", 
            "sel-inp", "sel-inp-css", "sel-opt", "sel-opt-elm"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showAutoComplete: false}, () =>{
                        this.uptArrow()
                    })
                }
            }else{
                this.setState({showAutoComplete: false}, () =>{
                    this.uptArrow()
                })
            }
        }
    }    
	goToCat(cat){
		this.setState({showAutoComplete: false}, () =>{
            this.uptArrow();
			if(ObjHasKey(this.props, ["close"])){
				this.props.close();
			}
			this.props.history(`${GetStateUrlLocale()}/${this.props.url}/?b=${cat}`);
        });		
    }    
	render(){
        let display = this.state.showAutoComplete ? '' : " dispnone";
        return (
            <div className="sel-cnt" onClick={this.clickSel}>
                <div className="sel-txt">
                    {this.props.label}
                </div>
                <div className="sel-arr" dangerouslySetInnerHTML={{__html: this.state.arrow}}></div>
                <div className={`sel-mre${display}`}>
                    <div className="sel-inp">
                        <input type="text" name="sel" value={this.state.query} onChange={this.handleChange} className="sel-inp-css" />
                    </div>
                    <div className="sel-opt">
                        {this.state.category && this.state.category.map((cat, index) => {							
                            return (
                                    <div className="sel-opt-elm" key={`opt-${index}`} onClick={() => this.goToCat(cat)}>
                                        {cat}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(SelectAutoComplete);