export const StripHtml = (html) => {
   let tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}
export const CreateOption = (option, txt, val, selected) => {
	let frag = document.createDocumentFragment();
	let htmOpt;
	option.map((opt, index) => {
		htmOpt = frag.appendChild(document.createElement('option'));
		htmOpt.text = opt[txt];
		htmOpt.value = opt[val];
		return htmOpt;
	});
    return htmOpt;
}
export const uptBodyHeight = () => {
	//if(document.getElementById('js-exp-bd')){
		//document.getElementById('js-exp-bd').style.height = `unset`;
	//}
	let scnhgt = window.screen.height;
	let fullBd = document.getElementById('js-fll-bd');
	let expBd  = document.getElementById('js-exp-bd');
	//console.log("fullBd", fullBd);
	//console.log("expBd", expBd);
	if(fullBd && expBd){
		let foot = 142 + 1;
		fullBd   = fullBd.offsetHeight;
		expBd    = expBd.offsetHeight;
		/*
		console.log("fullBd", fullBd);
		console.log("expBd",  expBd); let aa = (scnhgt - foot);
		console.log("scnhgt", scnhgt);
		console.log("(scnhgt - foot)", aa);
		*/
		if((fullBd /*+ foot*/) < (scnhgt/* - foot*/)){
			let hgt = (scnhgt - foot) - fullBd;
			hgt = hgt + expBd;
			//console.log("screen: ", scnhgt, " fullBd: ", fullBd, " expBd: ", expBd, " (scnhgt - foot): ", (scnhgt - foot), " hgt: ", hgt);
			document.getElementById('js-exp-bd').style.minHeight = `${hgt}px`;
		}
	}
}