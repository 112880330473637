import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import '../vocabulary/Vocabulary.css';
import './Alphabetic.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../utils/general/Env.js';
import store from '../../store/store.js';
import { ObjHasKey } from '../../utils/general/Json.js';
import { withParamsRouter } from '../../utils/general/Url.js';
import { GetStateUrlLocale } from '../../utils/general/General.js';
import { SetSeo } from '../../utils/general/Seo.js';
import { formatNumb } from '../../utils/general/String.js';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { ALPHA } from '../../file/Constant';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Pager from '../pager/Pager';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import load from '../../assets/images/load.gif';
let   height  = 0;
const baseUrl = "alphabetic";
class Alphabetic extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sel: null,
			loading: true,
			list: [],
			url: `/${baseUrl}`,
			page: 1,
			total: 0,
			waitHgt: 0,
			partBx: 0,
        }
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.params.letter !== this.props.params.letter ||
			prevProps.params.page !== this.props.params.page){
			this.setTitle();
			this.setState({total: 0}, () => {
				this.searchWord();				
			});
		}
	}
	componentDidMount(){		
		this.setTitle();
		this.setParams();
		this.searchWord();
		uptBodyHeight();
	}
	setTitle(){
		let letter = this.getParams("letter", "a").toUpperCase();
		SetSeo('alphabetic', {tt: 'alpha.sub', dsc: 'meta.desc'}, [`var_1#${letter}`]);
	}
	setParams(){
		this.setState({url: `/${baseUrl}/${this.getParams("letter", "a")}`});
		this.setState({page: `${this.getParams("page",    1)}`});
	}
	getParams(key, val){
		return ObjHasKey(this.props, ["params", `${key}`]) ? this.props.params[key] : val;
	}
	searchWord(){
		const pg = this.getParams("page",    1);
		const l  = this.getParams("letter", "a");
		this.uptHeightLoader();
		this.setState({loading: true}, () => {
			this.setState({waitHgt: height});
			this.setState({waitHgt: height});
			const data = { 
				act: 1, 
				t: this.state.total, 
				p: pg, 
				l: l
			};
			axios.get(`${ApiUrl()}/alpha.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"])){
					this.setState({list: res.data.msg}, () => {
						this.setState({loading: false});						
						this.setState({total: res.data.htm}, () => {
							this.setParams();
						});
					});
					document.body.scrollTop = document.documentElement.scrollTop = 0;
				}
			});
		})
    }
	goTo(i, label, url){
		store.dispatch({ type: 'SET_WRD', payload: {i:i, u:url, l:label} })
		this.props.history(`${GetStateUrlLocale()}${url}`);
    }
	uptHeightLoader(){
		if(document.getElementById('brw-1-hgt')){
			height = document.getElementById('brw-1-hgt').offsetHeight;
		}
	}
	uptPg(atv, letter){
		if(atv === "brw-alpha-elm"){
			if(letter === "#"){ letter = "+"; }
			this.props.history(`${GetStateUrlLocale()}/${baseUrl}/${letter}`);
		}
    }
	render(){
		let inc    = 0;
		let size   = this.state.list.length > 50 ? 50 : this.state.list.length;
		const half = Math.ceil(size / 2);
		let total  = this.state.total > 1 ? this.state.total * 50 : this.state.list.length;
		let alpha  = this.getParams("letter", "a");
		alpha = alpha ? alpha : "a";
		alpha = alpha === "+" ? "#" : alpha;
		let stt = Trtor('alphabetic', 'alpha.sub').replace("#var_1#", "");
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="vc-bd" id="js-exp-bd">
                    <div className="vc-mid">
                        <div className="brw-hd">
                            <h2 className="brw-tit-h2">{stt} "<b style={{color:"#c40000"}}>{alpha.toUpperCase()}</b>"</h2>
                        </div>
                        <div className="brw-all">
							<div className="brw-alpha-scll">
								<div className="brw-alpha-grd">
									{ALPHA && ALPHA.map((letter, index) => 
										{											
											if(!["q"].includes(letter.nm)){
												let atv = letter.nm === alpha ? "brw-alpha-elm-atv" : "brw-alpha-elm";
												atv = alpha === "+" && letter.nm === "#" ? "brw-alpha-elm-atv" : atv;
												return (
													<div className={`${atv}`} key={`letter-${index}`} onClick={() => this.uptPg(atv, letter.nm)}>
														{letter.ct}
													</div>
												);
											}
											return null;
										})
									}
								</div>
							</div>  
							<div className="brw-list">
								{this.state.loading &&
									<div className="brw-wait" style={{height:`${this.state.waitHgt}px`}}>
										<img src={load} alt="loading..."/>
									</div>
								}
								{!this.state.loading &&
									<div className="brw-tab">
										<div className="brw-tab-hd">
											{formatNumb(total, " ")} {Trtor('alphabetic', 'alpha.result')}
										</div>
										<div className="brw-tab-bd">
											<div className="brw-lst-two" id="brw-1-hgt">											
												<div key={`brw-1`}>
												{
													this.state.list.map((elm, key) => {
														if(inc < half){
															let bdTop = inc === 0 ? " brw-list-elm-bd-t1" : "";
															inc++;
															return (
																<div className={`brw-list-elm${bdTop}`} key={`brwelm-1-${inc}`} onClick={() => this.goTo(elm.i, elm.l, elm.u)}>
																	<div className="brw-lst-elm-wrd">
																		<span className="brw-lst-elm-lnk">
																			<Link to={`${GetStateUrlLocale()}${elm.u}`} className="lk-inh" onClick={() => this.goTo(elm.i, elm.l, elm.u)}>														
																				{elm.l}
																			</Link>
																		</span>
																	</div>
																	<div className="brw-lst-elm-sb"></div>
																</div>
															);
														}
														return null;
													})
												}
												</div>
												<div key={`brw-2`}>
												{
													this.state.list.map((elm, key) => {
														if(key >= half && key < 50){
															inc++;
															let bdTop = key === half ? " brw-list-elm-bd-t1" : "";
															return (
																<div className={`brw-list-elm${bdTop}`} key={`brwelm-2-${inc}`} onClick={() => this.goTo(elm.i, elm.l, elm.u)}>
																	<div className="brw-lst-elm-wrd">
																		<span className="brw-lst-elm-lnk">
																			<Link to={`${GetStateUrlLocale()}${elm.u}`} className="lk-inh" onClick={() => this.goTo(elm.i, elm.l, elm.u)}>														
																				{elm.l}
																			</Link>
																		</span>
																	</div>
																	<div className="brw-lst-elm-sb"></div>
																</div>
															);
														}
														return null;
													})
												}
												</div>
											</div>
										</div>
									</div>
								}
							</div>    
                        </div>    
                        <Pager
							page={this.state.page}
							url={this.state.url}
							total={this.state.total}
						/>                    
                    </div>
                </div>
                <Footer />
            </div>
		);
	}
}
export default withParamsRouter(Alphabetic);