import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import "bootstrap/dist/css/bootstrap.min.css";
import './GameResult.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';

class GameResult extends Component {
	componentDidMount(){}
	render(){
		let btn = this.props.status === "won" ? "gmr-box-play" : "gmr-box-play gmr-box-play-lost";
		return (
			<Modal dialogClassName="modal-dialog-gm" show={this.props.show} onHide={() => {}}>
				<Modal.Body>
					<div className="gmr-box">
						<div className="gmr-x" onClick={() => this.props.toggleModal()}>&#9587;</div>
						<div className="gmr-box-tit">
							{this.props.title}
						</div>
						{this.props.status === "won" &&
							<div className="gmr-box-check">
								&#10003;
							</div>
						}
						{this.props.status !== "won" &&
							<div className="gmr-box-check-lost">
								&#10006;
							</div>
						}
						<div className="gmr-box-msg">
							{this.props.msg}
						</div>
						<div className={`${btn}`} onClick={() => this.props.play()}>
							{Trtor('games', 'game.again')}
						</div>
						<div className="gmr-box-close" onClick={() => this.props.back()}>
							{Trtor('games', 'game.leave')}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}
export default GameResult;