import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Pager.css';
//-----------------------------------   utils   -----------------------------------
import { withRouter } from '../../utils/general/Url';
import { GetStateUrlLocale } from '../../utils/general/General';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------

class Pager extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: parseInt(this.props.page),
			url:   this.props.url,
			typed: false,
        }    
        this.handleChange = this.handleChange.bind(this);
	}
	componentDidUpdate(prevProps, prevState){
		if(this.state.url !== this.props.url){
			this.setState({url: this.props.url});
		}
		if(this.props.page !== this.state.query && this.state.typed === false){
			this.setState({query: this.props.page});
		}
	}
	componentDidMount(){
	}
    handleChange(e){
		if(!isNaN(e.target.value)){
			this.setState({query: String(e.target.value).length > 0 ? parseInt(e.target.value) : ""});
			this.setState({typed: true});  			
		}       
    }
    handleKeyPress = (event) => {
        if(event.key === 'Enter'){
			this.goToPage();
        }
    }
	processUrl = (p) => {
		let sep  = this.state.url.split("?");
		let frst = sep[0];
		let last = sep.hasOwnProperty(1) ? `?${sep[1]}` : "";
		let str  = "";
		let inc  = 0;
		while(inc < frst.length){
			let charr = frst.substring(inc, (inc + 1));
			if(isNaN(charr)){
				str = `${str}${charr}`;
			}else{
				inc = frst.length;
			}
			inc++;
		}
		str = str.substring((str.length - 1)) === "/" ? str.substring(0, (str.length - 1)) : str;
		return `${GetStateUrlLocale()}${str}/${p}${last}`;
	}
    goToPage = () => {
		if(this.state.query <= this.props.total && this.state.query > 0){
			this.props.history(this.processUrl(this.state.query));
		}
	}
    generateUrl = (dir) => {
		let p = this.state.query;
		if(dir === 1){
			p++;
		}else{
			p = p > 1 ? p - 1 : 1;
		}
		return this.processUrl(p);
	}
    nxt = (act, clss) => {
		if(this.state.typed){
			this.setState({typed: false}, () => {
				this.goToPage();
			});
		}else{
			let p = this.state.query;
			if(act === 1){
				p++;
			}else{
				p = p > 1 ? p - 1 : 1;
			}
			if(p !== this.state.query && clss !== "pg-arr-off"){console.log("next");
				this.setState({query: p}, () => {
					this.props.history(this.processUrl(p));			
				});
			}
		}
    }
	linkArr = (dir, clss) => {
		let arr = dir === 1 ? `&#10095;` : `&#10094;`;
		if(["pg-arr-off"].includes(clss)){
			return (
				<div className={`${clss}`}>
					<span dangerouslySetInnerHTML={{__html: arr}} />
				</div>
			);
		}else{
			return (
				<Link to={this.generateUrl(dir, clss)} className={`${clss}`} onClick={() => this.nxt(dir, clss)}>
					<span dangerouslySetInnerHTML={{__html: arr}} />
				</Link>
			);
		}
	}
	render(){
		let total  = this.props.total && this.props.total !== 0? this.props.total : 1;
		let arrLft = this.state.query < 2 || this.props.total === 1 ? "pg-arr-off" : "pg-arr"; 
		let arrRgt = this.state.query > 0 && this.state.query < this.props.total ? "pg-arr" : "pg-arr-off"; 
        return (
            <div className="pg-cnt">
                <div className="fltr">
					<div className="pg-bx">
						{this.linkArr(0, arrLft)}
						<div className="pg-inp">
							<input type="text" name="p" value={this.state.query} onChange={this.handleChange} onKeyPress={this.handleKeyPress} className="pg-inp-css" maxLength="4" />
						</div>
						{this.linkArr(1, arrRgt)}
						<div className="pg-txt">
							{Trtor('general', 'pager.of')} {total}
						</div>
					</div>
                </div>
                <div className="clr"></div>
            </div>
        )
    }
}
export default withRouter(Pager);