import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Submit.css';
//-----------------------------------   utils   -----------------------------------
import { withRouter } from '../../utils/general/Url';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { GetStateUrlLocale } from '../../utils/general/General';
import { SetSeo } from '../../utils/general/Seo.js';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import plus from '../../assets/images/plus.png';

class Submit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
        }
		
	}
	componentDidMount(){
		SetSeo('submit', {tt: 'submit.title', dsc: 'meta.desc'}, []);
		uptBodyHeight();
	}
    goTo(url){
        this.props.history(`${GetStateUrlLocale()}${url}`);
    }
	render(){
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="sub-bd" id="js-exp-bd">
					<div className="sub-mid">
						<h2 className="sub-tit-h2">{Trtor('submit', 'submit.title')}&nbsp;</h2>
						<div className="mg-t10" style={{fontSize: "1rem"}}>
							{Trtor('submit', 'submit.sub')}
						</div>
						<div className="sub-all">
							<div className="sub-card">
								<div className="sub-txt">
									<Link to={`${GetStateUrlLocale()}/submit/kreyol`} className="card-title lk-inh">
										{Trtor('submit', 'kreyol.name')}
									</Link>
								</div>
								<div className="sub-add" onClick={() => this.goTo(`/submit/kreyol`)}>
									<img src={plus} style={{width:"20px"}} alt="plus sign"/>
								</div>
								<div className="clr"></div>
							</div>
							<div className="sub-card">
								<div className="sub-txt">
									<Link to={`${GetStateUrlLocale()}/submit/expression`} className="card-title lk-inh">
										{Trtor('submit', 'expression.name')}
									</Link>
								</div>
								<div className="sub-add" onClick={() => this.goTo(`/submit/expression`)}>
									<img src={plus} style={{width:"20px"}} alt="plus sign"/>
								</div>
								<div className="clr"></div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
export default withRouter(Submit);