export const init = {
    ht: {
		meta:{
			title: `Pratike & Aprann Vokabilè Ayisyen`,
			desc: `Aprann vokabilè Kreyol Ayitien gratis sou Diksyone.net. Sit modèn nou an anseye ou mo vokabilè Kreyol Ayitien nan yon kontèks itil k ap pèmèt ou kenbe sa ou appran`,
		},
        vocabulary: {
			meta:{
				title: `Lis vokabilè pou #var_1#`,
				desc: `Ogmante konesans ou ak lis de #var_2# mo sa yo nan kategori vokabilè #var_1#. Aprann ki jan yo sèvi ak yo nan definisyon yo ak egzanp ak plis anko ...`,
			},
            title: 'Vokabilè',
            sub: 'rezilta',
            search: 'Rechèch',
            word: 'mo',
            all: 'Tout Vokabilè',
            detail: 'Lis mo nan menm fanmi an.',
            empty: 'Nou pa jwenn anyen.',
        },
    },
    en: {
		meta:{
			title: `Practice & Learn Haitian Vocabulary`,
			desc: `Learn the Haitian Creole vocabulary for free on Diksyone.net. Our modern interface teaches you the Haitian Creole vocabulary words in context and helps you retain your knowledge`,
		},
		vocabulary: {
			meta:{
				title: `Vocabulary list of #var_1#`,
				desc: `Increase your knowledge with this list of #var_2# words from the #var_1# vocabulary category. Learn how to use them from their definition and exemples and a lot more...`,
			},
            title: 'Vocabulary',
            sub: 'results',
            search: 'Search',
            word: 'words',
            all: 'All Vocabulary',
            detail: 'List of words of the same family.',
            empty: 'We did not find anything.',
        },
    },
    fr: {
		meta:{
			title: `Pratiquer et apprendre le vocabulaire Haïtien`,
			desc: `Apprenez gratuitement le vocabulaire créole Haïtien sur Diksyone.net. Notre interface moderne vous enseigne les mots de vocabulaire créole haïtien en contexte et vous aide à conserver vos connaissances`,
		},
        vocabulary: {
			meta:{
				title: `Liste vocabulaire pour #var_1#`,
				desc: `Augmentez vos connaissances avec cette liste de mots #var_2# de la catégorie de vocabulaire de #var_1#. Apprenez à les utiliser à partir de leur définition et d'exemples et bien plus encore...`,
			},
            title: 'Vocabulaire',
            sub: 'résultats',
            search: 'Chercher',
            word: 'mots',
            all: 'Tous les Vocabulaires',
            detail: 'Liste de mots de la même famille.',
            empty: 'Nous n\'avons rien trouvé.',
        },
    }
}
