export const Env = {
	status:  'dev',
    nameSite:'Diksyonè',
    api:     '/api',
	prod:{
		base: 'https://diksyone.net',		
	},
	dev:{
		base: 'http://localhost/diksyone',		
	}
}
export const IsDev = () => {
	return !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
}
export const ApiUrl = function(){
	let url = `${Env.prod.base}${Env.api}`;
	if(IsDev()){ url = `${Env.dev.base}${Env.api}`; }
    return url;
}
export const BaseUrl = function(){
	let url = `${Env.prod.base}`;
	if(IsDev()){ url = `${Env.dev.base}`; }
    return url;
}
export const ExtUrl = function(){
	let url = ``;
	if(IsDev()){ url = `${Env.prod.base}`; }
    return url;
}
export default Env;