export const init = {
    ht: {
		meta:{
			desc: `404. Nou pa jwenn paj lan`,
		},
		title: 'Nou pa jwenn paj lan',
		sub: 'Paj la yap chèche a pa egziste ankò oswa li ekspire',
		btn: 'Retounen nan paj akèy',
    },
    en: {
		meta:{
			desc: `404. Page not found`,
		},
		title: 'Page not found',
		sub: 'The page or ad you are looking for no longer exists or has expired',
		btn: 'Return to homepage',
    },
    fr: {
		meta:{
			desc: `404. Page non trouvée`,
		},
		title: 'Page non trouvée',
		sub: 'La page ou l\'annonce que vous recherchez n\'existe plus ou a expiré',
		btn: 'Retour à la page d\'accueil',
    }
}
