import { ObjHasKey } from '../general/Json';
export const PlaySound = (tabSound, key, path, clr, myInterval, _callBack) => {
	let color = `clr-${clr}`;
	let span  = Array.prototype.slice.call(document.getElementsByClassName("sylwrd"));
	//other sound
	let spanr = Array.prototype.slice.call(document.getElementsByClassName("sylrwrd"));
	
	if(ObjHasKey(tabSound, [key])){
		let elm   = tabSound[key];
		let sound = new Audio(`${path}/file/sound/${elm.file}`);
		let start = elm.start;
		let stop  = elm.stop;
		
		sound.currentTime = start;
		sound.play();
		
		span.map((elm, index) => {
			if(key === index){
				elm.classList.add(color);
			}else{
				elm.classList.remove(color);
			}
			if(spanr && ObjHasKey(spanr, [index])){
				if(key === index){
					spanr[index].classList.add(color);
				}else{
					spanr[index].classList.remove(color);
				}
			}
			return ''
		});				
		
		myInterval = setInterval(() => {
			if(sound.currentTime > stop){
				sound.pause();
				key++;
				clearInterval(myInterval);
				PlaySound(tabSound, key, path, clr, myInterval, _callBack);
			}
		},	150);
	}else{
		let nKey = key - 1;
		if(ObjHasKey(span, [nKey])){
			span[nKey].classList.remove(color);
			if(ObjHasKey(spanr, [nKey])){
				spanr[nKey].classList.remove(color);
			}
		}
		_callBack();
	}
}
export default PlaySound;