export const init = {
    ht: {
        copyright: 'Copyright',
        terms: 'Regleman pou utilisasyon',
        contact: 'Kontakte nou',
        about: 'Sou nou',
        privacy: 'Règleman sou enfòmasyon prive',
    },
    en: {
        copyright: 'Copyright',
        terms: 'Terms of service',
        contact: 'Contact us',
        about: 'About us',
        privacy: 'Privacy Policy',
    },
    fr: {
        copyright: 'Droits d\'auteur',
        terms: 'Conditions d\'utilisation',
        contact: 'Contactez-nous',
        about: 'À propos de nous',
        privacy: 'Politique de confidentialité',
    }
}
