export const InitSlider = (idContainer, partClass, gaper) => {
	let marginPos = [];
    marginPos.push(0);
	
	let boxToMove 	   = document.getElementById(`slider-${idContainer}`);
	let widthContainer = boxToMove.offsetWidth;
	let insideBox 	   = 0;
	let part      	   = 0;
	let inc       	   = 0;
	let widthPart	   = 0;
	let gap       	   = gaper;
	let gaps     	   = 0;
	
	//calculate inside box width
	let arrElm = Array.prototype.slice.call(document.getElementsByClassName(`${partClass}`));
	arrElm.map((elm, index) => {
		part += elm.offsetWidth;
		inc++;
		return null;
	});

	gaps      = gap * (inc - 1);
	insideBox = part + gaps;
	//add position to array
	if(insideBox > widthContainer){
		arrElm.map((elm, index) => {
			widthPart  += index < inc ? elm.offsetWidth + gap : elm.offsetWidth;
			let sumPart = insideBox - widthPart;
			if(sumPart > widthContainer){
				marginPos.push(widthPart);
			}else{
				widthPart = insideBox - (widthContainer - 5);
				if(!marginPos.includes(widthPart) && widthPart > 10){
					marginPos.push(widthPart);
				}
			}
			inc++;
			return null;
		});
	}	
	return marginPos;
}
export const SlideElment = (dir, idContainer, marginPos) => {
	let div   = document.getElementById(`slider-${idContainer}`);
	let mgLft = div.style.marginLeft;
	mgLft = mgLft.length === 0 ? 0 : Math.abs(parseInt(mgLft));
	let index = marginPos.findIndex(elm => elm === mgLft);
	if(index >= 0){
		if(dir === 1){
			index++;
			if(marginPos.hasOwnProperty(index)){
				mgLft = Math.abs(marginPos[index]);
				div.style.marginLeft = `-${mgLft}px`;
			}
		}else if(index > 0){
			index--;
			mgLft = Math.abs(marginPos[index]);
			div.style.marginLeft = `-${mgLft}px`;
		}
	}
}
export default InitSlider;