import React, { Component } from 'react';
import axios from "axios";
import '../Vocabulary.css';
import './VocabularyView.css';
//-----------------------------------   utils   -----------------------------------
import {ApiUrl} from '../../../utils/general/Env.js';
import { ObjHasKey } from '../../../utils/general/Json.js';
import store from '../../../store/store.js';
import { uptBodyHeight } from '../../../utils/html/Html.js';
import { withParamsRouter, getParams } from '../../../utils/general/Url';
import { GetStateUrlLocale, GetStateLocale } from '../../../utils/general/General';
import { SetSeo } from '../../../utils/general/Seo.js';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../../body/header/Header';
import Footer from '../../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import load from '../../../assets/images/load-clr.gif';

const basicUrl = "vocabulary";
class VocabularyView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			list: [],
			img: '',
			name: 'loading...',
			qty: 'loading...'
        }
	}
	componentDidMount(){
		SetSeo('vocabulary', {tt: 'meta.title', dsc: 'meta.desc'}, []);
		uptBodyHeight();
		this.browse();
	}
	browse(){
		const name = getParams(this.props, "name", "");
		this.setState({loading: true}, () => {
			const data = { 
				act: 2, 
				u:   name,
				lg: GetStateLocale()
			};
			axios.get(`${ApiUrl()}/${basicUrl}.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({list: res.data.htm}, () => {
						SetSeo('vocabulary', 
							{tt: 'vocabulary.meta.title', dsc: 'vocabulary.meta.desc'},
							[`var_1#${res.data.msg.l}`, `var_2#${res.data.msg.n}`]
						);
						this.setState({loading: false});
						this.setState({name: !!res.data.msg.ts ? res.data.msg.ts : res.data.msg.l});
						this.setState({img: res.data.msg.g});
						this.setState({qty: res.data.msg.n});
					});
				}else{
					this.setState({list:[]}, () => {
						this.setState({loading: false});
					});
				}
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			});
		});
	}
	goToWrd(elm){
		store.dispatch({ type: 'SET_WRD', payload: {i:elm.i, u:elm.u, l:elm.l} });
		this.props.history(`${GetStateUrlLocale()}/kreyol/${elm.u}`);
	}
	goPrev(){
		let prev = store.getState();
		let prv  = `/vocabulary`;
		if(ObjHasKey(prev, ["prv"])){
			let get  = ``;
			prev = store.getState().prv
			if(ObjHasKey(prev, ["q"])){
				get = prev.q ? `?q=${prev.q}` : get;
			}if(ObjHasKey(prev, ["p"])){
				prv += prev.q ? `/${prev.p}` : ``;
			}
			prv += `${get}`;
		}
		this.props.history(`${GetStateUrlLocale()}${prv}`);
	}
	render(){
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="vc-bd" id="js-exp-bd">
                    <div className="vc-mid">
                        <div className="pd-b5 fntz14">
                            <span className="vcv-link" onClick={() => this.goPrev()}>
								&#10096; {Trtor('vocabulary', 'vocabulary.all')}
							</span>
                        </div>
                        <div className="vc-bx">                            							
                            {this.state.loading && 
								<div style={{padding:"20px"}}>
									<h3>{this.state.name}</h3>
									<div className="vc-bx-nb">&nbsp;</div>	
								</div>								
							}
                            {!this.state.loading && 
								<div>
									<div className="vc-hd-img">									
										<img src={`https://diksyone.net${this.state.img}`} 
											className="imgfit" alt={this.state.name} />
									</div>
									<div className="vc-hd-txt">
										<h3 style={{margin:"0"}}>{this.state.name}</h3>
										<div className="vc-bx-nb">
											{this.state.qty} {Trtor('vocabulary', 'vocabulary.word')}
										</div>
									</div>
									<div className="clr"></div>
								</div>
							}
                        </div>
                        <div className="mg-t5 fntz14">
							{Trtor('vocabulary', 'vocabulary.detail')}
						</div>
						{this.state.loading &&
							<div className="mg-t20 brw-wait">
								<img src={load} alt="loading..."/>
							</div>
						}
						{!this.state.loading &&
							<div className="vc-lst">
								{this.state.list.map((elm, key) => {
									return (
										<div className="vc-lst-elm" key={`lst-${key}`}>
											<h4>
												<span onClick={() => this.goToWrd(elm)} className="vcv-link">
													<a href={`${GetStateUrlLocale()}/kreyol/${elm.u}`} className="lk-inh" onClick={() => { this.goToWrd(elm); return false; }}>{elm.l}</a>
												</span>
											</h4>
											{elm.d}.
										</div>
									)
								})}								
							</div>
						}
						{!this.state.loading && this.state.list.length === 0 &&
							<div className="mg-t20" style={{textAlign:"center", color:"#aeaeae"}}>
								<h3>{Trtor('vocabulary', 'vocabulary.empty')}</h3>
							</div>
						}                    
                    </div>
                </div>
                <Footer />
            </div>
		);
	}
}
export default withParamsRouter(VocabularyView);