import './HtmlListSideSide.css';
import { Link } from "react-router-dom";
import { GetStateUrlLocale } from '../../../utils/general/General';
function HtmlListSideSide(props){
	let   attr = props.attr;
	let   arr  = props.arr;
	let   inc  = 0;
	const half = Math.ceil(arr.length / 2);
	return (
		<div className="kr-ex-two">
			<div key={`kr-ex-1`}>
			{
				arr.map((elm, key) => {
					if(inc < half){
						inc++;
						let u = elm.hasOwnProperty("n") ? `/kreyol/${elm.n}` : "";
						return (
							<div className="df-def-ftxt" key={`${attr}-${inc}`}>
								<div className="df-ex-nb">
									{inc}
								</div>
								<div className="df-ex-txt">
								{u.length > 0 &&
									<Link to={`${GetStateUrlLocale()}${u}`} className="lk-blk212">
										<span dangerouslySetInnerHTML={{__html: elm.e}} />
									</Link>
								}
								{u.length === 0 &&
									<span dangerouslySetInnerHTML={{__html: elm.e}} />
								}
								</div>
								<div className="clr"></div>
							</div>
						);
					}
					return null;
				})
			}
			</div>
			<div key={`kr-ex-2`}>
			{
				arr.map((elm, key) => {
					if(key >= half){
						inc++;
						let u = elm.hasOwnProperty("n") ? `/kreyol/${elm.n}` : "";
						return (
							<div className="df-def-ftxt" key={`${attr}-${inc}`}>
								<div className="df-ex-nb">
									{inc}
								</div>
								<div className="df-ex-txt">
								{u.length > 0 &&
									<Link to={`${GetStateUrlLocale()}${u}`} className="lk-blk212">
										<span dangerouslySetInnerHTML={{__html: elm.e}} />
									</Link>
								}
								{u.length === 0 &&
									<span dangerouslySetInnerHTML={{__html: elm.e}} />
								}
								</div>
								<div className="clr"></div>
							</div>
						);
					}
					return null;
				})
			}
			</div>
		</div>
	);
}
export default HtmlListSideSide;