import React, { Component } from 'react';
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import "bootstrap/dist/css/bootstrap.min.css";
import './KreyolEdit.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../../utils/general/Env';
import { WORD_ELEMENT, NM_WRD } from '../../../file/Constant';
import { SetObjVal, ObjRmvKey, ObjHasKey } from '../../../utils/general/Json.js';
import { StripHtml } from '../../../utils/html/Html.js';
import { validateEmail } from '../../../utils/general/String.js';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import FieldWord from '../../form/field-word/FieldWord';
//----------------------------------- Other -----------------------------------
import load from '../../../assets/images/load.gif';

class KreyolEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			em: "",
			emErr: "",
			send: 0,
			obj: {
				menu:{
					show: false,
					visible: [0,1,2],
					tab: 0,
					keyOn: 0
				},
				word:{
					key:this.props.wrk,
				},
				form:[
					{ nm: WORD_ELEMENT[0].nm, nb: 1, type: "input", data:[] },
					{ nm: WORD_ELEMENT[1].nm, nb: 1, type: "selTxt", data:[] },
					{ nm: WORD_ELEMENT[2].nm, nb: 1, type: "input", data:[] },
					{ nm: WORD_ELEMENT[3].nm, nb: 1, type: "text", data:[] },
					{ nm: WORD_ELEMENT[4].nm, nb: 1, type: "text", data:[] },
					{ nm: WORD_ELEMENT[5].nm, nb: 1, type: "input", data:[] },
				]
			}
        }
		this.uptMenuKey = this.uptMenuKey.bind(this);
		this.openMenu   = this.openMenu.bind(this);
	}
	componentDidMount(){
		let wordKey = this.state.obj.word.key;
		WORD_ELEMENT.map((elment, index) => {
			let tab  = [];
			let kObj = elment.attr;
			if(elment.nm === NM_WRD.word){
				if(ObjHasKey(this.props.data[wordKey], ["l"])){
					tab.push(this.props.data[wordKey].l);
				}
			}
			if(elment.nm === NM_WRD.syllable){
				if(ObjHasKey(this.props.data[wordKey], ["sl"])){
					tab.push(this.props.data[wordKey].sl);
				}
			}
			if(ObjHasKey(this.props.data[wordKey], [kObj])){
				this.props.data[wordKey][kObj].map((elm, ind) => {
					if(elment.nm === NM_WRD.expression){
						tab.push(StripHtml(elm.e));
					}else{
						tab.push(elm);
					}
					return '';
				});				
			}
			if(tab.length > 0){
				let obj = { ...this.state.obj };
				SetObjVal(obj, `form.${index}.data`, tab);
				this.setState({obj: obj});
			}
			return '';
		});
	}
	addData(val){
		let keyOn = this.state.obj.menu.keyOn;
		if(WORD_ELEMENT[keyOn].addLimit > this.state.obj.form[keyOn].data.length){
			let tab   = this.state.obj.form[keyOn].data;
			tab.push(val);
			let obj = { ...this.state.obj };
			SetObjVal(obj, `form.${keyOn}.data`, tab);
			this.setState({obj: obj});
		}
	}
	rmvData(key){
		let keyOn = this.state.obj.menu.keyOn;
		let tab   = ObjRmvKey(this.state.obj.form[keyOn].data, key);
		let obj = { ...this.state.obj };
		SetObjVal(obj, `form.${keyOn}.data`, tab);
		this.setState({obj: obj});
	}
	saveData(val){
		let keyOn = this.state.obj.menu.keyOn;
		let obj = { ...this.state.obj };
		SetObjVal(obj, `form.${keyOn}.data`, val);
		this.setState({obj: obj});
	}
	uptFormData(key){
		let tab = [];
		if(WORD_ELEMENT[key].nm !== NM_WRD.word){		
			this.state.obj.form[key].data.map((elm, index) => {
				tab.push(elm);
				return '';
			});		
		}else{
			tab = this.state.obj.form[key].data;
		}
		if(tab.length === 0){
			tab.push(WORD_ELEMENT[key].empty);
		}
		let obj = { ...this.state.obj };
		SetObjVal(obj, `form.${key}.data`, tab);
		this.setState({obj: obj});
	}
	openMenu(elm){
		if(!elm.target.classList[0].includes("modal-tab-mn-elm")){
			let obj = { ...this.state.obj };
			SetObjVal(obj, 'menu.show', !this.state.obj.menu.show);
			this.setState({obj: obj});
		}
	}
	uptTab(index, key, tab){
		let obj = { ...this.state.obj };
		let vis = this.state.obj.menu.visible;
		if(!vis.includes(key)){
			vis[this.state.obj.menu.tab] = key;
			SetObjVal(obj, 'menu.visible', vis);
		}
		if(tab){
			SetObjVal(obj, 'menu.tab',   index);
			SetObjVal(obj, 'menu.keyOn', key);
			this.setState({obj: obj});
			this.uptFormData(key);
		}
	}
	uptMenuKey(key){
		this.uptTab(key, key, false);
		let obj = { ...this.state.obj };
		SetObjVal(obj, 'menu.show',  false);
		SetObjVal(obj, 'menu.keyOn', key);
		this.setState({obj: obj});
		this.uptFormData(key);
	}
	handleChange(event){
		this.setState({em: event.target.value});
	}
	btnClicked(act){
		if(act === 0){
			this.setState({send: act});
			this.props.toggleModal();
		}else if(act < 0){
			this.setState({send: 0});
		}else{
			this.setState({send: act});
		}
	}
	saveEdit(){
		if(validateEmail(this.state.em)){
			let wordKey = this.state.obj.word.key;
			this.setState({emErr: ""});
			this.setState({send: 2});
			axios.post(`${ApiUrl()}/edit.php`, {
					act: 1, 
					iw: this.props.data[wordKey].i, 
					kw: wordKey, 
					em: this.state.em, 
					o:  this.state.obj.form}).then(res => {
				if(res.data.cd === 1){
					this.setState({send: 3});
				}
			});
		}else{
			this.setState({emErr: " brd-err"});
		}
	}
	render(){
		let _this = this;
		return (
			<Modal show={this.props.show} onHide={() => {}}>
				<div className={"modal-tab"}>
					{
						this.state.obj.menu.visible.map((key, index) => {
							let clss = index === this.state.obj.menu.tab ? " modal-tab-elm-on" : "";
							let sup  = WORD_ELEMENT[key].nm === NM_WRD.word ? 
								`&nbsp;<sup>${_this.state.obj.word.key + 1}</sup>` : "";
							return (
								<div className={`modal-tab-elm${clss}`} onClick={() => this.uptTab(index, key, true)} key={`tab-${index}`}>
									{ Trtor('kreyol', `word_elem.${WORD_ELEMENT[key].nm}`) } 
									<div className="modal-tab-sup"><sup dangerouslySetInnerHTML={{__html: sup}} /></div>
								</div>
							);
						})
					}
					<div className={"modal-tab-elm modal-tab-plus"} onClick={(e) => this.openMenu(e)}>
						&#8230; 
						<div className={"modal-tab-mn"} style={{display: this.state.obj.menu.show ? "block" : "none"}}>
							{
								WORD_ELEMENT.map((elm, index) => {
									if(!this.state.obj.menu.visible.includes(index)){
										return (
											<div className={"modal-tab-mn-elm"} onClick={() => this.uptMenuKey(index)} key={`tabmn-${index}`}>
												{ Trtor('kreyol', `word_elem.${elm.nm}`) }
											</div>
										);
									}
									return null;
								})
							}							
						</div>
					</div>
					<div className={"modal-tab-elm"}>
						&nbsp;
					</div>
				</div>
				<Modal.Body>
				{
					_this.state.send !== 3 &&
						<FieldWord 
							form={this.state.obj.form[this.state.obj.menu.keyOn]} 
							keyOn={this.state.obj.menu.keyOn} 
							addField={(v)  => this.addData(v)} 
							rmvField={(k)  => this.rmvData(k)} 
							saveField={(k) => this.saveData(k)} 
						/>
				}
				{
					_this.state.send === 3 &&
						<div class="alert alert-success" role="alert" style={{margin: "0"}}>
							{Trtor('kreyol', 'edit.success')}
						</div>
					
				}
				</Modal.Body>
				<Modal.Footer>
					<div className={this.state.send === 0 ? "" : "dispnone"}>
						<div className={"fltl mg-r10"}>
							<Button variant="secondary" onClick={() => this.btnClicked(0)}>
								{Trtor('kreyol', 'edit.close')}
							</Button>
						</div>
						<div className={"fltl"}>
							<Button variant="primary" onClick={() =>  this.btnClicked(1)}>
								{Trtor('kreyol', 'edit.save')}
							</Button>
						</div>
						<div className={"clr"}></div>
					</div>
					<div className={this.state.send === 1 ? "" : "dispnone"} style={{width: "100%"}}>					
						<div className={"fld-wrd-em"}>
							<div className={"fld-wrd-emt"}>
								<input type="text" 
									className={"fld-wrd-inp"+this.state.emErr} 
									placeholder={Trtor('kreyol', 'edit.email')} 
									value={this.state.em} 
									style={{height: "38px"}} 
									onChange={(e) => this.handleChange(e)} />
							</div>
							<div className={"fld-wrd-emx"}>
								<div className="crsr cent-mid fld-wrd-add fld-wrd-x" 
									style={{margin: "0px", height: "38px"}}
									onClick={() => this.btnClicked(-1)}>
									&#x2715;
								</div>
							</div>
							<div className={"clr"}></div>
						</div>
						<div className={"fld-wrd-btn"}>							
							<Button variant="primary" onClick={() => this.saveEdit()}>
								{Trtor('kreyol', 'edit.confirm')}
							</Button>
						</div>
						<div className={"clr"}></div>
					</div>					
					<div className={this.state.send === 2 ? "" : "dispnone"}>
						<img src={load} alt="loading..." style={{width: "35px"}}/>
					</div>
					<div className={this.state.send === 3 ? "" : "dispnone"}>
						<Button variant="secondary" onClick={() => this.btnClicked(0)}>
							{Trtor('kreyol', 'edit.close')}
						</Button>					
					</div>
				</Modal.Footer>
			</Modal>
		);
	}
}
export default KreyolEdit;