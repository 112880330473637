export const init = {
    ht: {
        menu: {
            about: 'Sou nou',
            sitemap: 'Kat sit entènèt',
            rule: 'Regleman itilisasyon',
            contact: 'Kontakte nou',
        },
        lang: {
            ht: 'Kreyòl',
            en: 'Angle',
            fr: 'Franse',
        },
    },
    en: {
        menu: {
            about: 'About us',
            sitemap: 'Sitemap',
            rule: 'Terms of use',
            contact: 'Contact us',
        },
        lang: {
            ht: 'Creole',
            en: 'English',
            fr: 'French',
        },
    },
    fr: {
        menu: {
            about: 'A propos',
            sitemap: 'Plan du site',
            rule: 'Conditions utilisation',
            contact: 'Contactez-nous',
        },
        lang: {
            ht: 'Créole',
            en: 'Anglais',
            fr: 'Français',
        },
    }
}
