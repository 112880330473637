export const SetObjVal = (obj, propertyPath, val) => {
	let properties = Array.isArray(propertyPath) ? propertyPath : propertyPath.split(".")

	// Not yet at the last property so keep digging
	if(properties.length > 1){
		// The property doesn't exists OR is not an object (and so we overwritte it) so we create it
		if (!obj.hasOwnProperty(properties[0]) || typeof obj[properties[0]] !== "object") obj[properties[0]] = {}
		// We iterate.
		return SetObjVal(obj[properties[0]], properties.slice(1), val)
		// This is the last property - the one where to set the value
	}else{
		// We set the value to the last property
		obj[properties[0]] = val;
		return true; // this is the end
	}
}
export const ObjHasKey = (obj, keys) => {
	let nObj =  null;
	let res  = true;
	keys.map((key, index) => {
		if(nObj === null && obj.hasOwnProperty(key)){
			nObj = obj[key];
		}else if(nObj !== null && nObj.hasOwnProperty(key)){
			nObj = obj[key];
		}else { res = false; }
		return res;
	});
	return res;
}
export const ObjRmvKey = (obj, key) => {
	let nObj = [];
	Object.keys(obj).map(function(el, index){
		if(key !== index){
			nObj.push(obj[index]);
		}
		return nObj;
	});	
	return nObj;
}
export const AddToArray = (arr, valStructure) => {
	arr.push("111");
	return arr;
}
export default SetObjVal;