import store from '../../store/store';
import { GetStateLocale } from './General';
import { ReplaceVarWithValViaTab } from './String';
import { rmvLocale } from './Url';
import { Trtor } from './Translator';
import { TITLE_WEB, LANGUAGE, URL_WEB } from '../../file/Constant';

export const Title = (txt) => {
	document.title = `${txt} ${TITLE_WEB}`;
}
export const MetaDesc = (txt) => {
	document.querySelector('meta[name="description"]').setAttribute("content", `${txt}.`);
}
export const MetaAlternate = () => {
	let lg  = GetStateLocale();
	let url = rmvLocale();
	LANGUAGE.map((lang, index) => {
		if(!["ht", lg].includes(lang)){
			let link = document.createElement('link');
			let nurl = `${URL_WEB}/${lang}${url}`;
			let res  = searchTagType("link", "hreflang", lang);
			if(!res[0]){
				link.rel      = "alternate";
				link.hreflang = `${lang}`;
				link.href     = `${nurl}`;
				document.getElementsByTagName('head')[0].appendChild(link);
			}else{
				res[1].setAttribute("hreflang", `${lang}`);
				res[1].setAttribute("href",     `${nurl}`);
			}	
		}
		return null;
	});
	let res  = searchTagType("link", "hreflang", "x-default");
	if(res[0]){
		url = url.length > 0 ? url : "/";
		res[1].setAttribute("href", `${URL_WEB}${url}`);
	}
}
export const MetaContentType = () => {
	let meta = document.createElement('meta');
	if(!searchTagType("meta", "http-equiv", "content-type")[0]){
		meta.httpEquiv = "content-type";
		meta.content   = "text/html; charset=UTF-8";
		document.getElementsByTagName('head')[0].appendChild(meta);
	}
	meta = document.createElement('meta');
	if(!searchTagType("meta", "http-equiv", "X-UA-Compatible")[0]){
		meta.httpEquiv = "X-UA-Compatible";
		meta.content   = "IE=edge,chrome=1";
		document.getElementsByTagName('head')[0].appendChild(meta);
	}
	
	meta = document.createElement('meta');
	if(!searchTagType("meta", "http-equiv", "content-language")[0]){
		meta.httpEquiv = "content-language";
		meta.content   = `${GetStateLocale()}`;
		document.getElementsByTagName('head')[0].appendChild(meta);
	}
}
export const searchTagType = (tag, attName, val) => {
	let elm   = null;
	let found = false;
	let tags  = document.getElementsByTagName(tag);
	for(let i = 0; i < tags.length; i++){ 
		if(tags[i].getAttribute(attName) === val){
			found = true;
			elm   = tags[i];
		}
	}
	return [found, elm];
}
export const SeoTitleDesc = (cat, path, val) => {
	let title = Trtor(cat, path.tt);
	let desc  = Trtor(cat, path.dsc);
	if(val.length > 0){
		title = ReplaceVarWithValViaTab(Trtor(cat, path.tt),  val);
		desc  = ReplaceVarWithValViaTab(Trtor(cat, path.dsc), val);
	}
	Title(title);
	MetaDesc(desc);
	MetaAlternate();
}
export const SetSeo = (cat, path, val) => {	
	SeoTitleDesc(cat, path, val);
	MetaContentType();	
	store.subscribe(() => {
		SeoTitleDesc(cat, path, val);
	});
}
export default SetSeo;