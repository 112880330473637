import React, { Component } from 'react';
import './FieldWord.css';
//-----------------------------------   utils   -----------------------------------
import { WORD_ELEMENT, NM_WRD, WORD_TYPE } from '../../../file/Constant';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
class FieldWord extends Component {
	constructor(props) {
		super(props);
		this.state = {
			form: this.props.form,
			val:  this.props.form.data,
			ttt:  "aa",
        }    
		this.handleChange = this.handleChange.bind(this);
		this.handleChangeObj = this.handleChangeObj.bind(this);
	}
	componentDidUpdate(){ 
		if(this.state.form !== this.props.form || this.state.val.length !== this.props.form.data.length){
			this.setState({form: this.props.form}, () => {
				this.setState({val: this.props.form.data});
			});
		}
	}
	componentDidMount(){ 
	}
	handleChange(key, event){
		let obj  = this.state.val;
		obj[key] = event.target.value;
		this.setState({val: obj});
	}
	handleBadge(event){
		let htm = "";
		let txt = event.target.innerText;
		let arr = txt.split(/(\s+)/);
		if(this.state.val[0].c !== arr.length){
			arr.map((elm, index) => {
				if(elm.trim().length > 0){
					htm += `<span class="fld-badge-span" id="span-${index}">${elm}</span> `;
				}else{
					htm += ` ${elm}`;
				}
				return null;
			});
			this.setState({val: [{h:htm, c:arr.length}]}, () => {
				let elm = document.getElementById('divedit');
				this.setEndOfContenteditable(elm);
				this.props.saveField([{h:htm, c:arr.length}]);
			});
		}
	}
	handleChangeObj(key, kObj, event){
		let obj        = this.state.val;
		obj[key][kObj] = event.target.value;		
		this.setState({val: obj});
	}
	fieldAddRmvBtn(elm, key, isLast, addLimit, mgt){
		if(isLast){
			if(addLimit > 0){
				return (
					<div className={`hd-mn-b cent-mid fld-wrd-add`} 
						style={{margin:`${mgt} 0 0 0`}}
						onClick={() => this.props.addField(elm)}
					>
						&#x2795;
					</div>
				);
			}
			return (
				<div className="" style={{margin:"0"}}>&nbsp;</div>
			);
		}else{
			return (
				<div className={`hd-mn-b cent-mid fld-wrd-add fld-wrd-x`}
					style={{margin:`${mgt} 0 0 0`}}
					onClick={() => this.props.rmvField(key)}
				>
					&#x2715;
				</div>
			);
		}
	}
	fieldInput(key, isLast, addLimit){
		let mgt = key === 0 ? "" : " mg-t15";
		let nb  = key + 1; 
		return (
			<div className={`fld-wrd-inps${mgt}`} key={`inp-${key}`}>
				<div className={"fld-inps-nb"}>
					{nb}
				</div>
				<div className={"fld-inps-inp"}>
					<input type="text" className={"fld-wrd-inp"} 
						value={this.state.val[key]} 
						onChange={(e) => this.handleChange(key, e)} />
				</div>
				{this.fieldAddRmvBtn(WORD_ELEMENT[this.props.keyOn].empty, key, isLast, addLimit, "5px")}
			</div>
		);
	}
	fieldSelTxt(elm, key, isLast, addLimit){
		let mgt = key === 0 ? "" : " mg-t30";
		let nb  = key + 1; 
		return (
			<div className={`${mgt}`} key={`seltxt-${key}`}>
				<div className={"fld-wrd-seltxts"}>
					<div className="fld-wrd-selnb">
					{nb}&nbsp; -
					</div>
					<div className="fld-wrd-selsel">
						<select name="t" id="t" className="fld-wrd-sel" 
							value={elm.ti}
							onChange={(e) => this.handleChange(key, e)}
						>
						{
							WORD_TYPE.map((opt, index) => {
								return (
									<option key={`opt-${index}`} 
										value={opt.id}>
											{Trtor('kreyol', `word_type.${opt.tk}.nm`)}
										</option>
								);
							})
						}
						</select>
					</div>
				</div>
				<div className={`fld-wrd-seltxt mg-t10`}>				
					<div className={"fld-wrd-seltxti"}>
						<textarea name="msg" maxLength="500" className="fld-wrd-txtara" 
							data-lt-tmp-id="lt-371486" 
							spellCheck="false" 
							data-gramm="false"
							value={this.state.val[key].d}
							onChange={(e) => this.handleChangeObj(key, "d", e)}
						></textarea>
					</div>
					{this.fieldAddRmvBtn(WORD_ELEMENT[this.props.keyOn].empty, key, isLast, addLimit, "0px")}				
				</div>
			</div>
		);
	}
	fieldBadge(key){
		return (
			<div className={`fld-wrd-div`} id={`divedit`} 
				key={`badge-${key}`}
				contentEditable="true"
				suppressContentEditableWarning={true}
				onKeyUp={(e) => this.handleBadge(e)}
				dangerouslySetInnerHTML={{__html: this.state.val[key].h}}
			/>			
		);
	}
	setEndOfContenteditable(editableElem){
		var range, selection;
		//Firefox, Chrome, Opera, Safari, IE 9+
		if(document.createRange){
			range = document.createRange();//Create a range (a range is a like the selection but invisible)
			range.selectNodeContents(editableElem);//Select the entire contents of the element with the range
			range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
			selection = window.getSelection();//get the selection object (allows you to change selection)
			selection.removeAllRanges();//remove any selections already made
			selection.addRange(range);//make the range you have just created the visible selection
		}//IE 8 and lower
		else if(document.selection){ 
			range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
			range.moveToElementText(editableElem);//Select the entire contents of the element with the range
			range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
			range.select();//Select the range (make it the visible selection
		}
	}
	render(){
        return (
			<div>
				{					
					this.state.val.map((elm, index) => {
						let last = this.state.val.length === (index + 1);
						if(WORD_ELEMENT[this.props.keyOn].nm === NM_WRD.definition){
							return this.fieldSelTxt(elm, index, last, WORD_ELEMENT[this.props.keyOn].addLimit)	
						}
						if(WORD_ELEMENT[this.props.keyOn].nm === NM_WRD.synonym){
							return this.fieldBadge(index)	
						}
						return this.fieldInput(index, last, WORD_ELEMENT[this.props.keyOn].addLimit)						
					})
				}
            </div>
        )
    }
}
export default FieldWord;