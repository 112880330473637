import React, { Component } from 'react';
import './KreyolDefinition.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
const firstElm:number = 0;
class KreyolDefinition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			keyDef: 0,
			definition: [],
			see: [],
		}
	}
	process(){
		let key         = this.state.keyDef;
		let see         = [];
		let groupUpType = [];
		if(this.props.wrd.hasOwnProperty(key) &&
			this.props.wrd[key].hasOwnProperty("def")
		){
			this.props.wrd[key].def.map((el, ind) => {
				if(el.t === "see"){
					return el.d.split(",").map((seeElm, i) => {
						see.push(seeElm.trim());
						return null;
					});					
				}else{
					let tl = el.t.replaceAll(" ", "-");
					if(!groupUpType.hasOwnProperty(tl)){
						groupUpType[tl] = [];					
					}
					groupUpType[tl].push({
						"d" : el.d,
						"t" : el.t,
						"tk": el.tk
					});
					return null;
				}
			});
			this.setState({definition: groupUpType});
			this.setState({see: see});	
		}
	}
	componentDidMount(){
		this.setState({keyDef: this.props.index}, () => {
			this.process();			
		});
	}
	render(){
		let nb 	   = 0;		
		let _this  = this;
		let sumSee = '';
		let marg   = this.props.marg ? ` ${this.props.marg}` : '';
		if(Object.keys(this.state.definition).length === 0 &&
			this.state.see.length === 0){ return null; }
		else{
			return (
				<div className={`df-wrd-box${marg}`}>
					<div className="df-wrd-tit">
						<div className="df-wrd-oth">
							<h4 style={{margin:'0'}}>
								{Trtor('kreyol', 'word_elem.definition')}
							</h4>
						</div>
						{
							this.props.wrd.length > 1 &&
							<div className="df-wrd-nb">{this.props.nb}</div>
						}		
						<div className="df-wrd-edt">
							[<span className={"df-wrd-edt-lk"} 
								onClick={() => this.props.toggleEdit(this.props.index)}>
								{Trtor('kreyol', 'edit.label')}
							</span>]
						</div>			
						<div className="clr"></div>
					</div>
					<div className="df-wrd-def">
						{ 
							this.state.definition && 
							Object.keys(this.state.definition).map(function(elm, index){
								return (
									<div key={`def-${index}`}>
										<div className="df-def-typ">
											[{Trtor('kreyol', `word_type.${_this.state.definition[elm][firstElm].tk}.nm`)}]
										</div>
										<div className="df-wrd-adef">
										{
											_this.state.definition[elm].map((el, ind) => {
												nb++;
												return (
													<div key={`def-nbtxt-${ind}`}>
														<div className="df-def-nb">
															{nb}
														</div>
														<div className="df-def-txt">
															{el.d}
														</div>
														<div className="clr"></div>
													</div>
												)
											})
										}
										</div>
									</div>
								)
							})
						}
						{
							this.state.see.length > 0 &&
							this.state.see.map((el, ind) => {
								sumSee = sumSee ? `${sumSee}, ${el}`: `${el}`;
								return null;
							}) &&
							<div className="df-def-see">
								(Gade tou: <span className="df-def-see-txt">{sumSee}</span>)
							</div>	
						}
					</div>
				</div>
			);
		}
	}
}
export default KreyolDefinition;