import React, { Component } from 'react';
import axios from "axios";
import './KreyolSound.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl, BaseUrl } from '../../../utils/general/Env';
import { PlaySound } from '../../../utils/sound/Sound.js';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Other -----------------------------------
import mute from '../../../assets/images/mute.png';
import soundb from '../../../assets/images/sound-b.png';
import soundr from '../../../assets/images/sound-r.png';
let myInterval;
class KreyolSound extends Component {
	constructor(props) {
		super(props);
		this.state = {
			syllable: this.props.syllable,
			wrd: this.props.wrd,
			sound: [],
			disableBtn: false,
			showMute: false,
			mic: "",
		}
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.playSound !== this.props.playSound){
			if(!this.state.disableBtn && this.props.playSound === true){
				this.playSound(0);
			}
		}
	}
	componentDidMount(){
		this.getSound();
	}
	getSound(){
		const data = { act: 1, i: this.state.wrd[0].i }
		axios.get(`${ApiUrl()}/sound.php`, {params: data}).then(res => {
			this.setState({sound: res.data.msg});
			if(res.data.msg.length === 0){
				this.setState({showMute: true});
			}
		});
	}
	playSound(key){
		this.setState({disableBtn: true}, () => {
			this.setState({mic: " clr-redd70"});
			PlaySound(this.state.sound, key, BaseUrl(), "redd70", myInterval, () => {
				this.setState({disableBtn: false});
				this.setState({mic: ""});
				this.props.stopSound();
			});
		});		
	}
	render(){
		return (
			<div className="df-wrd-pro df-wrd-pro-snd">
				<div className="df-pro-snd">					
					{this.state.syllable.length > 0 &&
						<div className="df-pro-syl">
							[<span className={'sylwrdall'} dangerouslySetInnerHTML={{__html: this.state.syllable}} />]
						</div>
					}
					{this.state.syllable.length < 1 &&
						<div className="df-pro-syl df-pro-syl-na">
							[{Trtor("kreyol", "word.noSyllable")}]
						</div>
					}
					{!this.state.showMute &&
						<div className="df-pro-talk">
							<div className={`df-talk-btn df-talk-btn-anim${this.state.mic}`} onClick={() => { 
								if(!this.state.disableBtn){
									this.playSound(0);
								}
							}}>
								<img src={this.state.disableBtn ? soundr : soundb} alt="sound" width={"25px"}/>
							</div>
						</div>
					}
					{this.state.showMute &&
						<div className="df-nosnd">
							<img src={mute} alt="No sound" width={"25px"}/>
						</div>
					}
					<div className="df-pro-txt">
						<span className="dispnone">/sdsa/</span>&nbsp;
					</div>
					<div className="df-pro-txt dispnone">
						{Trtor("kreyol", "word.soundLike")}
						&nbsp;&nbsp;
						<span className='df-pro-like' onClick={() => {return false}}>&#9660;</span>
					</div>
				</div>
			</div>
		);
	}
}
export default KreyolSound;