export const init = {
    ht: {
		meta:{
			desc: `Diksyonè Kreyòl ki pi popilè pou moun k ap aprann Kreyòl Ayisyen. Siyifikasyon ak definisyon mo yo ak pwononsyasyon ak tradiksyon`,
		},
        partA: {
            title: 'Diksyonè Kreyòl Ayisyen',
            sub: 'Elaji vokabilè ou epi redekouvwi lang Kreyòl nou an',
            subsht: 'Elaji vokabilè ou an',
            placeholder: 'kisa ou vle aprann',
        },
        partB: {
            titleA: 'Pi gwo',
            titleB: 'diksyonè Ayisyen',
            titleC: 'sou entènèt',
            sub: 'Konsilte plis pase 50 000 mo ak santèn ekspresyon kreyòl',
        },
        partC: {
            titleA: 'Mo pou semèn nan',
            titleB: 'Mo popilè',
        },
        partD: {
            title: 'Diksyonè pa tèm',
            sub: 'Chwazi pami lis vokabilè mo ayisyen nou an, chak semèn nou pibliye nouvo lis',
            word: 'mo',
            ada: 'Bèt',
            adb: 'Infomatik',
            adc: 'Politik',
            add: 'Kwizin',
        },
        partE: {
            title: 'Lis alfabèt',
        },
        partF: {
            txtA: 'Diksyonè.net se lidè sou entènèt pou definisyon, pwononsyasyon, ak anpil lòt bagay ankò',
            txtB: 'Soti nan mo pou semèn nan pou rive nan fason Ayisyen itilize yo, Diksyonè.net déblotché sekrè nan lang Ayisyen an pou dè milyon de moun.',
        },
		game:{
			title: 'Jwe jwèt',
			sub: 'Pran plezi tout pandan yap amelyore lang ou ak konpetans ekri ou an',
		}
    },
    en: {
		meta:{
			desc: `The most popular dictionary and thesaurus for learners of Haitian Creole. Meanings and definitions of words with pronunciations and translations`,
		},
        partA: {
            title: 'Haitian Creole Dictionary',
            sub: 'Expand your vocabulary and rediscover our Creole language',
            subsht: 'Expand your vocabulary',
            placeholder: 'what do you want to learn',
        },
        partB: {
            titleA: 'Largest',
            titleB: 'Haitian dictionary',
            titleC: 'online',
            sub: 'Consult more than 50,000 words and hundreds of Creole expressions',
        },
        partC: {
            titleA: 'Word of the week',
            titleB: 'Popular words',
        },
        partD: {
            title: 'Dictionary by theme',
            sub: 'Choose from our list of Haitian vocabulary, we publish lists of vocabulary words every week',
            word: 'words',
            ada: 'Animals',
            adb: 'Computer science',
            adc: 'Politics',
            add: 'Kitchen',
        },
        partE: {
            title: 'Alphabet list',
        },
        partF: {
            txtA: 'Diksyonè.net is the leading online source for definitions, word pronunciation, and a whole lot more',
            txtB: 'From Word of the Day to the way natives use them, Diksyonè.net unlocks the secrets of the Haitian language to millions of people',
        },
		game:{
			title: 'Play games',
			sub: 'Flex your word muscles and improve your language and writing skills with a bit of fun',
		}
    },
    fr: {
		meta:{
			desc: `Le dictionnaire et thésaurus le plus populaire pour apprendre le créole Haïtien. Significations et définitions des mots avec prononciations et traductions`,
		},
        partA: {
            title: 'Dictionnaire créole Haïtien',
            sub: 'Élargissez votre vocabulaire du créole',
            subsht: 'Élargissez votre vocabulaire',
            placeholder: 'Que voulez-vous apprendre',
        },
        partB: {
            titleA: 'Le plus grand',
            titleB: 'dictionnaire haïtien',
            titleC: 'en ligne',
            sub: 'Consultez plus de 50 000 mots et des centaines d\'expressions créoles',
        },
        partC: {
            titleA: 'Le mot de la semaine',
            titleB: 'Mots populaires',
        },
        partD: {
            title: 'Dictionnaire par theme',
            sub: 'Choisissez parmi notre liste de vocabulaire de mot Haïtien, nous publions chaque semaine des nouvelles listes',
            word: 'mots',
            ada: 'Animaux',
            adb: 'Informatique',
            adc: 'Politique',
            add: 'Cuisine',
        },
        partE: {
            title: 'Liste alphabétique',
        },
        partF: {
            txtA: 'Diksyonè.net est le leader en ligne pour la définition, prononciation des mots Haïtien et bien plus',
            txtB: 'Du mot du jour à la façon dont les natives les utilisent, Diksyonè.net dévoile les secrets de la langue Haïtienne à des millions de personnes',
        },
		game:{
			title: 'Jouer à des jeux',
			sub: 'Développez et améliorez vos compétences linguistiques et rédactionnelles avec un peu de plaisir',
		}
    }
}
