export const init = {
    ht: {
        home: 'Lakay',
        menu: {
            label: 'Meni',
            expression: 'Ekspresyon',
            thesaurus: 'Thesaurus',
            wordWeek: 'Mo semèn nan',
            vocabulary: 'Vokabilè',
            contribute: 'Kontribye',
            alphabet: 'Alfabè A - Z',
            game: 'Jwèt',
        },
        search: {
            placeholder: 'Chèche',
            select:{
                creole: 'Kreyòl',
                creoleEng: 'Kreyòl - English',
                engCreole: 'English - Kreyòl',
                thesaurus: 'Thesaurus',
            }
        },
    },
    en: {
        home: 'home',
        menu: {
            label: 'Menu',
            expression: 'Expression',
            thesaurus: 'Thesaurus',
            wordWeek: 'Word of the week',
            vocabulary: 'Vocabulary',
            contribute: 'Contribute',
            alphabet: 'Alphabet A - Z',
            game: 'Games',
        },
        search: {
            placeholder: 'Search',
            select:{
                creole: 'Creole',
                creoleEng: 'Creole - English',
                engCreole: 'English - Creole',
                thesaurus: 'Thesaurus',
            }
        },
    },
    fr: {
        home: 'Accueil',
        menu: {
            label: 'Menu',
            expression: 'Expression',
            thesaurus: 'Thésaurus',
            wordWeek: 'Mot de la semaine',
            vocabulary: 'Vocabulaire',
            contribute: 'Contribuer',
            alphabet: 'Alphabet A - Z',
            game: 'Jeux',
        },
        search: {
            placeholder: 'Chercher',
            select:{
                creole: 'Créole',
                creoleEng: 'Créole - Anglais',
                engCreole: 'Anglais - Créole',
                thesaurus: 'Thésaurus',
            }
        },
    }
}
