export const init = {
    ht: {
        empty: 'Nou pa jwenn anyen',
    },
    en: {
        empty: 'No results found',
    },
    fr: {
        empty: 'Rien n\'a été trouvé',
    }
}
