import React, { Component } from 'react';
import './InputAutoComplete.css';
//-----------------------------------   utils   -----------------------------------
import { GetStateUrlLocale } from '../../../utils/general/General';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { withRouter } from '../../../utils/general/Url';
//----------------------------------- assets -----------------------------------
import search from '../../../assets/images/search-b8.png';
class InputAutoComplete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: '',
			showAuto: false,
        }
	}
	componentDidMount(){ 
        document.addEventListener('mousedown', this.handleClickOutside);
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleChange(e){
        this.setState({query: e.target.value}, () => {
			if(!this.state.showAuto && e.target.value){
				this.setState({showAuto: true});
			}else if(this.state.showAuto && !e.target.value){
				this.setState({showAuto: false});
			}
        });           
    }
	handleClickOutside = (event) => {     
        if(this.state.showAuto){
            let allowedDiv = ["inp-ac-bx", "inp-ac-fld", "inp-ac-icn", "inp-auto-bx", "inp-auto-elm"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showAuto: false});
                }
            }else{
                this.setState({showAuto: false});
            }
        }
    }
	submit(){
		if(ObjHasKey(this.props, ["close"])){
			this.props.close();
		}
		this.props.history(`${GetStateUrlLocale()}/${this.props.url}/?q=${this.state.query}`);
	}
	render(){
        //let display = this.state.showAuto ? '' : " dispnone";
        let display = this.state.showAuto ? ' dispnone' : " dispnone";
        return (
            <div className="inp-ac-bx">
				<div className="inp-ac-fld">
					<input type="text" 
						className={"fld-wrd-inp"} 
						placeholder={this.props.val ? this.props.val : this.props.label} 												
						value={this.state.query} 
						style={{border:"0"}}
						onChange={(e) => this.handleChange(e)}
						maxLength={this.props.max ? this.props.max : 50}/>
				</div>
				<div className="inp-ac-icn" onClick={() => this.submit()}>
					<img src={search} style={{width:"21px"}} alt={this.props.label} />
				</div>
				<div className={`inp-auto-bx${display}`}>
					<div className="inp-auto-elm" style={{border:"0"}}>
						das
					</div>
					<div className="inp-auto-elm">
						das
					</div>
					<div className="inp-auto-elm">
						das
					</div>
				</div>
			</div>
        )
    }
}
export default withRouter(InputAutoComplete);