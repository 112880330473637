import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GetStateUrlLocale } from './General';
import { ObjHasKey } from './Json.js';
import { URL_WEB } from '../../file/Constant';
import { IsDev } from './Env';

export const GoTo = function (url) {
    const history = useNavigate();
    history.push(url);
}
export const IsHttps = () => {
	return GetFullUrl().substring(0, 6) === "https:"
}
export const GetFullUrl = () => {
	return window.location.href;
}
export const GetUrl = function () {
    return window.location.pathname
}
export const SetHttpsUrl = () => {
	if(!IsDev() && !IsHttps()){
		window.location.href = `${URL_WEB}${GetUrl()}`;
	}
}
export const SetUrl = function (url) {
    return `${GetStateUrlLocale()}${url}`
}
export const andAddUrl = function (str, part) {
    let slash = part.length === 0 ? '' : '/';
    return str.length === 0 ? `${slash}${part}` : `${str}${slash}${part}`; 
}
export const rmvLocale = function () {
    let tab = GetUrl().split('/');
    let locale = '';
    tab.map((part, index) => {
        if(index === 1){
            if(!['en', 'fr'].includes(part)){
                locale = andAddUrl(locale, part)
            }
        }else if(part.length){
            locale = andAddUrl(locale, part)
        }
        return null
    })
    return locale
}
export const GetLocale = function () {
    let tab = GetUrl().split('/');
    let locale = tab.find(
       (part, index) => index === 1 && ['en', 'fr'].includes(part)
    )
    locale = locale ? locale : 'ht';
    return locale
}
export const GetRouteUrl = function (name) {
    const urlLocale = GetStateUrlLocale();
    switch (name) {
        case "home":
            return `${urlLocale}/`;
        case "kreyol":
            return `${urlLocale}/kreyol/:word`;
        case "word-week":
            return `${urlLocale}/word-of-the-week`;
        case "vocabulary":
            return `${urlLocale}/vocabulary`;
        case "vocabulary-a":
            return `${urlLocale}/vocabulary/:page`;
        case "vocabularyView":
            return `${urlLocale}/v/:name`;
        case "expression":
            return `${urlLocale}/expression`;
        case "expression-a":
            return `${urlLocale}/expression/:page`;
        case "expression-b":
            return `${urlLocale}/e/:title`;
        case "alphabetic":
            return `${urlLocale}/alphabetic`; 
        case "alphabetic-a":
            return `${urlLocale}/alphabetic/:letter`; 
        case "alphabetic-b":
            return `${urlLocale}/alphabetic/:letter/:page`; 
        case "submit":
            return `${urlLocale}/submit`; 
        case "submit-a":
            return `${urlLocale}/submit/kreyol`; 
        case "submit-b":
            return `${urlLocale}/submit/expression`; 
        case "game":
            return `${urlLocale}/games`; 
        case "game-a":
            return `${urlLocale}/game/worldle-solver`; 
        case "game-b":
            return `${urlLocale}/game/word-puzzle`;   
        case "game-c":
            return `${urlLocale}/game/the-hanged-man`;  
        case "contact":
            return `${urlLocale}/contact-us`;           
        default:
            return `${urlLocale}/`;
    }
}
export const getter = (key) => {
	const search = window.location.search;
	const params = new URLSearchParams(search);
	return params.get(`${key}`);
}
export const getParams = (props, key, val) => {
	return ObjHasKey(props, ["params", `${key}`]) ? props.params[key] : val;
}
export const withLocation = Component => props => {
	const location = useLocation();
	return <Component {...props} location={location} />;
};
export const withRouter = (Component) => {
    const Wrapper = (props) => {
        const history = useNavigate();
        return <Component history={history} {...props} />
    } 
    return Wrapper;
}
export const withParams = (Component) => {
    const Wrapper = (props) => {
        const params = useParams();
        return <Component params={params} {...props} />
    } 
    return Wrapper;
}
export const withParamsRouter = (Component) => {
    const Wrapper = (props) => {
        const params  = useParams();
		const history = useNavigate();
		const location = useLocation();
        return <Component 
			params={params} 
			history={history} 
			location={location}
			{...props} 
		/>
    } 
    return Wrapper;
}
export default withParamsRouter;