export const init = {
    ht: {
		meta:{
			desc: `Ki jan nou ka ede w? Tape kesyon ou isit. Lyen rapid: Vle sijere yon mo oswa gen yon kòmantè sou youn nan definisyon nou yo oswa sinonim? Ou pa ka jwenn repons lan nan Sant Èd nou an? Pataje kòmantè ou, kesyon, oswa sijesyon avèk nou. Si ou gen yon foto sou pwoblèm ou an, sa ap ede ekip nou an rezoud pwoblem ou an`,
		},
        title: 'Kontakte nou',
        sub: 'Ou gen kesyon? Nap kontan anpil pou reponn ou. Nou pral reponn ou nan 48 èdtan',
        name: 'Non, premye non',
        email: 'Imèl',
        msg: 'Messaj',
        btn: 'Voye yon mesaj',
        success: 'Nou resevwa mesaj ou an',
    },
    en: {
		meta:{
			desc: `How can we help you? Type in your question below. Quick Links:   Want to suggest a word or have a comment about one of our definitions or synonyms?   Can’t find the answer in our Help Center? You are welcome to share your comments, questions, or suggestions with us. If you have a screenshot of your issue, that will help our team to solve it`,
		},
        title: 'Contact us',
        sub: 'Do you have any questions? We will be very happy to answer you. We will respond within 48 hours',
        name: 'Last name, first name',
        email: 'E-mail',
        msg: 'Message',
        btn: 'Send your message',
        success: 'We received your message',
    },
    fr: {
		meta:{
			desc: `Comment pouvons-nous vous aider? Tapez votre question ci-dessous. Liens rapides : Vous voulez suggérer un mot ou avoir un commentaire sur l'une de nos définitions ou synonymes ? Vous ne trouvez pas la réponse dans notre centre d'aide ? Vous êtes invités à nous faire part de vos commentaires, questions ou suggestions. Si vous avez une capture d'écran de votre problème, cela aidera notre équipe à le résoudre`,
		},
        title: 'Contactez-nous',
        sub: 'Avez-vous des questions? Nous serons très heureux de vous répondre. Nous vous répondrons dans les 48 heures',
        name: 'Nom, Prénom',
        email: 'E-mail',
        msg: 'Message',
        btn: 'Envoyez votre message',
        success: 'Nous avons bien reçu votre message',
    }
}
