export const init = {
    ht: {
		title: "Mo Ayisyen",
		meta:{
			title: `#var_1#, Definisyon, egzanp & pwononsyasyon`,
			desc: `Definisyon de#var_1#ak egzanp, sinonim ak pwononsyasyon. Tout enfòmasyon ki nesesè pou w byen konprann ak apresye mo#var_2#ki soti nan diksyonè ayisyen an sou entènèt. Aprann plis`,
		},
		word: {
			usage: "Frekans itilizasyon",
			usageLow: "Ra",
			usageHigh: "Souvan",
			soundLike: "Pwononse tankou",
			notFound: "Pa gen rezilta pou mò sa",
			similar: "Nou gen mò sa yo ki gen prèske menm òtograf / pwononsyasyon ak mò yap chèche a",
			add: "Ajoute mo sa",
			noSyllable: "Pa gen silab",
			more: "Lòt definisyon pou",
		},
		edit: {
			label: "Edite",
			confirm: "Konfime",
			close: "Fèmen",
			save:  "Sove",
			email:  "Imèl",
			success: 'Nou byen resevwa enfòmasyon ou yo, mèsi pou kontribisyon ou an.',
		},
		word_elem: {
			word: "Mo",
			definition: "Definisyon",
			synonym: "Sinonim",
			example: "Egzanp",
			expression: "Ekspresyon",
			syllable: "Silab",
		},
		word_type: {
			adjective:{ nm: "adjektif", sh: "" },
			adverb:{ nm: "advèb", sh: "" },
			antiquated:{ nm: "demode", sh: "" },
			attributive:{ nm: "atribitif", sh: "" },
			compare:{ nm: "konpare", sh: "" },
			conjunction:{ nm: "konjonksyon", sh: "" },
			definite_article:{ nm: "atik definitif", sh: "" },
			euphemism:{ nm: "efemism", sh: "" },
			familiar:{ nm: "abitye", sh: "" },
			feminine:{ nm: "feminen", sh: "" },
			figurative:{ nm: "figire", sh: "" },
			interjection:{ nm: "entèjeksyon", sh: "" },
			literally:{ nm: "literalman", sh: "" },
			masculine:{ nm: "maskilen", sh: "" },
			military:{ nm: "militè", sh: "" },
			noun:{ nm: "non", sh: "" },
			number:{ nm: "nimewo", sh: "" },
			onomatopoeia:{ nm: "onomatopei", sh: "" },
			pejorative:{ nm: "peyoratif", sh: "" },
			plural:{ nm: "pliryèl", sh: "" },
			preposition:{ nm: "prepozisyon", sh: "" },
			see:{ nm: "wè", sh: "" },
			singular:{ nm: "sengilye", sh: "" },
			synonym:{ nm: "sinonim", sh: "" },
			verb:{ nm: "vèb", sh: "" },
			abbreviation:{ nm: "abreviyasyon", sh: "" },
			acronym:{ nm: "akwonim", sh: "" },
			future:{ nm: "lavni", sh: "" },
			prefix:{ nm: "prefiks", sh: "" },
			reflexive_verb:{ nm: "vèb reflexif", sh: "" },
			suffix:{ nm: "sifiks", sh: "" },
			vulgar:{ nm: "vilgè", sh: "" },
			pronoun:{ nm: "pwonon", sh: "" },
			proper_noun:{ nm: "non pwòp", sh: "" },
			usually:{ nm: "anjeneral", sh: "" },
			variety_variant:{ nm: "varyete variant", sh: "" },
			medicine_medicinal:{ nm: "remèd medsin", sh: "" },
			especially:{ nm: "espesyalman", sh: "" },
			short_form_of:{ nm: "fòm kout", sh: "" },
			contraction:{ nm: "kontraksyon", sh: "" },
			negative:{ nm: "negatif", sh: "" },
			auxiliary_verb:{ nm: "vèb oksilyè", sh: "" }
		},
    },
    en: {
		title: "Haitian word",
		meta:{
			title: `#var_1#Definition, example & pronounciation`,
			desc: `Definition of#var_1#with example, synonym and pronounciation. All the information needed to fully comprehend and appreciate the word#var_2#from the online Haitian dictionary. Learn more`,
		},
		word: {
			usage: "Frekans itilizasyon",
			usageLow: "Ra",
			usageHigh: "Souvan",
			soundLike: "Pwononse tankou",
			notFound: "No results found for",
			similar: "We have these words with similar spellings or pronunciations",
			add: "Add this word",
			noSyllable: "No syllable",
			more: "Other definitions for",
		},
		edit: {
			label: "edit",
			confirm: "Confirm",
			close: "Close",
			save:  "Save",
			email:  "Email",
			success: 'Your infomation was successfully submitted, thank you for your contribution.',
		},
		word_elem: {
			word: "Word",
			definition: "Definition",
			synonym: "Synonym",
			example: "Example",
			expression: "Expression",
			syllable: "Syllable",
		},
        word_type: {
			adjective:{ nm: "adjective", sh: "" },
			adverb:{ nm: "adverb", sh: "" },
			antiquated:{ nm: "antiquated", sh: "" },
			attributive:{ nm: "attributive", sh: "" },
			compare:{ nm: "compare", sh: "" },
			conjunction:{ nm: "conjunction", sh: "" },
			definite_article:{ nm: "definite article", sh: "" },
			euphemism:{ nm: "euphemism", sh: "" },
			familiar:{ nm: "familiar", sh: "" },
			feminine:{ nm: "feminine", sh: "" },
			figurative:{ nm: "figurative", sh: "" },
			interjection:{ nm: "interjection", sh: "" },
			literally:{ nm: "literally", sh: "" },
			masculine:{ nm: "masculine", sh: "" },
			military:{ nm: "military", sh: "" },
			noun:{ nm: "noun", sh: "" },
			number:{ nm: "number", sh: "" },
			onomatopoeia:{ nm: "onomatopoeia", sh: "" },
			pejorative:{ nm: "pejorative", sh: "" },
			plural:{ nm: "plural", sh: "" },
			preposition:{ nm: "preposition", sh: "" },
			see:{ nm: "see", sh: "" },
			singular:{ nm: "singular", sh: "" },
			synonym:{ nm: "synonym", sh: "" },
			verb:{ nm: "verb", sh: "" },
			abbreviation:{ nm: "abbreviation", sh: "" },
			acronym:{ nm: "acronym", sh: "" },
			future:{ nm: "future", sh: "" },
			prefix:{ nm: "prefix", sh: "" },
			reflexive_verb:{ nm: "reflexive verb", sh: "" },
			suffix:{ nm: "suffix", sh: "" },
			vulgar:{ nm: "vulgar", sh: "" },
			pronoun:{ nm: "pronoun", sh: "" },
			proper_noun:{ nm: "proper noun", sh: "" },
			usually:{ nm: "usually", sh: "" },
			variety_variant:{ nm: "variety variant", sh: "" },
			medicine_medicinal:{ nm: "medicine medicinal", sh: "" },
			especially:{ nm: "especially", sh: "" },
			short_form_of:{ nm: "short form of", sh: "" },
			contraction:{ nm: "contraction", sh: "" },
			negative:{ nm: "negative", sh: "" },
			auxiliary_verb:{ nm: "auxiliary verb", sh: "" },
		},
    },
    fr: {
		title: "Mot Haitien",
		meta:{
			title: `#var_1#, Définition, exemple et prononciation`,
			desc: `Définition de#var_1#avec exemple, synonyme et prononciation. Toutes les informations nécessaires pour bien comprendre et apprécier le mot#var_2#du dictionnaire haïtien en ligne. Apprendre encore plus`,
		},
		word: {
			usage: "Use Frequency",
			usageLow: "Rare",
			usageHigh: "Often",
			soundLike: "Sounds like",
			notFound: "Aucun résultat trouvé pour",
			similar: "Nous avons ces mots avec des orthographes ou des prononciations similaires",
			add: "Ajouter ce mot",
			noSyllable: "Aucune syllabe",
			more: "D'autres définitions pour",
		},
		edit: {
			label: "Éditer",
			confirm: "Confirmer",
			close: "Fermer",
			save:  "Sauvegarder",
			email:  "E-mail",
			success: 'Vos informations ont étées soumises avec succès, merci de votre contribution.',
		},
		word_elem: {
			word: "Mot",
			definition: "Définition",
			synonym: "Synonyme",
			example: "Exemple",
			expression: "Expression",
			syllable: "Syllabe",
		},
        word_type: {
			adjective:{ nm: "adjectif", sh: "" },
			adverb:{ nm: "adverbe", sh: "" },
			antiquated:{ nm: "vieilli", sh: "" },
			attributive:{ nm: "attributif", sh: "" },
			compare:{ nm: "comparer", sh: "" },
			conjunction:{ nm: "conjonction", sh: "" },
			definite_article:{ nm: "article défini", sh: "" },
			euphemism:{ nm: "euphémisme", sh: "" },
			familiar:{ nm: "familier", sh: "" },
			feminine:{ nm: "féminin", sh: "" },
			figurative:{ nm: "figuratif", sh: "" },
			interjection:{ nm: "interjection", sh: "" },
			literally:{ nm: "au sens propre", sh: "" },
			masculine:{ nm: "masculin", sh: "" },
			military:{ nm: "militaire", sh: "" },
			noun:{ nm: "nom", sh: "" },
			number:{ nm: "Numéro", sh: "" },
			onomatopoeia:{ nm: "onomatopée", sh: "" },
			pejorative:{ nm: "péjoratif", sh: "" },
			plural:{ nm: "pluriel", sh: "" },
			preposition:{ nm: "préposition", sh: "" },
			see:{ nm: "voir", sh: "" },
			singular:{ nm: "singulier", sh: "" },
			synonym:{ nm: "synonyme", sh: "" },
			verb:{ nm: "verbe", sh: "" },
			abbreviation:{ nm: "abréviation", sh: "" },
			acronym:{ nm: "acronyme", sh: "" },
			future:{ nm: "avenir", sh: "" },
			prefix:{ nm: "préfixe", sh: "" },
			reflexive_verb:{ nm: "verbe réfléchi", sh: "" },
			suffix:{ nm: "suffixe", sh: "" },
			vulgar:{ nm: "vulgaire", sh: "" },
			pronoun:{ nm: "pronom", sh: "" },
			proper_noun:{ nm: "nom propre", sh: "" },
			usually:{ nm: "d'habitude", sh: "" },
			variety_variant:{ nm: "variété; une variante", sh: "" },
			medicine_medicinal:{ nm: "Médicament; médicinal", sh: "" },
			especially:{ nm: "surtout", sh: "" },
			short_form_of:{ nm: "forme abrégée de", sh: "" },
			contraction:{ nm: "contraction", sh: "" },
			negative:{ nm: "négatif", sh: "" },
			auxiliary_verb:{ nm: "verbe auxiliaire", sh: "" }
		},
    }
}
