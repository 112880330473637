import React, { Component } from 'react';
import axios from "axios";
import './Page.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../utils/general/Env';
import { withParamsRouter } from '../../utils/general/Url';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { GetStateUrlLocale, GetStateLocale } from '../../utils/general/General';
import { SetSeo, MetaDesc, Title } from '../../utils/general/Seo.js';
import { Trtor } from '../../utils/general/Translator';
import { ObjHasKey } from '../../utils/general/Json.js';
import { PAGE, PAGE_URL } from '../../file/Constant.js';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import book from '../../assets/images/book.png';
class Page extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			page: "loading",
			txt: {},
			htm: {},
			menu: " dispnone",
        }
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.location.pathname !== this.props.location.pathname){
			this.pageName();
		}
	}
	componentDidMount(){		
		uptBodyHeight();
		this.pageName();
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { 
				act: 1, 
				p:  this.state.page, 
				lg: GetStateLocale() 
			};
			axios.get(`${ApiUrl()}/page.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					Title(res.data.msg.title);
					MetaDesc(res.data.msg.title);
					this.setState({txt:   res.data.msg});
					this.setState({htm:   res.data.htm});
					this.setState({loading: false});
				}else{
					this.setState({loading: false});
				}			
			});
		});
	}
    pageName(){
		let path = "";
		let tab  = window.location.pathname.split('/');
		if(tab.length > 0){
			path = tab.length > 2 ? tab[2] : tab[1];
			if(PAGE.includes(path)){
				this.setState({page: path}, () => {
					this.browse();
				});
			}
		}
		if(!PAGE.includes(path)){
			this.setState({loading: false}, () => {
				this.setState({page: "404"});
				SetSeo('404', {tt: 'title', dsc: 'meta.desc'}, []);
			});
		}
	}
    menu(){
        this.setState({menu: this.state.menu === " dispnone" ? "" : " dispnone"});
    }
    goTo(url){
        this.props.history(`${GetStateUrlLocale()}${url}`);
    }
	render(){
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="pag-bd" id="js-exp-bd">
					<div className="pag-mid">
						{this.state.loading &&
							<div>
								<div style={{width:"300px"}}>
									 <h3>Loading...</h3>
								</div>
								<div className="mg-t30">
									<div className="src-ft-wait lazyload-wave" style={{padding: "0"}}>
										<div className="src-ft-wait-sm" style={{maxWidth:"300px", margin:"0"}}></div>
										<div className="src-ft-wait-sm" style={{margin:"20px 0 0 0"}}></div>
										<div className="src-ft-wait-sm" style={{margin:"20px 0 0 0"}}></div>
									</div>
								</div>
							</div>
						}
						{!this.state.loading && this.state.page !== "404" &&
							<div className="mg-t20 pd-b10">
								<div className="fltl mg-r10">
									<div className="pag-menu" onClick={() => this.menu()}>
										&#9776;
										<div className={`pag-menu-bx${this.state.menu}`}>
											{PAGE_URL.map((elm, index) => {
												return (
													<div key={`mn-${index}`}
														className="pag-menu-elm"
														onClick={() => this.goTo(elm.url)}>
														{Trtor("page", elm.key)}
													</div>
												);
											})}
										</div>
									</div>
								</div>
								<div className="fltl pd-t5">
									<h3>{this.state.txt.title}</h3>
								</div>
								<div className="clr"></div>
								<div className="mg-t10">
									<div dangerouslySetInnerHTML={{__html: this.state.htm}} />
								</div>
							</div>
						}
						{!this.state.loading && this.state.page === "404" &&
							<div style={{textAlign:"center"}}>
								<div className="pag-404-mid">
									<div className="pag-404">
										<div className="pag-404-nb" style={{textAlign:"left"}}>
											4
										</div>
										<div className="pag-404-icn">
											<img src={book} alt="book" className="imgmax"/>
										</div>
										<div className="pag-404-nb" style={{textAlign:"right"}}>
											4
										</div>
									</div>
								</div>
								<div className="pag-404-sub">
								{Trtor("404", "title")}.
								</div>
								<div className="pag-404-txt">
									{Trtor("404", "sub")}.
								</div>
								<div className="mg-t30">
									<button type="button" className="btn btn-dark" onClick={() => this.goTo('')}>
										{Trtor("404", "btn")}
									</button>
								</div>
							</div>
						}
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
export default withParamsRouter(Page);