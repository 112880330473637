import MONTH from '../../file/Constant';
import { GetStateLocale } from './General';
import { capitalizeFirst } from './String';
import { Trtor } from './Translator';
export const FormatDate = (str) => {
	let tab = str.split("-");
	let nb  = parseInt(tab[1]);
	tab[1]  = capitalizeFirst(Trtor('general', `month.${nb}.m`)); 
	let format = `${tab[2]} ${tab[1]}. ${tab[0]}`;
	if(GetStateLocale() === 'en'){
		format = `${tab[1]}. ${tab[2]}, ${tab[0]}`;
	}
	return format;
}
export const AddSeparatorDate = (strDate, sep) => {
	let tab = strDate.split(" ");
	return `${tab[0]}${sep}${tab[1]}${sep}${tab[2]}`;
}
export const GetMonthLetter = (str) => {
	let tab = str.split("-");
	let nb  = parseInt(tab[1]);
	return MONTH[nb];
}
export default AddSeparatorDate;