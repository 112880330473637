import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import './Game.css';
//-----------------------------------   utils   -----------------------------------
import { Env } from '../../utils/general/Env';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { withRouter } from '../../utils/general/Url';
import { ObjHasKey } from '../../utils/general/Json.js';
import { GetStateUrlLocale } from '../../utils/general/General';
import { SetSeo } from '../../utils/general/Seo.js';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import hangmanGame from '../../assets/images/hangman-game.png';
import puzzle from '../../assets/images/puzzle.png';
import wordle from '../../assets/images/wordle.png';

class Game extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
        }
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { act: 1 }
			let url = Env.baseURL+'/alpha.php';
			url = 'https://diksyone.net/api/game.php';
			axios.get(`${url}`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({loading: false});
				}				
			});
		});
	}
	componentDidMount(){
		SetSeo('games', {tt: 'game.title', dsc: 'meta.desc'}, []);
		uptBodyHeight();
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
	}
	render(){
		return (
			<div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="gm-bd" id="js-exp-bd">
					<div className="gm-mid">
						<h2 className={"gm-tit-h2"}>{Trtor('games', 'game.title')}&nbsp;</h2>
						<div className="mg-t10" style={{fontSize: "1rem"}}>
							{Trtor('games', 'game.sub')}. {Trtor('games', 'game.suba')}!
						</div>
						<div className="gm-all">
							<div className="gm-card" onClick={() => this.goTo(`/game/the-hanged-man`)}>
								<div className="gm-card-hd gm-thm">
									<img src={hangmanGame} alt="Hangman game" style={{width:"100px"}}/>
								</div>
								<div className="gm-card-bd">
									<Link to={`${GetStateUrlLocale()}/game/the-hanged-man`} className="lk-inh">
										{Trtor('games', 'hangedMan.title')}
									</Link>
								</div>
							</div>
							<div className="gm-card" onClick={() => this.goTo(`/game/word-puzzle`)}>
								<div className="gm-card-hd gm-thm" style={{background:"#0039A7"}}>
									<img src={puzzle} alt="Word puzzle" style={{width:"100px"}}/>
								</div>
								<div className="gm-card-bd">
									<Link to={`${GetStateUrlLocale()}/game/word-puzzle`} className="lk-inh">
										{Trtor('games', 'wordPuzzle.title')}
									</Link>
								</div>
							</div>
							<div className="gm-card" onClick={() => this.goTo(`/game/worldle-solver`)}>
								<div className="gm-card-hd gm-thm" style={{background:"#00AE66"}}>
									<img src={wordle} alt="wordle" style={{width:"100px"}}/>
								</div>
								<div className="gm-card-bd">
									<Link to={`${GetStateUrlLocale()}/game/worldle-solver`} className="lk-inh">
										{Trtor('games', 'wordle.title')}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
export default withRouter(Game);