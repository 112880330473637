export const init = {
    ht: {
		meta:{
			desc: `Yon lis konplè ekspresyon ak idiom ki pi komen nan lang kreyòl Ayisyen an pou itilize nan nenpòt konvèsasyon oswa sitiyasyon ou jwenn nan! Eksplore ekspresyon pa sijè, gwo lis sijè sa a gen yon varyete ekspresyon pi popilè ki gen rapò ak divès sijè`,
		},
        expression: {
			meta:{
				title: `Ekspresyon #var_1#`,
				desc: `#var_1#, Definisyon: #var_2#`,
			},
            title: 	 'Ekspresyon',
            browse:  'rezilta',
            find: 	 'Jwenn yon ekspresyon',
            search:  'Rechèch',
            select:  'Chwazi yon tèm',
            theme: 	 'Tèm',
            latest: 'Dènye idiom',
            all: 	 'Tout Ekspresyon',
            definition: 'Definisyon',
            example:  'Egzanp',
            notFound: 'Nou pa jwenn anyen',
        },
    },
    en: {
		meta:{
			desc: `A complete list of the most common expressions and idioms in the Haitian Creole language to use in any given conversation or situation you find yourself in! Explore expressions by topics, this huge list of topics contains a variety of famous expressions related to various topics`,
		},
        expression: {
			meta:{
				title: `Expression #var_1#`,
				desc: `#var_1#, Definition: #var_2#`,
			},
            title: 	 'Expression',
            browse:  'results',
            find: 	 'Find an expression',
            search:  'Search',
            select:  'Select a theme',
            theme: 	 'Themes',
            latest: 'Latest expressions',
            all: 	 'All Expressions',
            definition: 'Definition',
            example:    'Example',
            notFound: 'We did not find anything',
        },
    },
    fr: {
		meta:{
			desc: `Une liste complète des expressions et des idiomes les plus courants dans la langue créole Haïtienne à utiliser dans n'importe quelle conversation ou situation dans laquelle vous vous trouvez ! Explorez les expressions par sujets, cette énorme liste de sujets contient une variété d'expressions célèbres liées à divers sujets`,
		},
        expression: {
			meta:{
				title: `Expression #var_1#`,
				desc: `#var_1#, Définition: #var_2#`,
			},
            title: 	 'Expression',
            browse:  'résultats',
            find: 	 'Trouver une expression',
            search:  'Chercher',
            select:  'Sélectionnez un thème',
            theme: 	 'Thèmes',
            latest: 'Nouvelles expressions',
            all: 	 'Toutes les expressions',
            definition: 'Définition',
            example:    'Exemple',
            notFound: 'Nous n\'avons rien trouvé',
        },
    }
}