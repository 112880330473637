export const init = {
    ht: {
        alpha: {
            title: 'Done diksyonè a',
            sub: 'Mo ki komanse ak#var_1#',
            result: 'resilta',
        },
		meta:{
			desc: `Definisyon ak siyifikasyon mo ki kòmanse ak lèt#var_1#sou Diksyone.net, pi gwo diksyonè kreyòl sou entènèt nan mond lan`,
		}
    },
    en: {
        alpha: {
            title: 'Browse the Dictionary',
            sub: 'Words that start with #var_1#',
            result: 'results',
        },
		meta:{
			desc: 'Definitions & meanings of words beginning with the letter"#var_1#"on Diksyone.net, the world\'s leading online creole dictionary',
		}
    },
    fr: {
        alpha: {
            title: 'Les données du Dictionnaire',
            sub: 'Mots commençant par #var_1#',
            result: 'résultats',
        },
		meta:{
			desc: 'Définitions et significations des mots commençant par la lettre"#var_1#"sur Diksyone.net, le premier dictionnaire créole en ligne au monde',
		}
    }
}
