import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import '../Expression.css';
import './ExpressionView.css';
//-----------------------------------   utils   -----------------------------------
import store from '../../../store/store.js';
import { ApiUrl} from '../../../utils/general/Env.js';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { uptBodyHeight } from '../../../utils/html/Html.js';
import { GetStateUrlLocale, GetStateLocale } from '../../../utils/general/General';
import { SetSeo } from '../../../utils/general/Seo.js';
import { withParamsRouter, getParams } from '../../../utils/general/Url';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../../body/header/Header';
import SelectAutoComplete from '../../form/select-auto-complete/SelectAutoComplete';
import InputAutoComplete from '../../form/input-auto-complete/InputAutoComplete';
import Footer from '../../body/footer/Footer';
//----------------------------------- Other -----------------------------------
const basicUrl = 'expression';
class ExpressionView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			expr: '',
			title: '',
			mean: [],
			exp: [],
			cat: [],
			badge: [],
			lastest: [],
			screen: false
        }
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.location.pathname !== this.props.location.pathname){
			uptBodyHeight();
			this.browse();
		}
	}
	componentDidMount(){	
		SetSeo('expression', {tt: 'expression.title', dsc: 'meta.desc'}, []);
		uptBodyHeight();
		this.browse();
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
	}
	browse(){
		const title = getParams(this.props, "title", "");
		this.setState({loading: true}, () => {
			const data = { 
				act: 3, 
				u:   title,
				lg:  GetStateLocale()
			};
			axios.get(`${ApiUrl()}/${basicUrl}.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({title: res.data.msg.l}, () => {
						let d = res.data.msg.d[0].l;
						SetSeo('expression', 
							{tt: 'expression.meta.title', dsc: 'expression.meta.desc'},
							[`var_1#${res.data.msg.l}`, `var_2#${d}`]
						);
						this.setState({loading: false});
						this.setState({cat:  res.data.msg.c});
						this.setState({mean: res.data.msg.d});
						this.setState({exp:  res.data.msg.e});
						if(this.state.badge.length === 0){
							this.cat();
						}
					});
				}else{
					this.setState({list:[]}, () => {
						this.setState({loading: false});
					});
				}
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			});
		});
	}
	cat(){
		const data = { 
			act: 2,
			lg:  GetStateLocale()
		};
		axios.get(`${ApiUrl()}/${basicUrl}.php`, {params: data}).then(res => {
			if(ObjHasKey(res, ["data","msg"])){
				this.setState({badge: res.data.msg});
				this.setState({lastest: res.data.htm});
			}
		});
	}
	goPrev(){
		let prev = store.getState();
		let prv  = `/expression`;
		if(ObjHasKey(prev, ["prv"])){
			let get  = ``;
			prev = store.getState().prv
			if(ObjHasKey(prev, ["b"])){
				get = prev.b ? `?b=${prev.b}` : get;
			}if(ObjHasKey(prev, ["q"])){
				get = prev.q ? `?q=${prev.q}` : get;
			}if(ObjHasKey(prev, ["p"])){
				prv += prev.q ? `/${prev.p}` : ``;
			}
			prv += `${get}`;
		}
		this.props.history(`${GetStateUrlLocale()}${prv}`);
	}	
	slideMenu(bool){
		this.setState({screen: bool}, () => {
			const elm = document.getElementById('bd-main');
			if(bool){ elm.classList.add("no-ovrflw"); }
			else{ elm.classList.remove("no-ovrflw"); }			
		});
	}
	slideMenuUrl(bool, url){
		this.setState({screen: bool}, () => {
			const elm = document.getElementById('bd-main');
			if(bool){ elm.classList.add("no-ovrflw"); }
			else{ elm.classList.remove("no-ovrflw"); }	
			this.goTo(url);			
		});		
	}
	render(){
		return (
            <div className="posrel" id="js-fll-bd">
				<div className={this.state.screen ? "bd-screen" : "bd-screen dispnone"} id="bd-scrn"></div>
                <Header showShadow={false} />
                <div className="exprv-bd" id="js-exp-bd">
                    <div className="vc-mid posrel">
						<div className="pd-b5 fntz14">
							<span className="expv-link" onClick={() => this.goPrev()}>
								&#10096; {Trtor('expression', 'expression.all')}
							</span>
                        </div>
						{this.state.loading &&
							<div>
								<div style={{width:"300px"}}>
									 <h3>Loading...</h3>
								</div>
								<div className="exprv-cnt mg-t15">
									<div className="src-ft-wait lazyload-wave" style={{padding: "0"}}>
										<div className="src-ft-wait-sm" style={{maxWidth:"300px", margin:"0"}}></div>
										<div className="src-ft-wait-sm" style={{margin:"20px 0 0 0"}}></div>
										<div className="src-ft-wait-sm" style={{margin:"20px 0 0 0"}}></div>
									</div>
								</div>
								<div className="exprv-cnt mg-t20">
									<div className="src-ft-wait lazyload-wave" style={{padding: "0"}}>
										<div className="src-ft-wait-sm" style={{maxWidth:"300px", margin:"0"}}></div>
										<div className="src-ft-wait-sm" style={{margin:"20px 0 0 0"}}></div>
										<div className="src-ft-wait-sm" style={{margin:"20px 0 0 0"}}></div>
									</div>
								</div>
							</div>
						}
						{!this.state.loading &&
						<div>
							<h3>{this.state.title}</h3>
							<div className="exp-bx">						
								<div>
									<div className="exprv-cnt">
										<div className="exprv-tit">
											{Trtor('expression', 'expression.definition')}
										</div>
										{this.state.mean.map((elm, key) => {
											return (
												<div className="exprv-dsc pd-l10" key={`mean-${key}`}>
													{elm.l}
												</div>
												)
											})
										}
										<div className="expr-bdg-bx">
										{this.state.cat.map((el, key) => {
											return (<span className="exp-badge" key={`bdg-${key}`}>{el.l}</span> )
											})
										}
										</div>
									</div>
									<div className="exprv-cnt mg-t30">
										<div className="exprv-tit">
											{Trtor('expression', 'expression.example')}
										</div>
										<div className="exprv-dsc">
											<ul style={{margin:"0", paddingLeft: "1.5rem"}}>
												{this.state.exp.map((elm, key) => {
													return (
														<li 															
															style={{padding:"2.5px 0"}} 
															key={`cat-${key}`}>
																<span className={"expr-li"}>{elm.l}</span>
															</li>
														)
													})										
												}
											</ul>										
										</div>
									</div>
								</div>
								<div className="exp-side">
									<div className="exp-thm">
										<div className="pd-b5">
											{Trtor('expression', 'expression.find')}
										</div>
										<InputAutoComplete 
											label={Trtor('expression', 'expression.search')} 
											url={`expression`}
											val={""}
											max={25}
										/>
									</div>
									<div className="exp-thm mg-t20">
										<div className="pd-b5">
											{Trtor('expression', 'expression.select')}:
										</div>
										<SelectAutoComplete 
											label={Trtor('expression', 'expression.theme')}
											arr={[]} 
											obj={this.state.badge} 
											url={`expression`} 
										/>
									</div>
									<div className="exp-rgt-bx">
										<div className="exp-rgt-tit">
											{Trtor('expression', 'expression.latest')}
										</div>
										<div className="exp-rgt-bd">
											<ul className={"exp-new-li"} style={{margin:"0"}}>
												{this.state.lastest.map((elm, key) => {
													return (
														<li 															
															style={{padding:"2.5px 0"}} 
															key={`cat-${key}`} 
															onClick={() => this.goTo(`${elm.u}`) }>
																<span className={"exp-new-elm"}>
																	<Link to={`${GetStateUrlLocale()}${elm.u}`} className="lk-inh">
																	{elm.l}
																	</Link>
																</span>
															</li>
														)
													})										
												}
											</ul>										
										</div>
									</div>
								</div>
							</div>
							<div className={this.state.screen || this.state.loading ? "exp-bx-mn dispnone mg-t30" : "exp-bx-mn mg-t30"} 
								onClick={() => this.slideMenu(true)}>
								<div className={"exp-mn-bar"} style={{marginTop:"4px"}}></div>
								<div className={"exp-mn-bar"}></div>
								<div className={"exp-mn-bar"}></div>
							</div>
						</div>
						}
                    </div>
                </div>
				<div className={this.state.screen ? "exp-full-mn exp-full-mn-on" : "exp-full-mn" }>					
					<div className="posrel">
						<div className="exp-full-mn-xx" onClick={() => this.slideMenu(false)}>&#10006;</div>						
					</div>
					<div className="exp-thm mg-t20">
						<div className="pd-b5">
							{Trtor('expression', 'expression.find')}
						</div>
						<InputAutoComplete 
							label={Trtor('expression', 'expression.search')}
							url={`expression`}
							val={""}
							close={() => this.slideMenu(false)}
							max={25}
						/>
					</div>
					<div className="exp-thm mg-t20">
						<div className="pd-b5">
							{Trtor('expression', 'expression.select')}:
						</div>
						<SelectAutoComplete 
							label={Trtor('expression', 'expression.theme')}
							arr={[]} 
							obj={this.state.badge} 
							url={`expression`} 
							close={() => this.slideMenu(false)}
						/>
					</div>
					<div className="exp-rgt-bx">
						<div className="exp-rgt-tit">
							{Trtor('expression', 'expression.latest')}
						</div>
						<div className="exp-rgt-bd">
							<ul className={"exp-new-li"} style={{margin:"0"}}>
								{this.state.lastest.map((elm, key) => {
									return (
										<li 															
											style={{padding:"2.5px 0"}} 
											key={`catb-${key}`} 
											onClick={() => this.slideMenuUrl(false, `${elm.u}`) }>
												<span className={"exp-new-elm"}>
													{elm.l}
												</span>
											</li>
										)
									})										
								}
							</ul>										
						</div>
					</div>
				</div>
                <Footer />
            </div>
		);
	}
}
export default withParamsRouter(ExpressionView);