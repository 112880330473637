import React, { Component } from 'react';
import axios from "axios";
import './AutoComplete.css';
//-----------------------------------   utils   -----------------------------------
import store from '../../../store/store';
import { ApiUrl } from '../../../utils/general/Env';
import { Trtor } from '../../../utils/general/Translator';
import { withRouter } from '../../../utils/general/Url';
import { GetStateUrlLocale } from '../../../utils/general/General';
//----------------------------------- Component -----------------------------------

let cancelTokenSource = null;
let timeoutTyping = null;
class AutoComplete extends Component {
	constructor(props) {        
		super(props);
		this.state = {
			query: '',
			showAutoComplete: false,
			placeholder: this.props.placeholder,
			loading: true,
			result: [],
        }    
        this.setQuery 	  = this.setQuery.bind(this);
        this.searchResult = this.searchResult.bind(this);
		this.goTo   	  = this.goTo.bind(this);
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.placeholder !== this.props.placeholder){
			this.setState({placeholder: this.props.placeholder});
		}
	}
	componentDidMount(){ 
        document.addEventListener('mousedown', this.handleClickOutside);
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    setQuery(e){
        this.setState({query: e.target.value}, () => {
			if(this.props.hasOwnProperty("setQuery")){
				this.props.setQuery(e.target.value);
			}
            this.setAutoComplete();
        })               
    }
    setAutoComplete(){
        if(this.state.query.length > 1){
            if(!this.state.showAutoComplete){
                this.setState({showAutoComplete: true}, () => {                
                    this.setState({loading: true}, () =>{
                        this.searchWord() 
                    })
                })
            }else{
                this.setState({loading: true}, () =>{
                    this.searchWord() 
                })
            }
        }
        else if(!this.state.query.length){
            this.setState({showAutoComplete: false})
        }
    }
    searchWord(){
        clearTimeout(timeoutTyping);
        timeoutTyping = setTimeout(() => {
            if(cancelTokenSource){ cancelTokenSource.cancel(); }
            cancelTokenSource = axios.CancelToken.source();
            const data = { act: 1, w: this.state.query, s: this.props.selOpt }
            axios.get(`${ApiUrl()}/search.php`, {params: data}).then(res => {
                this.setState({result: res.data.msg}, () => {
                    this.setState({loading: false})
                });
            });
        }, 500);
    }
    handleClickOutside = (event) => {     
        if(this.state.showAutoComplete){
            let allowedDiv = ["inp-src-cnt", "inp-src-css", "inp-src-hmcss", "inp-src-auto", "inp-src-auto-elm"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showAutoComplete: false})
                }
            }else{
                this.setState({showAutoComplete: false})
            }
        }
    }
    goTo(i, label, url){
		this.setState({query: ''}, () => {
			this.setState({showAutoComplete: false}, () => {
				store.dispatch({ type: 'SET_WRD', payload: {i:i, u:url, l:label} });
				this.props.history(`${GetStateUrlLocale()}${url}`);
			});
		});		
    }
    searchResult(){
        let res;
        if(!this.state.loading){
            if(this.state.result){
                res = this.state.result.map((elm, index) => {
                    return(
                        <div className="inp-src-auto-elm" onClick={() => this.goTo(elm.i, elm.l, elm.u)} key={`auto-${index}`}>
							{elm.l}
						</div>
                    )
                })
            }else{
                res = (
                    <div className="inp-src-auto-elm">{Trtor("autoComplete", "empty")}</div>
                )
            }
        }
        return res;
    }
    getFieldAutoComplete(clssInp, clssAuto){
        let display = this.state.showAutoComplete ? '' : " dispnone";
        let load = this.state.loading ? '' : " dispnone";
        return (
            <div className="inp-src-cnt">
                <input type="text" name="q" value={this.state.query} placeholder={this.state.placeholder} onChange={this.setQuery} className={clssInp} />
                <div className={`${clssAuto}${display}`}>
                    <div className={`src-ft-wait lazyload-wave${load}`}>
                        <div className="src-ft-wait-sm"></div>
                        <div className="src-ft-wait-sm"></div>
                        <div className="src-ft-wait-sm"></div>
                    </div>
                    {this.searchResult()}
                </div>
            </div>    
        )
    }
	render(){
        if(this.props.category === 'home'){
            return (this.getFieldAutoComplete("inp-src-hmcss", "inp-hmsrc-auto"));
        }else{
            return (this.getFieldAutoComplete("inp-src-css", "inp-src-auto"));
        }
    }
}
export default withRouter(AutoComplete);