import React, { Component } from 'react';
import axios from "axios";
import './Kreyol.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../utils/general/Env';
import store from '../../store/store';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { withParamsRouter, getParams } from '../../utils/general/Url';
import { Trtor } from '../../utils/general/Translator';
import { SetSeo } from '../../utils/general/Seo.js';
import { ObjHasKey } from '../../utils/general/Json.js';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
import KreyolLoading from './kreyol-loading/KreyolLoading';
import KreyolNotFound from './kreyol-not-found/KreyolNotFound';
import KreyolDefinition from './kreyol-definition/KreyolDefinition';
import KreyolBadge from './kreyol-badge/KreyolBadge';
import KreyolExample from './kreyol-example/KreyolExample';
import KreyolMore from './kreyol-more/KreyolMore';
import KreyolEdit from './kreyol-edit/KreyolEdit';
import KreyolSound from './kreyol-sound/KreyolSound';
//----------------------------------- Other -----------------------------------
import mute from '../../assets/images/mute.png';
import soundb from '../../assets/images/sound-b.png';
import soundr from '../../assets/images/sound-r.png';
class Kreyol extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: '',
			iwrd: 0,
			wrk: 0,
			loading: true,
			exist: true,
			dot: '...',
			label: '',
			sy: '',
			syr: '',
			def: 0,
			syn: 0,
			ex: 0,
			expr: 0,
			aud: 0,
			data: [],
			mic: "",
			playSound: false,
			modalShow: false
        }
	}
	browse(){
		this.dotAnimation();
		let wrd = store.getState().wrd;
		if(wrd.i !== this.state.iwrd || this.state.iwrd === 0){
			this.setState({iwrd: wrd.i}, () => { 
				const wd = getParams(this.props, "word", "");
				const data = { act: 1, w: wd, i: wrd.i }
				axios.get(`${ApiUrl()}/browse.php`, {params: data}).then(res => {
					if(res.data.cd === 0){
						this.setState({label: res.data.msg.wrd}, () => {
							this.setState({data: [res.data.htm, res.data.msg.ww]});
							this.setState({exist: false}, () => {
								this.setState({loading: false});
							});
						});
					}else{
						this.setState({result: res.data.msg}, () => {
							this.setTitleDesc(wd);
							this.setState({exist: true});
							this.setState({loading: false});
							this.setState({data: res.data.msg.wrd});
							this.setState({sy:res.data.msg.wrd[0].sy});
							this.setState({syr:res.data.msg.wrd[0].syr});
							this.setState({label: ''}, () => {
								this.process(res.data.msg.sta);
								this.view();
							});
						});
					}
				});
			});
		}
	}
	view(){
		if(ObjHasKey(this.state.data, [0, "i"])){
			const data = { act: 2, i: this.state.data[0].i }
			axios.get(`${ApiUrl()}/browse.php`, {params: data});
		}
	}
	process(stat){
		if(this.state.label === ''){
			this.setState({label: stat.lab});
			this.setState({def:   stat.def});
			this.setState({syn:   stat.syn});
			this.setState({ex:    stat.ex});
			this.setState({expr:  stat.expr});
			this.setState({aud:   stat.aud});
		}
	}
	dotAnimation(){
		setTimeout(() => {
			let dots = `${this.state.dot}.`;
			if(dots.length > 6){
				dots = '.';
			}
			this.setState({dot: dots}, () => {
				if(this.state.loading){
					this.dotAnimation();
				}
			});

		}, "1000");
	}
	toggleEdit(k){
		this.setState({wrk: k}, () => {
			this.setState({modalShow: !this.state.modalShow});
		});		
	}
	componentDidMount(){
		SetSeo('kreyol', {tt: 'title', dsc: 'title'}, []);
		if(this.state.query === ''){
			const wd = getParams(this.props, "word", "");
			let swd  = store.getState().wrd;
			if(ObjHasKey(swd, ["l"]) && wd !== swd.l){
				store.dispatch({ type: 'SET_WRD', payload: {i:0, u:"", l:""} });
			}
			this.setState({query: wd}, () => {
				this.setState({loading: true}, () => {
					this.browse();
				});
			});
		}	
		uptBodyHeight();
	}
	componentDidUpdate(prevProps, prevState){/*same url fix*/
		if(this.state.loading === false){			
			const wd = getParams(this.props, "word", "");
			if(this.state.query !== wd){
				this.setState({query: wd}, ()=> {
					this.setState({loading: true}, () => {
						this.browse();
					});
				});
			}else{/*same url fix + wrd with diff id*/
				let cmpwrd = store.getState().wrd;
				if(cmpwrd.i > 0 && cmpwrd.i !== this.state.iwrd){
					this.browse();
				}
			}
			if(!this.state.loading){
				uptBodyHeight();
			}		
		}
	}
	setTitleDesc(wrd){
		SetSeo('kreyol', {tt: 'meta.title', dsc: 'meta.desc'}, 
			[`var_1#${wrd}`, `var_2#${wrd}`]
		);
	}
	render(){
		if(!this.state.loading){
			if(this.state.exist){
				return (
					<div id="js-fll-bd">
						<Header showShadow={true} />
						{this.state.modalShow &&
							<KreyolEdit 
								show={this.state.modalShow} 
								toggleModal={() => this.toggleEdit()} 
								data={this.state.data} 
								wrk={this.state.wrk} 
							/>
						}
						<div className="df-hd">
							<div className="df-hd-mid">
								<div className="df-wrd">
									{this.state.label}
								</div>
								<div className="df-wrd-stat">
									<div className="df-wrd-stat-elm">
										<span className="df-wrd-stat-val">{this.state.def}</span>&nbsp;&nbsp;
										<span className="df-wrd-stat-lab">{Trtor('kreyol', 'word_elem.definition')}</span>
									</div>
									<div className="df-wrd-stat-sep">&bull;</div>
									<div className="df-wrd-stat-elm">
										<span className="df-wrd-stat-val">{this.state.syn}</span>&nbsp;&nbsp;
										<span className="df-wrd-stat-lab">{Trtor('kreyol', 'word_elem.synonym')}</span>
									</div>
									<div className="df-wrd-stat-sep df-wrd-stat-sepsec">&bull;</div>
									<div className="df-wrd-stat-elm">
										<span className="df-wrd-stat-val">{this.state.ex}</span>&nbsp;&nbsp;
										<span className="df-wrd-stat-lab">{Trtor('kreyol', 'word_elem.example')}</span>
									</div>
									<div className="df-wrd-stat-sep">&bull;</div>
									<div className="df-wrd-stat-elm">
										<span className="df-wrd-stat-val">{this.state.expr}</span>&nbsp;&nbsp;
										<span className="df-wrd-stat-lab">{Trtor('kreyol', 'word_elem.expression')}</span>
									</div>
								</div>
							</div>
						</div>
						<div className="df-bd" id="js-exp-bd">
							<div className="df-mid">
								<div className="df-bd-lft">
									<div className="df-wrd-pro-rsp">
										<div className="fltl">
											{this.state.sy.length > 0 &&
												<span className={'sylwrdall'} dangerouslySetInnerHTML={{__html: `&#91;${this.state.syr}&#93;&nbsp;`}} />
											}
											{this.state.sy.length < 1 &&
												<div className="df-pro-talk-nosyl">[{Trtor("kreyol", "word.noSyllable")}]</div>
											}
										</div>
										<div className="fltl">
											{this.state.sy.length > 0 &&
												<div className="pd-t0">
													<span className={`df-pro-talk-rsp${this.state.mic}`} onClick={() => { 
														if(!this.state.playSound){
															this.setState({playSound: true});
															this.setState({mic: " clr-redd70"});
														}
													}}>
														<img src={this.state.playSound ? soundr : soundb} alt="sound" width={"25px"}/>
													</span>
												</div>
											}
											{this.state.sy.length < 1 &&
												<span className={`df-pro-talk-rsp`} style={{cursor:"default",background:"transparent"}}>
													<img src={mute} alt="No sound" width={"25px"}/>
												</span>
											}
										</div>
										<div className="fltr dispnone">
											{Trtor('kreyol', 'word.soundLike')}
											&nbsp;&nbsp;
											<span className='df-pro-like' onClick={() => {return false}}>&#9660;</span>
										</div>
										<div className="clr"></div>
									</div>
									<KreyolDefinition 
										wrd={this.state.data} 
										nb={1} 
										index={0} 
										marg={''}
										toggleEdit={() => this.toggleEdit(0)} 
									/>
									<KreyolBadge   wrd={this.state.data} index={0} attr={"syn"}  label={Trtor('kreyol', 'word_elem.synonym')} />
									<KreyolExample wrd={this.state.data} index={0} attr={"ex"}   label={Trtor('kreyol', 'word_elem.example')} />
									<KreyolExample wrd={this.state.data} index={0} attr={"expr"} label={Trtor('kreyol', 'word_elem.expression')} />
									<KreyolMore    wrd={this.state.data} toggleEdit={(k) => this.toggleEdit(k)} />
								</div>
								<div className="df-bd-rgt">
									<div className="df-wrd-rgt">
										<KreyolSound 
											wrd={this.state.data} 
											syllable={this.state.sy} 
											playSound={this.state.playSound}
											stopSound={() => { 
												this.setState({playSound: false}); 
												this.setState({mic: ""}); 
											}}
										/>
										<div className="df-wrd-pro dispnone mg-t20">
											<div className="df-wrd-frq">
												<div className="df-frq-tit">
													{Trtor('kreyol', 'word.usage')}
												</div>
												<div className="df-frq-br">
													<div className="df-frq-bx">
														<div className="df-frq-fill"></div>
													</div>
													<div className="df-frq-bx"></div>
													<div className="df-frq-bx"></div>
													<div className="df-frq-bx"></div>
													<div className="df-frq-bx"></div>
												</div>
												<div className="df-frq-lgd">
													<div className="fltl">
														{Trtor('kreyol', 'word.usageLow')}
													</div>
													<div className="fltr">
														{Trtor('kreyol', 'word.usageHigh')}
													</div>
													<div className="clr"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="clr"></div>
						</div>
						<Footer />
					</div>
				);
			}else{
				return (
					<div id="js-fll-bd">
						<Header showShadow={true} />
						<KreyolNotFound other={this.state.data} label={this.state.label} />
						<Footer />
					</div>
				);
			}
		}else{
			return (
				<div id="js-fll-bd">
					<Header showShadow={true} />
					<KreyolLoading dot={this.state.dot} />
					<Footer />
				</div>
			)
		}
	}
}
export default withParamsRouter(Kreyol);