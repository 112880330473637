import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import './WordOfWeekBox.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl, BaseUrl } from '../../../utils/general/Env';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { FormatDate, AddSeparatorDate } from '../../../utils/general/Date.js';
import { GetStateUrlLocale } from '../../../utils/general/General';
import { PlaySound } from '../../../utils/sound/Sound.js';

//-----------------------------------  other   -----------------------------------
import mute from '../../../assets/images/mute.png';
import soundb from '../../../assets/images/sound-b.png';
import soundr from '../../../assets/images/sound-r.png';
let myInterval;
class WordOfWeekBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			play: false,
			showMute: false,
			wrd:  "",
			nosp: "",
			syl:  "",
			htm:  "",
			date: "",
			file: [],
        }
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { act: 2, p: 0 }
			axios.get(`${ApiUrl()}/kreyol.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setVal({
						l: res.data.msg.l,
						n: res.data.msg.n,
						s: res.data.msg.s,
						h: res.data.msg.sh,
						d: res.data.msg.fw,
						f: []
					});
					this.getSound(parseInt(res.data.msg.i));					
				}				
			});
		});
	}
	getSound(id){
		const data = { act: 1, i: id }
		axios.get(`${ApiUrl()}/sound.php`, {params: data}).then(res => {			
			if(Array.isArray(res.data.msg) && res.data.msg.length === 0){
				this.setState({showMute: true});
			}else{
				this.setState({file: res.data.msg});
			}
			this.setState({loading: false});
		});
	}
	componentDidMount(){
		if(ObjHasKey(this.props, ["data", "ww"])){
			this.setVal({
				l: this.props.data.ww.l,
				n: this.props.data.ww.n,
				s: this.props.data.ww.s,
				h: this.props.data.ww.h,
				d: this.props.data.ww.d,
				f: this.props.data.ww.f
			});
		}else{
			this.browse();
		}
	}
	setVal(obj){
		this.setState({wrd:  obj.l});
		this.setState({nosp: obj.n});
		this.setState({syl:  obj.s});
		this.setState({htm:  obj.h});
		this.setState({date: obj.d});
		this.setState({file: obj.f});		
	}
	playSound(){
		if(!this.state.play){
			this.setState({play: true}, () => {
				PlaySound(this.state.file, 0, BaseUrl(), "blk212", myInterval, () => {
					this.setState({play: false});
				});
			});
		}
	}
	render(){
		let brd = this.props.brd.length > 0 ? this.props.brd : "005CB9";
		return (
			<div className="wow-bx-bd" style={{marginTop:"18px", borderColor:`#${brd}`}}>
				{!this.state.loading &&
					<div>
						<h3 className="hm-ptd-wd">
							<Link to={`${GetStateUrlLocale()}/kreyol/${this.state.nosp}`} className="lk-blu004">
								{this.state.wrd}
							</Link>
						</h3>
						<div className="mg-t10">
							<h4 className="wow-syl">
								[<span className={'sylwrdall'} dangerouslySetInnerHTML={{__html: this.state.htm}} />]
							</h4>
						</div>
						<div className="mg-t5">
							<h3>
								{!this.state.showMute &&
									<span className={`wow-speaker`} onClick={() => this.playSound() }>
										<img src={this.state.play ? soundr : soundb} alt="sound" width={"25px"}/>
									</span>
								}
								{this.state.showMute &&
									<span className={`wow-mute`}>
										<img src={mute} alt="no sound" width={"25px"}/>
									</span>
								}
							</h3>
						</div>
						<div className="wow-date mg-t5">
							{AddSeparatorDate(FormatDate(this.state.date), " / ")}
						</div>
					</div>
				}
				{this.state.loading &&
					<div className={`src-ft-wait lazyload-wave`} style={{padding:'0 15px 0px 15px'}}>
						<div className="src-ft-wait-sm" style={{height: "20px", maxWidth:'130px', margin:'10px auto 0 auto'}}></div>
						<div className="src-ft-wait-sm" style={{height: "20px"}}></div>
						<div className="src-ft-wait-sm" style={{height: "20px"}}></div>
						<div className="src-ft-wait-sm" style={{height: "20px"}}></div>
					</div>
				}
			</div>
		);
	}
}
export default WordOfWeekBox;