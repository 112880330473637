import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import '../vocabulary/Vocabulary.css';
import './Expression.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../utils/general/Env.js';
import store from '../../store/store.js';
import { ObjHasKey } from '../../utils/general/Json.js';
import { GetStateUrlLocale, GetStateLocale } from '../../utils/general/General';
import { SetSeo } from '../../utils/general/Seo.js';
import { withParamsRouter, getParams, getter } from '../../utils/general/Url.js';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import SelectAutoComplete from '../form/select-auto-complete/SelectAutoComplete';
import InputAutoComplete from '../form/input-auto-complete/InputAutoComplete';
import Pager from '../pager/Pager';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import load from '../../assets/images/load-clr.gif';
const basicUrl = 'expression';
class Expression extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			sel: null,
			page: 1,
			mxp: 0,
			total: 0,
			list: [],
			cat: [],
			lastest: [],
			query: "",
			badge: "",
			screen: false
        }
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.location.pathname !== this.props.location.pathname || 
			prevProps.location.search !== this.props.location.search){
			let badge = getter(`b`);
			let query = getter(`q`);
			this.setState({badge: badge}, () => {
				this.setState({query: query}, () => {
					this.reset();
				});
			});
		}
	}
	componentDidMount(){
		SetSeo('expression', {tt: 'expression.title', dsc: 'meta.desc'}, []);
		this.setState({page: getParams(this.props, "page", 1)});
		uptBodyHeight();
		this.setParams();
		this.browse();
	}
	setParams(){
		let badge = getter(`b`);
		let query = getter(`q`);
		if(badge && badge !== this.state.badge){
			this.setState({badge: badge});
		}else if(query && query !== this.state.query){
			this.setState({query: query});
		}
	}
	reset(){
		this.setState({page: getParams(this.props, "page", 1)}, () => {
			this.setState({total: 0}, () => {
				this.browse();
				this.cat();
			});			
		});
	}
	browse(){
		const pg = getParams(this.props, "page", 1);
		this.setState({loading: true}, () => {
			const data = { 
				act: 1, 
				t:  this.state.total, 
				p:  pg,
				b:  this.state.badge,
				q:  this.state.query,
				lg: GetStateLocale()
			};
			axios.get(`${ApiUrl()}/${basicUrl}.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({list: res.data.msg}, () => {
						this.setState({loading: false});
						this.setState({total: res.data.htm.tt});
						this.setState({mxp: res.data.htm.mp});
						if(this.state.cat.length === 0){
							this.cat();
						}
					});
				}else{
					this.setState({list:[]}, () => {
						this.setState({loading: false});
					});
				}
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			});
		});
	}
	cat(){
		const data = { 
			act: 2,
			lg:  GetStateLocale()
		};
		axios.get(`${ApiUrl()}/${basicUrl}.php`, {params: data}).then(res => {
			if(ObjHasKey(res, ["data","msg"])){
				this.setState({cat: res.data.msg});
				this.setState({lastest: res.data.htm});
			}
		});
	}
    selector(ind){
        if(this.state.sel !== ind){
            this.setState({sel: ind})
        }else{            
            this.setState({sel: null})
        }
    }	
	handleChange(event){
		this.setState({srch: event.target.value});
	}	
	setUrl(url){
		const pg = getParams(this.props, "page", "");
		if(this.state.query){
			return `${url}?q=${this.state.query}`;
		}else if(this.state.badge){
			return `${url}?b=${this.state.badge}`;
		}
		return pg ? `${url}/${pg}` : url; 
	}
    goTo(url){
		const pg = getParams(this.props, "page", "");
		store.dispatch({ type: 'SET_PRV_PG', payload: {
			u:this.setUrl(`/${basicUrl}`), 
			q:this.state.query, 
			b:this.state.badge, 
			p:pg
			} 
		});
        this.props.history(`${GetStateUrlLocale()}${url}`);
    }
	slideMenu(bool){
		this.setState({screen: bool}, () => {
			const elm = document.getElementById('bd-main');
			if(bool){ elm.classList.add("no-ovrflw"); }
			else{ elm.classList.remove("no-ovrflw"); }			
		});
	}
	slideMenuUrl(bool, url){
		this.setState({screen: bool}, () => {
			const elm = document.getElementById('bd-main');
			if(bool){ elm.classList.add("no-ovrflw"); }
			else{ elm.classList.remove("no-ovrflw"); }	
			this.goTo(url);			
		});		
	}
	render(){
		return (
            <div className="posrel" id="js-fll-bd">
				<div className={this.state.screen ? "bd-screen" : "bd-screen dispnone"} id="bd-scrn"></div>
                <Header showShadow={false} />
                <div className="exp-bd" id="js-exp-bd">
                    <div className="exp-mid posrel">
						<div className={"fltl"}>
							<span className="exp-tit-h2">
								{Trtor('expression', 'expression.title')}
							</span>
						</div>
						<div className={"exp-tit-res"}>
							<>
								{this.state.total} {Trtor('expression', 'expression.browse')}
								{(this.state.badge || this.state.query) &&
									<>										
										&nbsp;{Trtor('general', 'for')} <strong>{this.state.badge} {this.state.query}</strong><span>&nbsp;</span>
										<span style={{fontSize:"0.95rem", cursor:"pointer"}} onClick={() => this.goTo(`/${basicUrl}`) }>
											(&#10540;)
										</span> 
									</>
								}
							</>
						</div>
						<div className={"clr"}></div>						
						{this.state.loading &&
							<div className="mg-t20 brw-wait">
								<img src={load} alt="loading..."/>
							</div>
						}
						{!this.state.loading &&
							<div className="exp-bx">							
								<div>
								{this.state.list.map((elm, key) => {
									let mg = key === 0 ? ` mg-t0` : ``;
									return (
										<div className={`exp-elm${mg}`} key={`exp-${key}`}>
											<div className="exp-tit">
												<span className="exp-tita">
													<Link to={`${GetStateUrlLocale()}${elm.u}`} className="lk-inh">
														{elm.l}
													</Link>
												</span>
											</div>
											<div className="exp-dsc">
												<div className="exp-dsc-lft">
													<span className="exp-mn">{Trtor('expression', 'expression.definition')}:</span>
													<div className="mg-t5">
														{elm.d}
													</div>
												</div>
												<div className="exp-dsc-rgt">
													<span className="exp-mn">{Trtor('expression', 'expression.example')}:</span>
													<div className="mg-t5">
														{elm.e}
													</div>
												</div>
												<div className="clr"></div>
											</div>
											<div className="exp-badge-bx">
											{elm.c.map((el, key) => {
												return (<span className="exp-badge" key={`bdg-${key}`}>{el}</span> )
												})
											}
											</div>
										</div>
									)
								})
								}
								{this.state.list.length === 0 &&
									<div className={"mg-t30 mg-b30"} style={{textAlign:"center", color:"#aeaeae"}}>
										<h3>{Trtor('expression', 'expression.notFound')}.</h3>
									</div>
								}
								{this.state.list.length > 0 &&
									<Pager 
										page={this.state.page}
										url={this.setUrl(`/${basicUrl}`)}
										total={this.state.mxp}
									/> 
								}
								</div>
								<div className="exp-side">
									<div className="exp-thm">
										<div className="pd-b5">{Trtor('expression', 'expression.find')}</div>
										<InputAutoComplete 
											label={Trtor('expression', 'expression.search')} 
											url={`expression`}
											val={this.state.query}
											max={25}
										/>
									</div>
									<div className="exp-thm mg-t20">
										<div className="pd-b5">{Trtor('expression', 'expression.select')}:</div>
										<SelectAutoComplete 
											label={Trtor('expression', 'expression.theme')} arr={[]} 
											obj={this.state.cat} 
											url={`expression`} 
										/>
									</div>
									<div className="exp-rgt-bx">
										<div className="exp-rgt-tit">{Trtor('expression', 'expression.latest')}</div>
										<div className="exp-rgt-bd">
											<ul className={"exp-new-li"} style={{margin:"0"}}>
												{this.state.lastest.map((elm, key) => {
													return (
														<li 															
															style={{padding:"2.5px 0"}} 
															key={`cat-${key}`} 
															onClick={() => this.goTo(`${elm.u}`) }>
																<span className={"exp-new-elm"}>{elm.l}</span>
															</li>
														)
													})										
												}
											</ul>										
										</div>
									</div>
								</div>
							</div>
						}
                    </div>
                </div>
				<div className={this.state.screen ? "exp-full-mn exp-full-mn-on" : "exp-full-mn" }>					
					<div className="posrel">
						<div className="exp-full-mn-xx" onClick={() => this.slideMenu(false)}>&#10006;</div>						
					</div>
					<div className="exp-thm mg-t20">
						<div className="pd-b5">
							{Trtor('expression', 'expression.find')}
						</div>
						<InputAutoComplete 
							label={Trtor('expression', 'expression.search')}
							url={`expression`}
							val={this.state.query}
							close={() => this.slideMenu(false)}
							max={25}
						/>
					</div>
					<div className="exp-thm mg-t20">
						<div className="pd-b5">{Trtor('expression', 'expression.select')}:</div>
						<SelectAutoComplete 
							label={Trtor('expression', 'expression.theme')} 
							arr={[]} 
							obj={this.state.cat} 
							url={`expression`}
							close={() => this.slideMenu(false)}
						/>
					</div>
					<div className="exp-rgt-bx">
						<div className="exp-rgt-tit">
							{Trtor('expression', 'expression.latest')}
						</div>
						<div className="exp-rgt-bd">
							<ul className={"exp-new-li"} style={{margin:"0"}}>
								{this.state.lastest.map((elm, key) => {
									return (
										<li 															
											style={{padding:"2.5px 0"}} 
											key={`cat-${key}`} 
											onClick={() => this.slideMenuUrl(false, `${elm.u}`) }>
												<span className={"exp-new-elm"}>{elm.l}</span>
											</li>
										)
									})										
								}
							</ul>										
						</div>
					</div>
				</div>									
				<div className={this.state.screen || this.state.loading ? "exp-bx-mn dispnone" : "exp-bx-mn"} 
					onClick={() => this.slideMenu(true)}>
					<div className={"exp-mn-bar"} style={{marginTop:"4px"}}></div>
					<div className={"exp-mn-bar"}></div>
					<div className={"exp-mn-bar"}></div>
				</div>
                <Footer />
            </div>
		);
	}
}
export default withParamsRouter(Expression);