import React, { Component } from 'react';
import axios from "axios";
import './Contact.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../utils/general/Env';
import { withRouter } from '../../utils/general/Url';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { GetStateUrlLocale } from '../../utils/general/General';
import { SetSeo } from '../../utils/general/Seo.js';
import { Trtor } from '../../utils/general/Translator';
import { ObjHasKey } from '../../utils/general/Json.js';
import { validateEmail } from '../../utils/general/String';
import { PAGE_URL } from '../../file/Constant.js';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import load from '../../assets/images/load-clr.gif';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			nm:  "",
			em:  "",
			txt: "",
			sent: false,
			error:{
				name: "",
				txt: "",
				email: ""
			},
			menu: " dispnone",
        }
	}
	error(){
		let err   = 0;
		let error = { ...this.state.error };
		if(!validateEmail(this.state.em)){
			error.email = ' brd-err';
			err++;
		}else{
			error.email = '';
		}if(this.state.nm.length < 3){
			error.name = ' brd-err';
			err++;
		}else{
			error.name = '';
		}if(this.state.txt.length < 5){
			error.txt = ' brd-err';
			err++;
		}else{
			error.txt = '';
		}
		this.setState({error: error});
		return err;
	}
	submit(){
		if(this.error() === 0){
			this.setState({loading: true}, () => {
				const data = { 
					act: 1, 
					nm:  this.state.nm, 
					em:  this.state.em, 
					txt: this.state.txt,
				};
				axios.post(`${ApiUrl()}/contact.php`, JSON.stringify(data)).then(res => {
					if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
						this.setState({sent: true});
					}			
					this.setState({loading: false});
				});
			});
		}
	}
	componentDidMount(){
		SetSeo('contact', {tt: 'title', dsc: 'meta.desc'}, []);
		uptBodyHeight();
	}
    menu(){
        this.setState({menu: this.state.menu === " dispnone" ? "" : " dispnone"});
    }
	handleVal(key, event){
		if(!this.state.sent){
			let state = { ...this.state};
			state[key] = event.target.value;
			this.setState(state);
		}
	}
    goTo(url){
        this.props.history(`${GetStateUrlLocale()}${url}`);
    }
	render(){
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="pag-bd" id="js-exp-bd">
					<div className="pag-mid">
						<div className="mg-t20 pd-b10">
							<div className="fltl mg-r10">
								<div className="pag-menu" onClick={() => this.menu()}>
									&#9776;
									<div className={`pag-menu-bx${this.state.menu}`}>
										{PAGE_URL.map((elm, index) => {
											return (
												<div key={`mn-${index}`}
													className="pag-menu-elm"
													onClick={() => this.goTo(elm.url)}>
													{Trtor("page", elm.key)}
												</div>
											);
										})}
									</div>
								</div>
							</div>
							<div className="fltl pd-t5">
								<h3>{Trtor("contact", "title")}</h3>
							</div>
							<div className="clr"></div>	
						</div>
						<div className="pg-rgt">
							<div className="mg-t0" style={{fontSize:"1.1rem"}}>
								{Trtor("contact", "sub")}.
							</div>
							<div className="mg-t20" style={{fontSize:"1.1rem"}}>
								{Trtor("contact", "name")}
							</div>
							<div className="mg-t5">
								<input type="text" 
									className={`cont-inp${this.state.error.name}`} 
									placeholder={Trtor('contact', 'name')} 
									value={this.state.nm} 
									onChange={(e) => this.handleVal("nm", e)}
								/>
							</div>
							<div className="mg-t20" style={{fontSize:"1.1rem"}}>
								{Trtor("contact", "email")}
							</div>
							<div className="mg-t5">
								<input type="text" 
									className={`cont-inp${this.state.error.email}`} 
									placeholder={Trtor('contact', 'email')} 
									value={this.state.em} 
									onChange={(e) => this.handleVal("em", e)}
								/>
							</div>
							<div className="mg-t20" style={{fontSize:"1.1rem"}}>
								{Trtor("contact", "msg")}
							</div>
							<div className="mg-t5">
								<textarea
									name="label"
									maxLength="250"
									className={`cont-txt${this.state.error.txt}`} 
									spellCheck="false"
									data-gramm="false"
									value={this.state.txt}
									onChange={(e) => this.handleVal("txt", e)}
								></textarea>
							</div>
							{this.state.sent &&
								<div className="alert alert-success mg-t30 mg-b30" role="alert">
									{Trtor('contact', 'success')}
								</div>
							}
							{this.state.loading && !this.state.sent &&
								<div style={{margin:"30px 0 0 0", maxWidth:"700px", textAlign:"right"}}>
									<img src={load} alt="loading..." style={{width:"45px"}}/>
								</div>
							}
							{!this.state.loading && !this.state.sent &&
							<div style={{margin:"30px 0 0 0", maxWidth:"700px", textAlign:"right"}}>
								<button type="button" className="btn btn-dark" id="btnsnd" onClick={() => this.submit()}>
									{Trtor("contact", "btn")}
								</button>
							</div>	
							}
						</div>						
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}
export default withRouter(Contact);