import './KreyolLoading.css';
function KreyolLoading(props) {
	return (
		<div>
			<div className="df-hd">
				<div className="df-hd-mid">
					<div className="df-wrd">{props.dot}</div>
				</div>
			</div>
			<div className="df-bd">
				<div className="df-mid">
					<div className="df-bd-lft">                            
						<div className="df-wrd-box">
							<div className={`src-ft-wait lazyload-wave`}>
								<div className="src-ft-wait-sm" style={{maxWidth:'300px'}}></div>
								<div className="src-ft-wait-sm"></div>
								<div className="src-ft-wait-sm"></div>
								<div className="src-ft-wait-sm"></div>
							</div>                                
						</div>
						<div className="df-wrd-box" style={{marginTop:'40px'}}>
							<div className={`src-ft-wait lazyload-wave`}>
								<div className="src-ft-wait-sm" style={{maxWidth:'300px'}}></div>
								<div className="src-ft-wait-sm"></div>
								<div className="src-ft-wait-sm"></div>
								<div className="src-ft-wait-sm"></div>
							</div>  
						</div>
					</div>
					<div className="df-bd-rgt">
						<div className="df-wrd-rgt">
							<div className="df-wrd-pro df-wrd-pro-snd">
								<div className={`src-ft-wait lazyload-wave`} style={{padding:'0 15px 15px 15px'}}>
									<div className="src-ft-wait-sm" style={{maxWidth:'130px', margin:'20px auto'}}></div>
									<div className="src-ft-wait-sm"></div>
									<div className="src-ft-wait-sm"></div>
									<div className="src-ft-wait-sm"></div>
								</div> 
							</div>
						</div>
					</div>
				</div>
				<div className="clr"></div>
			</div>
		</div>
	);
}
export default KreyolLoading;