import React, { Component } from 'react';
import axios from "axios";
import './WordOfWeek.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../utils/general/Env';
import { SetSeo } from '../../utils/general/Seo.js';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { ObjHasKey } from '../../utils/general/Json.js';
import { FormatDate } from '../../utils/general/Date.js';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import Footer from '../body/footer/Footer';
//----------------------------------- Others -----------------------------------
import load from '../../assets/images/load-clr.gif';

class WordOfWeek extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			wrd:  '...',
			type: '...',
			ktype:'...',
			def:  '...',
			syl:  '...',
			prev: false,
			page: 0,
			frm:  '...',
			to:   '...',
        }
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { act: 2, p: this.state.page }
			axios.get(`${ApiUrl()}/kreyol.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					SetSeo('word-of-the-week', 
						{tt: 'title', dsc: 'meta.desc'}, [`var_1#${res.data.msg.l}`]
					);
					this.setState({wrd:  res.data.msg.l});
					this.setState({type: res.data.msg.t});
					this.setState({ktype:res.data.msg.kt});
					this.setState({def:  res.data.msg.d});
					this.setState({syl:  res.data.msg.s});
					this.setState({prev: res.data.msg.b === 1 ? true : false});
					this.setState({loading: false});
					this.setState({frm: res.data.msg.fw });
					this.setState({to:  res.data.msg.tw });
				}				
			});
		});
	}
	componentDidMount(){
		SetSeo('word-of-the-week', {tt: 'title', dsc: 'title'}, []);
		uptBodyHeight();
		this.browse();
	}
	nav(inc){
		if(inc === 0){
			if(this.state.page > 0){
				this.setState({page: (this.state.page - 1)}, () => {
					this.browse();
				});
			}
		}else if(this.state.prev){
			this.setState({page: (this.state.page + 1)}, () => {
				this.browse();
			});
		}
	}
	render(){
		return (
			<div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="wrd-week-bd" id="js-exp-bd">
                    <div className="wrd-week-mid">
						{this.state.loading && 
							<div style={{textAlign:"center", margin:"40px 0 0 0"}}>
								<img src={load} alt="loading..." style={{width:"45px"}}/>
							</div>
						}
						{!this.state.loading &&
							<div className="wrd-week-box">
								{this.state.prev &&
								<div className="wrd-week-arr" onClick={() => this.nav(1)}>
									&larr;
								</div>
								}
								{this.state.page > 0 &&
								<div className="wrd-week-arr wrd-week-arrr" onClick={() => this.nav(0)}>
									&rarr;
								</div>
								}
								<h4 className="wrd-week-tim" style={{color:"#b8b8b8"}}>
									{Trtor('word-of-the-week', 'frm')} {FormatDate(this.state.frm)} - {FormatDate(this.state.to)}
								</h4>
								<h1 className="wrd-week-tit">
									{this.state.wrd}
								</h1>
								<h2 style={{color:"#444"}}>
									[{this.state.syl}]
								</h2>
								<div className="wrd-week-type">
									{Trtor('kreyol', `word_type.${this.state.ktype}.nm`)}
								</div>
								<div className="clr"></div>
								<div className="wrd-week-def">
									{this.state.def}
								</div>
							</div>
						}
                    </div>
                </div>
				<Footer />
		    </div>
		);
	}
}
export default WordOfWeek;