import React, { Component } from 'react';
//-----------------------------------   utils   -----------------------------------
import store from '../../../store/store';
import { Trtor } from '../../../utils/general/Translator';
import { GetStateUrlLocale } from '../../../utils/general/General';
import { SetUrl, rmvLocale, withRouter } from '../../../utils/general/Url';
import { Env } from '../../../utils/general/Env';
//----------------------------------- Component -----------------------------------
import flagHt from '../../../assets/images/ht-flag-sm.png';
import flagEn from '../../../assets/images/en-flag-sm.png';
import flagFr from '../../../assets/images/fr-flag-sm.png';
import './Footer.css';

const currentYear = new Date().getFullYear();
class Footer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			title: '',
			showFlag: false,
			arrow: '&#9660;',
        }
        this.clickSel   = this.clickSel.bind(this);
        this.chgeLocale = this.chgeLocale.bind(this);
	}
    componentDidMount(){ 
        document.addEventListener('mousedown', this.handleClickOutside);
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    clickSel(){
        if(!this.state.showFlag){ 
            this.setState({showFlag: true}, () =>{
                this.uptArrow()
            }) 
        }        
    }
    uptArrow(){
        if(this.state.showFlag){
            this.setState({arrow: '&#9650;'}) 
        }else{
            this.setState({arrow: '&#9660;'}) 
        }
    }
    handleClickOutside = (event) => {     
        if(this.state.showFlag){
            let allowedDiv = ["ft-lng", "ft-lng-mr", "ft-lng-mr-elm", "ft-flg-df", 
            "ft-flg-txt", "ft-flg-arr"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showFlag: false}, () =>{
                        this.uptArrow()
                    })
                }
            }else{
                this.setState({showFlag: false}, () =>{
                    this.uptArrow()
                })
            }
        }
    }
    chgeLocale(locale){
        this.setState({showFlag: false}, () => {
            this.uptArrow()
            store.dispatch({ type: 'SET_LOCALE', payload: locale })
            let url    = rmvLocale()
			let urlSet = SetUrl(url);
			urlSet     = urlSet.length > 0 ? urlSet : "/";
            this.props.history(urlSet);
        })
    }
    goTo(url){
        this.props.history(SetUrl(url));
    }
    getMenuFlag(cat){        
        const lngTxt = Trtor('foot', 'lang')
        const lng = [
            {
                label: lngTxt.ht,
                accr: 'ht',
                to:  '',
                img: flagHt
            },
            {
                label: lngTxt.en,
                accr: 'en',
                to:  '/en',
                img: flagEn
            },
            {
                label: lngTxt.fr,
                accr: 'fr',
                to:  '/fr',
                img: flagFr
            },
        ]
        if(cat === 'all'){
            return (
                <div>
                    {lng && lng.map((elm, index) => {
                        if(elm.to !== GetStateUrlLocale()){
                            return (
                                <div className="ft-lng-mr-elm" key={`ftflg-${index}`} onClick={() => this.chgeLocale(elm.accr)}>
                                    <div className="ft-flg-df">
                                        <img src={elm.img} className="imgmax" alt="flag" style={{width: '30px'}} />
                                    </div>
                                    <div className="ft-flg-txt">
                                        {elm.label}
                                    </div>
                                    <div className="clr"></div>
                                </div>
                            )
                        }else{
                            return null
                        }
                    })}            
                </div> 
            )
        }else{
            return (
                <div>
                    {lng && lng.map((elm, index) => {
                        if(elm.to === GetStateUrlLocale()){
                            return (
                                <div key={`ftflgonce-${index}`}>
                                    <div className="ft-flg-df">
                                        <img src={elm.img} className="imgmax" alt="logo" style={{width: '30px'}} />
                                    </div>
                                    <div className="ft-flg-txt">
                                        {elm.label}
                                    </div>
                                    <div className="ft-flg-arr" dangerouslySetInnerHTML={{__html: this.state.arrow}}></div>
                                    <div className="clr"></div>
                                </div> 
                            )
                        }else{
                            return null
                        }
                    })} 
                </div>
            )
        }
    }
	render(){
        let display = this.state.showFlag ? '' : " dispnone";
		return (
            <div className="ft-cnt">
               <div className="ft-mid">                   
                    <div className="ft-gd">
                        <div className="ft-elm">
                            <div className="ft-nm">
                                <h4 className="ft-nmlh">{Env.nameSite}.net</h4>
                            </div>
                        </div>
                        <div className="ft-elm">
							<span className="ft-elm-spn" onClick={() => this.goTo('/about')}>
								{Trtor('page', 'about')}
							</span></div>
                        <div className="ft-elm">
							<span className="ft-elm-spn" onClick={() => this.goTo('/privacy')}>
								{Trtor('page', 'privacy')}
							</span></div>
                        <div className="ft-elm">
							<span className="ft-elm-spn" onClick={() => this.goTo('/copyright')}>
								{Trtor('page', 'copyright')}
							</span></div>
                        <div className="ft-elm">
							<span className="ft-elm-spn" onClick={() => this.goTo('/contact-us')}>
								{Trtor('page', 'contact')}
							</span></div>
                    </div>
                    <div className="ft-lst">
                        <div className="ft-lng">
                            <div className={`ft-lng-mr${display}`}>
                                {this.getMenuFlag('all')}
                            </div>
                            <div className="ft-flg" onClick={this.clickSel}>
                                {this.getMenuFlag('curr')}
                            </div>
                        </div>
                        <div className="ft-crgt">
                            © 2021 - {currentYear} {Env.nameSite}.net - All rights reserved.
                        </div>
                        <div className="clr"></div>
                    </div>
                </div>
            </div>
		);
	}
}
export default withRouter(Footer);