import React, { Component } from 'react';
import { Link } from "react-router-dom";
import './Header.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';
import { GenerateMenu, 
	GenerateSrcSelect, 
	GenerateLinkRgt, 
	GetStateUrlLocale 
} from '../../../utils/general/General';
import { withRouter } from '../../../utils/general/Url';
import { replaceAllWith } from '../../../utils/general/String';
//----------------------------------- Component -----------------------------------
import AutoComplete from '../../form/auto-complete/AutoComplete';
//----------------------------------- Other -----------------------------------
import logob from '../../../assets/images/logo-b.png';
import srch from '../../../assets/images/src.png';

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			query: 	  '',
			select:   '1',
			showMenu: false,
        }    
        this.menu   = this.menu.bind(this);
        this.goTo   = this.goTo.bind(this);
        this.goHome = this.goHome.bind(this);
	}
    componentDidMount(){         
        document.addEventListener('mousedown', this.handleClickOutside);
	}
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }
    handleClickOutside = (event) => {        
        if(this.state.showMenu){
            let allowedDiv = ["hd-br-mn-elm", "hd-br-mn", "hd-br-icn", "hd-br", "lk-inh"]
            if(event.target && event.target.className){
                if(!allowedDiv.includes(event.target.className)){
                    this.setState({showMenu: false})
                }
            }else{
                this.setState({showMenu: false})
            }
        }
    }
    menu(){
        if(!this.state.showMenu){            
            this.setState({showMenu: true})
        }
    }
    setQuery(val){
        this.setState({query: val});
    }
    valSelect(e){
        this.setState({select: e.target.value});
    }
    submit(){
		let url = `${GetStateUrlLocale()}/kreyol/${replaceAllWith(this.state.query, " ", "-")}`;
		this.goTo(url);
	}
    goHome(){
        return GetStateUrlLocale() ? GetStateUrlLocale() : '/';
    }
    goTo(url){
        this.props.history(url);
    }
	render(){
		return (
            <div className={this.props.showShadow ? "hd hd-shd" : "hd"}>
                <div className="hd-logo">
                    <Link to={this.goHome()} className="hd-br-lnk">
                        <img src={logob} className="imgmax" alt="logo" />
                    </Link>
                </div>
                <div className="hd-logot">
                    <Link to={this.goHome()} className="hd-br-lnk" alt={Trtor('head', 'home')}>
                        Diksyon&egrave;<span style={{color:'#b8b8b8'}}>.net</span>
                    </Link>
                </div>
                <div className="hd-br">
                    <div onClick={this.menu} className="hd-br-icn">&#x2630;</div>
                    <div className={this.state.showMenu ? "hd-br-mn" : "hd-br-mn dispnone"}>
                        {GenerateMenu(this.goTo)}
                    </div>
                </div>
                <div className="hd-search">
                    <div className="hd-src-a">
                        <AutoComplete 
							category="header" 
							placeholder={`${Trtor('head', 'search.placeholder')}...`} 
							setQuery={(val) => this.setQuery(val)}
							selOpt={this.state.select}
						/>
                    </div>
                    <div className="hd-src-b">
                        {GenerateSrcSelect((e) => {this.valSelect(e);})}
                    </div>
                    <div className="hd-src-c">
                        <div className="hd-src-btn cent-mid" onClick={() => this.submit()}>
							<img src={srch} className="hd-src-icn" alt="search" />
						</div>
                    </div>
                    <div className="clr"></div>
                </div>
                <div className="hd-mn">
                    {GenerateLinkRgt(this.goTo)}                                        
                </div>
                <div className="clr"></div>
            </div>
		);
	}
}
export default withRouter(Header);