import { Link } from "react-router-dom";
import './KreyolNotFound.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';
import { GetStateUrlLocale } from '../../../utils/general/General';
//----------------------------------- Component -----------------------------------
import HtmlListSideSide from '../../html/html-list-side-side/HtmlListSideSide';
import WordOfWeekBox from '../../word-of-week/word-of-week-box/WordOfWeekBox';
//----------------------------------- Other -----------------------------------
import plus from '../../../assets/images/plus.png';

function KreyolNotFound(props) {
	let other = [];
	if(props.other[0].length > 0){
		other = props.other[0].map((elm, index) => {
			return {e: elm.l, n: elm.n};
		});
	}
	let ww = props.other.hasOwnProperty(1) ? props.other[1] : {l:"",s:"",h:"",d:"",f:""};
	return (
		<div>
			<div className="df-hd">
				<div className="df-hd-mid">
					<div className="df-wrd" style={{marginTop:"11px"}}>{props.label}</div>
				</div>
			</div>
			<div className="df-bd" id="js-exp-bd">
				<div className="df-mid">
					<div className="df-bd-lft">                            
						<div className="df-wrd-box">
							<h3>{Trtor("kreyol", "word.notFound")}: <span style={{color:"#aeaeae"}}>{props.label}</span></h3>                             
						</div>
						<div className="kre-nfd-add">
							<div className="kre-nfd-addt">
								<Link to={`${GetStateUrlLocale()}/submit`} className="lk-blk212">
									{Trtor("kreyol", "word.add")}
								</Link>
							</div>
							<div className="kre-nfd-addi">
								<Link to={`${GetStateUrlLocale()}/submit`} className="lk-blk212">
									<img src={plus} style={{width:"20px"}} alt="plus sign"/>
								</Link>
							</div>
							<div className="clr"></div>
						</div>
						{other.length > 0 &&						
							<div className="df-wrd-box" style={{marginTop:'30px'}}>
								<h5 style={{color:"#aeaeae"}}>{Trtor("kreyol", "word.similar")}:</h5>
								<HtmlListSideSide attr="sim" arr={other}/>
							</div>
						}
					</div>
					<div className="df-bd-rgt">
						<div className="df-wrd-rgt" style={{top:"-22px"}}>
							{ww.l.length > 0 &&
								<div>
									<h4 className="kre-nfd-week">
										{Trtor("word-of-the-week", "subtitle")}
									</h4>									
									<WordOfWeekBox 
										data={{}}
										brd={""}
									/>
								</div>
							}							
						</div>
					</div>
				</div>
				<div className="clr"></div>
			</div>
		</div>
	);
}
export default KreyolNotFound;