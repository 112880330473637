import React, { Component } from 'react';
import './KreyolMore.css';
//-----------------------------------   utils   -----------------------------------
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import KreyolDefinition from '../kreyol-definition/KreyolDefinition';
import KreyolBadge from '../kreyol-badge/KreyolBadge';
import KreyolExample from '../kreyol-example/KreyolExample';
//----------------------------------- Other -----------------------------------
import arrDn from '../../../assets/images/arrow-dn.jpg';
import arrUp from '../../../assets/images/arrow-up.jpg';
const firstKey:number = 0;
const startKey:number = 1;
class KreyolMore extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clicked: [],
			see: [],
		}
		this.click = this.click.bind(this);
	}
	click(index){
		if(!this.state.clicked.includes(index)){
			this.setState(prevState => ({
				clicked: [...prevState.clicked, index]
			}));
		}else{
			let _cp = this.state.clicked;
			let key = _cp.indexOf(index);
			if(key >= 0){
				_cp.splice(key, 1); 
				this.setState(prevState => ({
					clicked: _cp
				}));
			}
		}
	}
	componentDidMount(){}
	render(){
		let nb    = 1;
		let total = this.props.wrd.length;
		let _this = this;
		return (
			<div>
			{
				this.props.wrd &&
				this.props.wrd.map(function(elm, index){
					if(index >= startKey){
						nb++;
						let crsr = _this.state.clicked.includes(index) ? ' crsr' : '';
						return (
							<div key={`def-mr-${index}`}>
								<div className="df-wrd-sm" style={{margin:'40px 0 5px 0'}}>
									{Trtor('kreyol', `word.more`)} {_this.props.wrd[firstKey].l} ({nb}/{total})
								</div>
								<div className={`df-wrd-box${crsr}`} onClick={() => _this.click(index)}>
									<div className="df-wrd-tit-mr">
										<div className="df-wrd-nm">
											<h1 style={{margin:'0'}}>{elm.l}</h1>
										</div>
										<div className="df-wrd-nb df-wrd-nbt">
											{nb}
										</div>
										<div className="df-wrd-arr">
											{ !_this.state.clicked.includes(index) &&
												<img src={arrUp} alt="extra definition" />
											}
											{ _this.state.clicked.includes(index) &&
												<img src={arrDn} alt="extra definition" />
											}
										</div>
										<div className="clr"></div>
									</div>
								</div>
								{!_this.state.clicked.includes(index) &&
									<div>
										<KreyolDefinition 
											wrd={_this.props.wrd} 
											nb={nb} 
											index={index}
											marg={'mg-t20'} 
											toggleEdit={(index) => _this.props.toggleEdit(index)}
										/>
										<KreyolBadge      wrd={_this.props.wrd} index={index} attr={"syn"}  label={"Synonym"} />
										<KreyolExample    wrd={_this.props.wrd} index={index} attr={"ex"}   label={"Example"} />
										<KreyolExample    wrd={_this.props.wrd} index={index} attr={"expr"} label={"Expression"} />
									</div>
								}
							</div>
						);
					}
					return null;
				})					
			}				
			</div>
		);
	}
}
export default KreyolMore;