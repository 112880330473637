import { GetStateLocale } from './General';
export const LoadI18 = function (name) {
    switch (name) {
        case "general":
            return require('../../i18/i18General');
        case "head":
            return require('../../i18/i18Head');
        case "word-of-the-week":
            return require('../../i18/i18WordOfTheWeek');
        case "kreyol":
            return require('../../i18/i18Kreyol');
        case "vocabulary":
            return require('../../i18/i18Vocabulary');
        case "expression":
            return require('../../i18/i18Expression');
        case "alphabetic":
            return require('../../i18/i18Alphabetic');
        case "submit":
            return require('../../i18/i18Submit');
        case "games":
            return require('../../i18/i18Games');
        case "contact":
            return require('../../i18/i18Contact');
        case "foot":
            return require('../../i18/i18Foot');
        case "home":
            return require('../../i18/i18Home');  
        case "404":
            return require('../../i18/i18NotFound');   
        case "page":
            return require('../../i18/i18Page');            
        case "autoComplete":
            return require('../../i18/i18AutoComplete');            
        default:
            return {};
    }
}
export const Trans = function (i18, path) {
    if(i18 && i18.hasOwnProperty('init')){        
        let obj = i18.init
        path.split('.').forEach((elm, index) => {
            if(obj[elm]){				
              obj = obj[elm]
            }else{
                obj = 'undefined';
                obj = '...';
            }
        });
        return obj
    }
    return '...'
    //return 'undefined'
}
export const Trtor = function (nameI18, path) {
    return Trans(LoadI18(nameI18), `${GetStateLocale()}.${path}`)
}


