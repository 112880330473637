import React, { Component } from 'react';
import './KreyolBadge.css';
//-----------------------------------   utils   -----------------------------------

//----------------------------------- Component -----------------------------------
class KreyolBadge extends Component {
	constructor(props) {
		super(props);
		this.state = {
			definition: [],
			see: [],
		}
	}
	process(){}
	componentDidMount(){
		this.process();
	}
	render(){
		if(this.props.wrd.hasOwnProperty(this.props.attr) && this.props.wrd.this.props.attr.length > 0){		
			return (
				<div className="df-wrd-box" style={{marginTop:'40px'}}>
					<div className="df-wrd-tit">
						<div className="df-wrd-oth">
							<h4 style={{margin:'0'}}>Sinonim</h4>
						</div>
						<div className="clr"></div>
					</div>
					<div className="df-bx-badge">
						<div className="df-wrd-badge">A surface</div>
						<div className="df-wrd-badge">itilizasyon</div>
						<div className="df-wrd-badge">Frekans</div>
						<div className="df-wrd-badge">Lorsque</div>
						<div className="df-wrd-badge">Pwononse</div>
						<div className="df-wrd-badge">tankou</div>
						<div className="df-wrd-badge">Definition</div>
						<div className="df-wrd-badge">Lorsque</div>
					</div>
				</div>
			);
		}
		return null;
	}
}
export default KreyolBadge;