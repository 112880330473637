export const init = {
    ht: {
		meta:{
			desc: `Ou ka kontribye nan pi gwo diksyonè Haitian sou entènèt la lè w soumèt done sou paj sa. Kontrèman ak lektè ki tou senpleman li atik yo. Nenpòt moun—ou mem tou—ka vin yon kontribitè lè yo fè chanjman, ajoute oswa amelyore yon bagay sou sit sa`,
		},
        submit: {
            title: 'Kontribye',
            sub: 'Èske w konnen lang kreyòl ayisyen an? Pataje avèk nou ekspresyon oswa mo ou konnen sou sit entènèt nou an \'diksyone.net\'.',
            all: 'Tout lis',
            detail: 'Ajoute tout detay ke ou konnen!',
            email: 'Email',
            publish: 'Pibliye',
            success: 'Nou byen resevwa enfòmasyon ou yo, mèsi pou kontribisyon ou an.',
        },
        kreyol: {
			meta:{
				desc: `Vin yon kontribitè epi ede plizyè milyon moun atravè mond lan pi byen konprann lang kreyòl Ayisyen an. Kontribisyon ou yo pral ede moun yo eksprime panse yo ak lide yo byen klè, prezante panse yo nan yon fason ki senp epi ki lojik, epi asire moun konprann yo`,
			},
            title: 'Ajoute yon nouvo mo',
            name: 'Mo Kreyol',
            word: 'Mo',
            definition: 'Definisyon',
            example: 'Egzanp',
            success: 'Nou byen resevwa enfòmasyon ou yo, mèsi pou kontribisyon ou an.',
        },
        expression: {
			meta:{
				desc: `Ekspresyon lang Kreyòl Ayisyen an se bèl bagay e moun bezwen konnen sou li donk fè pati pa w ede nou bati pi gwo bibliyotèk sou entènèt de lang ekspresyon Kreyòl Ayisyen an`,
			},
            title: 'Ajoute ekspresyon ou an',
            name: 'Ekspresyon',
            meaning: 'Definisyon',
            example: 'Egzanp',
            success: 'Nou byen resevwa enfòmasyon ou yo, mèsi pou kontribisyon ou an.',
        },
    },
    en: {
		meta:{
			desc: `You can contribute to the largest Haitian online dictionary by submitting your data on this pages. Unlike readers who simply read the articles. Anyone—including you—can become a contributor by boldly making changes when they find something that can be added or improved`,
		},
        submit: {
            title: 'Contribute',
            sub: 'Do you know the Hatian kreole language? Share with us the expressions or words you know on our website \'diksyone.net\'.',
            all: 'All lists',
            detail: 'Add as many details as you can!',
            email: 'Email',
            publish: 'Publish',
            success: 'Your infomation was successfully submitted, thank you for your contribution.',
        },
        kreyol: {
			meta:{
				desc: `Become a contributor and help millions of people around the world better understand the Haitian creole language. Your contributions will help people to articulate their thoughts and ideas clearly, present their thinking in a straightforward and logical way, and ensure that they are understood`,
			},
            title: 'Add a new word',
            name: 'Kreyol Word',
            word: 'Word',
            definition: 'Definition',
            example: 'Example',
            success: 'Your infomation was successfully submitted, thank you for your contribution.',
        },
        expression: {
			meta:{
				desc: `The expessions of the Haitian creole language are marvelous and people need to know about it so do your part and help us build the largest online library of the Haitian creole expressions language`,
			},
            title: 'Add your expression',
            name: 'Expression',
            meaning: 'Meaning',
            example: 'Example',
            success: 'Your infomation was successfully submitted, thank you for your contribution.',
        },
    },
    fr: {
			meta:{
				desc: `Vous pouvez contribuer au plus grand dictionnaire H en ligne en soumettant vos donnéesh. Contrairement aux lecteurs qui se contentent de lire les articles. N'importe qui, y compris vous, peut devenir un contributeur en apportant audacieusement des modifications lorsqu'il trouve quelque chose qui peut être ajouté ou amélioré`,
			},
        submit: {
            title: 'Contribuer',
            sub: 'Connaissez-vous la langue créole haïtienne ? Partagez avec nous les expressions ou mots que vous connaissez sur notre site \'diksyone.net\'.',
            all: 'Toutes les listes',
            detail: 'Ajoutez autant de détails que vous pouvez!',
            email: 'Email',
            publish: 'Publier',
            success: 'Vos informations ont étées soumises avec succès, merci de votre contribution.',
        },
        kreyol: {
			meta:{
				desc: `Devenez contributeur et aidez des millions de personnes à travers le monde à mieux comprendre la langue créole haïtienne. Vos contributions aideront les gens à articuler clairement leurs pensées et leurs idées, à présenter leur pensée de manière simple et logique et à s'assurer qu'elles sont comprises`,
			},
            title: 'Ajouter un nouveau mot',
            name: 'Mot créole',
            word: 'Mot',
            definition: 'Définition',
            example: 'Exemple',
            success: 'Vos informations ont étées soumises avec succès, merci de votre contribution.',
        },
        expression: {
			meta:{
				desc: `Les expressions de la langue créole haïtienne sont merveilleuses et les gens doivent en prendre connaissance, alors faites votre part et aidez-nous à construire la plus grande bibliothèque en ligne de la langue des expressions créoles Haïtiennes`,
			},
            title: 'Ajoutez votre expression',
            name: 'Expression',
            meaning: 'Definition',
            example: 'Exemple',
            success: 'Vos informations ont étées soumises avec succès, merci de votre contribution.',
        },
    }
}
