export const init = {
    ht: {
		for: "pou",
		pager:{
			of: "nan"
		},
        month: [
			{m: "jan"},
			{m: "jan"},
			{m: "fev"},
			{m: "mas"},
			{m: "avr"},
			{m: "me"},
			{m: "jen"},
			{m: "jiyè"},
			{m: "out"},
			{m: "sep"},
			{m: "okt"},
			{m: "nov"},
			{m: "des"},
		],
		word_type: [
			{nm:"", sh: ""},
		]		
    },
    en: {
		for: "for",
		pager:{
			of: "of"
		},
        month: [
			{m: "jan"},
			{m: "jan"},
			{m: "feb"},
			{m: "mar"},
			{m: "apr"},
			{m: "mai"},
			{m: "jun"},
			{m: "jul"},
			{m: "aug"},
			{m: "sep"},
			{m: "oct"},
			{m: "nov"},
			{m: "dec"},
		],
		word_type: [
			{nm:"", sh: ""},
		]    
    },
    fr: {
		for: "pour",
		pager:{
			of: "de"
		},
		month:[
			{m: "janv"},
			{m: "janv"},
			{m: "févr"},
			{m: "mars"},
			{m: "avril"},
			{m: "mai"},
			{m: "juin"},
			{m: "juil"},
			{m: "août"},
			{m: "sept"},
			{m: "oct"},
			{m: "nov"},
			{m: "dec"},
		],
		word_type: [
			{nm:"", sh: ""},
		]
	}
}
