import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import './Vocabulary.css';
//-----------------------------------   utils   -----------------------------------
import {ApiUrl,ExtUrl} from '../../utils/general/Env.js';
import store from '../../store/store.js';
import { ObjHasKey } from '../../utils/general/Json.js';
import { withParamsRouter, getParams, getter } from '../../utils/general/Url';
import { GetStateUrlLocale, GetStateLocale } from '../../utils/general/General';
import { SetSeo } from '../../utils/general/Seo.js';
import { uptBodyHeight } from '../../utils/html/Html.js';
import { Trtor } from '../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../body/header/Header';
import InputAutoComplete from '../form/input-auto-complete/InputAutoComplete';
import Pager from '../pager/Pager';
import Footer from '../body/footer/Footer';
//----------------------------------- Other -----------------------------------
import load from '../../assets/images/load-clr.gif';

const basicUrl = "vocabulary";
class Vocabulary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			page: 1,
			mxp: 0,
			total: 0,
			list: [],
			lastest: [],
			query: "",
        }
        this.setSrc = this.setSrc.bind(this);
	}
	componentDidUpdate(prevProps, prevState){
		if(prevProps.location.pathname !== this.props.location.pathname || 
			prevProps.location.search !== this.props.location.search){
			let query = getter(`q`);
			this.setState({query: query}, () => {
				this.reset();
			});
		}
	}
	componentDidMount(){
		SetSeo('vocabulary', {tt: 'meta.title', dsc: 'meta.desc'}, []);
		this.setState({page: getParams(this.props, "page", 1)});
		let query = getter(`q`);		
		this.setState({query: query}, () => {
			uptBodyHeight();
			this.browse();
		});
	}
	browse(){
		const pg = getParams(this.props, "page", 1);
		this.setState({loading: true}, () => {
			const data = { 
				act: 1, 
				t: this.state.total, 
				p: pg,
				q: this.state.query,
				lg: GetStateLocale()
			};
			axios.get(`${ApiUrl()}/${basicUrl}.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({list: res.data.msg}, () => {
						this.setState({loading: false});
						this.setState({total: res.data.htm.tt});
						this.setState({mxp: res.data.htm.mp});
					});
				}else{
					this.setState({list:[]}, () => {
						this.setState({loading: false});
					});
				}
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			});
		});
	}
	reset(){
		this.setState({page: getParams(this.props, "page", 1)}, () => {
			this.setState({total: 0}, () => {
				this.browse();
			});			
		});
	}
	setSrc(e){
        this.setState({query: e.target.value})
	}
	setUrl(url){
		const pg = getParams(this.props, "page", "");
		if(this.state.query){
			return `${url}?q=${this.state.query}`;
		}
		return `${url}/${pg}`; 
	}
    goTo(url){
		const pg = getParams(this.props, "page", "");
		store.dispatch({ type: 'SET_PRV_PG', payload: {u:this.setUrl(`/${basicUrl}`) , q:this.state.query, p:pg} });        
		this.props.history(`${GetStateUrlLocale()}${url}`);	
    }
	render(){
		return (
            <div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="vc-bd" id="js-exp-bd">
					<div className="vc-mid posrel">
						<div className={"vc-htit"}>
							<div className={"fltl mg-r10"}>
								<h2 className={"vc-tit-h2"}>
									{Trtor('vocabulary', 'vocabulary.title')}&nbsp;
								</h2>
							</div>
							<div className={"vc-rslt"}>								
								{this.state.total} {Trtor('vocabulary', 'vocabulary.sub')}
								{(this.state.query) &&
									<>										
										&nbsp;{Trtor('general', 'for')} <strong>{this.state.query}</strong><span>&nbsp;</span>
										<span style={{fontSize:"0.95rem", cursor:"pointer"}} onClick={() => this.goTo(`/${basicUrl}`) }>
											(&#10540;)
										</span> 
									</>
								}
							</div>
							<div className={"clr"}></div>
						</div>						
						<div className={"vc-src"}>
							<InputAutoComplete 
								label={Trtor('vocabulary', 'vocabulary.search')} 
								url={`${basicUrl}`}
								val={this.state.query}
								max={25}
							/>
						</div>
						<div className={"clr"}></div>
						{this.state.loading &&
							<div className="mg-t20 brw-wait">
								<img src={load} alt="loading..."/>
							</div>
						}
						{!this.state.loading &&
							<div className={this.state.list.length === 0 ? "dispnone" : "vc-all"}>
								{this.state.list.map((elm, key) => {
									let label = ObjHasKey(elm, ["ts"]) && !!elm.ts ? elm.ts : elm.l;
									return (
										<div className="vc-elm" key={`vcb-${key}`}>
											<div className="vc-img" onClick={() => this.goTo(`${elm.u}`)}>
												<img src={`${ExtUrl()}${elm.g}`} className="imgfit" alt={elm.l} />
											</div>
											<div className="vc-tit">
												<span className="vc-link">
													<Link to={`${GetStateUrlLocale()}${elm.u}`} className="lk-inh">
														{label}
													</Link>
												</span>
											</div>
											<div className="vc-nb">
												{elm.n} {Trtor('vocabulary', 'vocabulary.word')}
											</div>
										</div>
									)
								})}								
							</div>
						}
						{!this.state.loading && this.state.list.length === 0 &&
							<div style={{margin:"40px 0",textAlign:"center", color:"#aeaeae"}}>
								<h3>{Trtor('vocabulary', 'vocabulary.empty')}</h3>
							</div>
						}
						{this.state.list.length > 0 &&
							<Pager 
								page={this.state.page}
								url={this.setUrl(`/${basicUrl}`)}
								total={this.state.mxp}
							/> 
						}						
					</div>					
				</div>
				<Footer />
			</div>
		);
	}
}
export default withParamsRouter(Vocabulary);