import React, { Component } from 'react';
import axios from "axios";
import './GameHangedMan.css';
//-----------------------------------   utils   -----------------------------------
import { ApiUrl } from '../../../utils/general/Env';
import { uptBodyHeight } from '../../../utils/html/Html.js';
import { withRouter } from '../../../utils/general/Url';
import { ObjHasKey } from '../../../utils/general/Json.js';
import { GetStateLocale, GetStateUrlLocale } from '../../../utils/general/General.js';
import { SetSeo } from '../../../utils/general/Seo.js';
import { VOWEL, KEYBOARD_ONE, KEYBOARD_TWO, KEYBOARD_THREE } from '../../../file/Constant.js';
import { Trtor } from '../../../utils/general/Translator';
//----------------------------------- Component -----------------------------------
import Header from '../../body/header/Header';
import Footer from '../../body/footer/Footer';
import GameResult from '../game-result/GameResult';
//----------------------------------- Other -----------------------------------
import load from '../../../assets/images/load-clr.gif';
const drawing = ["gmthm-drw-ft", "gmthm-drw-pole", "gmthm-drw-poleb", 
"gmthm-drw-polec", "gmthm-drw-bd-lgb man-bd", "gmthm-drw-bd-lga man-bd", "gmthm-drw-bd-bd man-bd", 
"gmthm-drw-bd-ara man-bd", "gmthm-drw-bd-arb man-bd", "gmthm-drw-bd-hd man-hd", "gmthm-drw-poled"];
const widthCarret = 60;
const spaceCarret = 5;
const maxError    = drawing.length - 1;
const initialState = {
    loading:  true,
	modal:    false,
	answer:   [],
	selected: [],
	error:    0,
	found:    0,
	hint: 	  '',
	status:   'playing',
	width:    0,
};
class GameHangedMan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:  true,
			modal:    false,
			answer:   [],
			selected: [],
			error:    0,
			found:    0,
			hint: 	  '',
			status:   'playing',
			width:    0,
        }
	}
	browse(){
		this.setState({loading: true}, () => {
			const data = { act: 1 }
			axios.get(`${ApiUrl()}/game.php`, {params: data}).then(res => {
				if(ObjHasKey(res, ["data","msg"]) && res.data.cd === 1){
					this.setState({answer: res.data.msg}, () => {
						let nb    = this.state.answer.length;
						let sum   = (nb * widthCarret) + 2;
						let space = (nb - 1) * spaceCarret;
						this.setState({width: (sum + space)});
					});
					this.setState({hint: res.data.htm[GetStateLocale()]});
					this.setState({loading: false});
					this.anchor();
				}
			});
		});
	}
	componentDidMount(){
		SetSeo('games', {tt: 'hangedMan.title', dsc: 'hangedMan.meta.desc'}, []);
		uptBodyHeight();
		this.browse();
	}
	selectAlpha(letter){
		if(this.state.status === 'playing'){
			let arr = this.state.selected;
			arr.push(letter);
			this.setState({selected: arr}, () => {
				this.process(letter);
			});
		}
	}
	restart(){
		this.setState(initialState, () => {
			drawing.map((el, key) => {
				let k = key + 1;
				document.getElementById(`hang-${k}`).classList.add(`dispnone`);
				document.getElementById(`hang-${k}`).classList.remove(`man-red`);
				document.getElementById(`hang-${k}`).classList.remove(`man-bdred`);
				return null;
			});
			this.browse();
		});
	}
	anchor(){
		let width = document.getElementById('js-fll-bd').offsetWidth;
		if(width < 600){
			document.getElementById('anchor').click();
		}
	}
	process(letter){
		if(maxError >= this.state.error && this.state.status === 'playing'){			
			if(this.state.answer.includes(letter)){
				let newVar = [];
				this.state.answer.map((elm, key) => {
					if(elm === letter){
						newVar.push(key);
					}
					return null
				});
				this.setState({ found: this.state.found + newVar.length }, () => {			
					newVar.map((elm, key) => {
						document.getElementById(`ans-${elm}`).textContent = this.state.answer[elm];
						return null;
					});
					if(this.state.found === this.state.answer.length){
						this.setState({status: "won"}, () => {
							this.toggleModal();
						})
					}					
				});					
			}else if(!VOWEL.includes(letter)){			
				let err = this.state.error + 1;
				if(err === 3){ err++; }
				this.setState({error: err}, () => {
					document.getElementById(`hang-${err}`).classList.remove(`dispnone`);
					if(err === 4){
						document.getElementById(`hang-3`).classList.remove(`dispnone`);
					}
					if(this.state.error > maxError){
						let div = Array.prototype.slice.call(document.getElementsByClassName("man-bd"));
						div.map((elm, key) => {
							elm.classList.add("man-red");
							return null;
						});
						let elm = Array.prototype.slice.call(document.getElementsByClassName("man-hd"));
						elm[0].classList.add("man-bdred");
						this.setState({status: "lost"}, () => {
							this.toggleModal();
						});
					}
				});
			}
		}
	}
	goTo(url){
		this.props.history(`${GetStateUrlLocale()}${url}`);
	}
	toggleModal(){
		this.setState({modal: !this.state.modal});
	}
	render(){
		return (
			<div id="js-fll-bd">
                <Header showShadow={false} />
                <div className="gm-thm-bd" id="js-exp-bd">
                    <div className="gm-mid">
						<div>
							<div className="gm-link">
								<span className="back-link" onClick={() => this.goTo(`/games`)}>
									&#10096; {Trtor('games', 'game.allGame')}
								</span>
								<span className="back-sep">/</span>
								<span className="back-on">{Trtor('games', 'hangedMan.title')}</span>
							</div>
							<div className="gm-hint">
								<h5 style={{margin:"0", color:"#909090"}}>
									{Trtor('games', 'hangedMan.hint')}: {this.state.hint}
								</h5>
							</div>
							<div className="clr"></div>
						</div>
						{this.state.loading && 
							<div style={{textAlign:"center", margin:"10px 0 0 0"}}>
								<img src={load} alt="loading..." style={{width:"45px"}}/>
							</div>
						}
						{!this.state.loading &&	
							<div>
								<a href="#start" id="anchor" style={{outline:"none"}}>&nbsp;</a>
								<a href="#no" id="start" style={{outline:"none"}}>&nbsp;</a>								
								<div className="gm-thm-box-carret">
									{this.state.answer.map((elm, key) => {
										let clss = key === 0 ? ' submg-l0' : '';
										return (
											<div className={`gm-thm-carret${clss}`}
												id={`ans-${key}`} key={`ans-${key}`}></div>
										);
									})}
									<div className="clr"></div>
								</div>
							</div>
						}
						<div>
							<div className="gm-thm-man">
								{drawing.map((elm, key) => {
									let k  = key + 1;
									let id = `hang-${k}`;
									return (<div 
										className={`${elm} dispnone`} 
										id={`${id}`}
										key={`drw-${key}`}
									></div>);
								})}
							</div>
							<div className="mg-t30">
								<div className="gm-thm-keys">
									{KEYBOARD_ONE.map((elm, key) => {
										if(this.state.selected.includes(elm)){
											return (<div className="gm-thm-keye" key={`chare-${key}`}>&nbsp;</div>);
										}
										return (<div className="gm-thm-key"
												onClick={() => this.selectAlpha(elm) }
												key={`char-${key}`}
											>{elm}</div>);
									})}
								</div>
								<div className="gm-thm-keys gm-thm-keys-two">
									{KEYBOARD_TWO.map((elm, key) => {
										if(this.state.selected.includes(elm)){
											return (<div className="gm-thm-keye" key={`chare-${key}`}>&nbsp;</div>);
										}
										return (<div className="gm-thm-key"
												onClick={() => this.selectAlpha(elm) }
												key={`char-${key}`}
											>{elm}</div>);
									})}
								</div>
								<div className="gm-thm-keys gm-thm-keys-three">
									{KEYBOARD_THREE.map((elm, key) => {
										if(this.state.selected.includes(elm)){
											return (<div className="gm-thm-keye" key={`chare-${key}`}>&nbsp;</div>);
										}
										return (<div className="gm-thm-key" 
											onClick={() => this.selectAlpha(elm) }
											key={`char-${key}`}
										>{elm}</div>);
									})}
								</div>
							</div>
						</div>
						{this.state.modal &&
						<GameResult 
							show={this.state.modal} 
							toggleModal={() => this.toggleModal()}
							title={this.state.status === "won" ? 
								Trtor('games', 'game.win') : Trtor('games', 'game.lost')}
							msg={this.state.status === "won" ? 
								Trtor('games', 'game.winSub') : Trtor('games', 'game.lostSub')}
							status={this.state.status}
							play={() => this.restart()}
							back={() => this.goTo(`/games`)}
						/>
						}
					</div>
				</div>
                <Footer />
            </div>
		);
	}
}
export default withRouter(GameHangedMan);