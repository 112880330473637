import { Link } from "react-router-dom";
import { Trtor } from './Translator';
import { SetUrl } from './Url';
import { TITLE_WEB } from '../../file/Constant';
import store from '../../store/store';
export const GetStateLocale = function () {
    return store.getState().locale;
}
export const GetStateUrlLocale = function () {
    return store.getState().localeUrl;
}
export const GenerateMenu = function (goTo) {
    const menuTxt = Trtor('head', 'menu')
    const menu = [
        {
            label: menuTxt.wordWeek,
            url: SetUrl('/word-of-the-week'),
        },
        {
            label: menuTxt.alphabet,
            url: SetUrl('/alphabetic'),
        },
        {
            label: menuTxt.expression,
            url: SetUrl('/expression'),
        },
		/*
        {
            label: menuTxt.thesaurus,
            url: SetUrl('/thesaurus'),
        },
		*/
        {
            label: menuTxt.vocabulary,
            url: SetUrl('/vocabulary'),
        },
        {
            label: menuTxt.game,
            url: SetUrl('/games'),
        },
        {
            label: menuTxt.contribute,
            url: SetUrl('/submit'),
        }
    ]
    return (
        <div>
            {menu && menu.map((elm, index) => {
                return (
                    <div className="hd-br-mn-elm" onClick={() => goTo(elm.url)} key={`mn-${index}`}>
						<Link to={elm.url} className="lk-inh">
							{elm.label}
						</Link>
					</div>
                )
            })}
        </div> 
    )
}
export const GenerateSrcSelect = function(setVal){
    const selTxt = Trtor('head', 'search.select')
    const sel = [
        {
            label: selTxt.creole,
            val: 1,
        },/*
        {
            label: selTxt.creoleEng,
            val: 2,
        },
        {
            label: selTxt.engCreole,
            val: 2,
        },/*
        {
            label: selTxt.thesaurus,
            val: 4,
        },*/
    ]
    return (
        <select name="t" id="t" className="hd-src-sel" onChange={setVal}>
            {sel && sel.map((elm, index) => {
                return (
                    <option value={elm.val} key={`sel-${index}`}>{elm.label}</option>
                )
            })}
        </select> 
    )
}
export const GenerateLinkRgt = function (goTo) {
    const linkTxt = Trtor('head', 'menu')
    const link = [
        {
            label: linkTxt.expression,
            url: SetUrl('/expression'),
        },/*
        {
            label: linkTxt.thesaurus,
            url: SetUrl('/thesaurus'),
        },*/
        {
            label: linkTxt.vocabulary,
            url: SetUrl('/vocabulary'),
        },
        {
            label: linkTxt.alphabet,
            url: SetUrl('/alphabetic'),
        },
        {
            label: linkTxt.game,
            url: SetUrl('/games'),
        }
    ]
    return (
        <div>
            {link && link.map((elm, index) => {
                return (
                    <div className="hd-mn-a" onClick={() => goTo(elm.url)} key={`lkrgt-${index}`}>
                        <Link to={elm.url} className="hd-br-lnk">
							{elm.label}
						</Link>
                    </div>
                )
            })}
            <div className="hd-mn-b" onClick={() => goTo(SetUrl(`/submit`))}>
                &#x2795;
            </div>
            <div className="clr"></div>
        </div> 
    )
}
export const SetTitleNav = function (nm) {
	document.title = `${nm} ${TITLE_WEB}`;
}
export default GenerateLinkRgt;