export const init = {
    ht: {
		meta:{
			desc: `Men mo semèn nan #var_1#. Bati vokabilè w ak Diksyone.net! Chak semèn yon editè Diksyone.net ofri yon apèsi sou yon nouvo mo kaptivan -- nou bay tout bagay, siyifikasyon li, itilizasyon aktyèl la ...`,
		},
        title: '#var_1# se mo pou semèn nan',
        subtitle: 'Mo pou semèn nan',
        frm: 'Pou semèn'
    },
    en: {
		meta:{
			desc: `Here is the word of the week #var_1#. Build your vocabulary with Diksyone.net word of the week! Each week a Diksyone.net editor offers insight into a fascinating new word -- explaining its meaning, current use ...`,
		},
        title: '#var_1# is the word of the week',
        subtitle: 'Word of the week',
        frm: 'Week of'
    },
    fr: {
		meta:{
			desc: `Voici le mot de la semaine #var_1#. Construisez votre vocabulaire avec Diksyone.net mot de la semaine! Chaque semaine, un éditeur de Diksyone.net offre un aperçu d'un nouveau mot fascinant - expliquant sa signification, son utilisation actuelle ...`,
		},
        title: '#var_1# est le mot de la semaine',
        subtitle: 'Mot de la semaine',
        frm: 'Semaine du'
    }
}
