export const validateEmail = (em) => {
	//let rgx = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	let rgx = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	if(!em || !rgx.test(em)){
		return false;
    }
	return true;
}
export const capitalizeFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export const replaceAllWith = (str, what, withh) => {
	return str.split(what).join(withh);
}
export const formatNumb = (numb, rep) => {
	let nb = parseInt(numb);
	nb     = nb.toLocaleString('en-US');
	if(rep !== ","){
		nb = String(nb).replaceAll(",", rep);
	}
	return nb
}
export const ReplaceVarWithValViaTab = (str, tab) => {
	let cp = str;
	for(let i = 0; i < tab.length; i++){
		let sep  = tab[i].split("#");
		let mark = sep[0];
		let val  = sep[1];
		cp = cp.replace(`#${mark}#`, ` ${val} `);
	}	
	return cp;
}
export default validateEmail;